import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Home, NavigateNext, Search } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

interface Props {
  slug: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: '#fff',
    width: 30,
    height: 30,
  },
  inputSearch: {
    // maxWidth: 300,
    height: 50,
    width: '100%',
    // height: 44,
    borderRadius: 0,
    border: `1px solid ${theme.palette.secondary.main}`,
    outline: 'none',
    paddingLeft: 16,
    fontFamily: 'Mulish',
    fontSize: 14,
  },
  buttonSearch: {
    background: theme.palette.secondary.main,
  },
  breadcrumbs: {
    flexGrow: 1,
  },
}));

export default function Breadcrumb({ slug }: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [textSearch, setTextSearch] = useState<string>('');
  const matches = useMediaQuery('(min-width:935px)');
  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      history.push(`/buscar/q=${encodeURIComponent(textSearch)}`);
      setTextSearch('');
    }
  };
  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        maxWidth='1052px'
        alignItems='center'
        width='100%'
        flexWrap='wrap'
        justifyContent={matches ? 'space-between' : 'flex-end'}
      >
        <Breadcrumbs
          separator={
            <NavigateNext style={{ color: '#fff' }} fontSize='small' />
          }
          className={classes.breadcrumbs}
        >
          <Link
            href='/'
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              history.push('/');
            }}
          >
            <Home className={classes.icon} />
          </Link>
          <Link
            href='/meus_atendimentos'
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              history.push('/meus_atendimentos');
            }}
          >
            <Typography
              style={{
                cursor: 'pointer',
                color: '#fff',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '300px',
              }}
            >
              {slug}
            </Typography>
          </Link>
        </Breadcrumbs>
        <form
          onSubmit={submitSearch}
          style={{
            display: 'flex',
            justifyContent: `${matches ? 'flex-end' : 'center'}`,
            flexGrow: 1,
            minWidth: 300,
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <input
            value={textSearch}
            onChange={(e): void => setTextSearch(e.target.value)}
            className={classes.inputSearch}
            style={{
              maxWidth: 300,
              width: '100%',
            }}
            placeholder='Digite aqui o que procura...'
          />
          <IconButton
            type='submit'
            className={classes.buttonSearch}
            style={{
              borderRadius: 0,
            }}
          >
            <Search style={{ color: '#fff' }} />
          </IconButton>
        </form>
      </Box>
    </Box>
  );
}
