import React, { useState } from 'react';
import { Box, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import theme from '../../../../services/theme-service';
import ModalReportBug from '../../../../Components/ModalReportBug';

const ReportBug = ({ service_id }: { service_id: string }): JSX.Element => {
  const [showModalReport, setShowModalReport] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    message: string;
    type: 'error' | 'success' | 'info' | 'warning' | undefined;
  }>({
    message: '',
    type: 'success',
  });

  const useStyles = makeStyles(() => ({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '58px 0px',
    },
    boxReport: {
      borderRadius: 5,
      width: '100%',
      maxWidth: 500,
      height: 40,
      border: '1px solid',
      borderColor: '#265AAA',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        color: '#003F7E',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: 'unset',
        justifyContent: 'center',
        margin: '0px 20px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: 30,
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 700,
      color: '#003F7E',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        padding: 10,
      },
    },
  }));
  const classes = useStyles();
  const handleModalReport = () => {
    setShowModalReport(!showModalReport);
  };
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.boxReport} onClick={handleModalReport}>
          <Typography className={classes.text} align='center'>
            Encontrou algum erro? Nos avise por aqui.
          </Typography>
        </Box>
      </Box>
      <ModalReportBug
        service_id={service_id}
        show={showModalReport}
        setShow={handleModalReport}
        alert={setMessage}
      />
      <Snackbar
        autoHideDuration={3000}
        onClose={(): void => setMessage({ message: '', type: message.type })}
        open={!!message.message}
      >
        <Alert
          onClose={(): void => setMessage({ message: '', type: message.type })}
          severity={message.type}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ReportBug;
