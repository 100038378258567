import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';
import { News } from '../../../News';

interface Props {
  newsRelation: News;
}

export default function ContentRelation({ newsRelation }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      maxWidth: '1040px',
      marginTop: 64,
      marginBottom: 120,
      backgroundColor: colors.colorBackground,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
    },
    textTitle: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(32),
      color: colors.textBody,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubtitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    card: {
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 0px !important',
        margin: '0px 32px',
      },
    },
    newSubtitle: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.subTitleColor,
      fontWeight: 400,
      lineHeight: '18.2px',
      textTransform: 'capitalize',

      '&:first-child': {
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    newSubtitleBackground: {
      fontSize: theme.typography.pxToRem(14),
      padding: '3px 6px',
      borderRadius: '6px',
      backgroundColor: '#316BBE',
      color: colors.textAccentColor,
      fontWeight: 700,
      lineHeight: '18.2px',
      textTransform: 'capitalize',
      '&:first-child': {
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    newTitle: {
      marginTop: 16,
      fontSize: theme.typography.pxToRem(20),
      display: '-webkit-box',
      WebkitLineClamp: 3, // Quantidade de linhas que você deseja
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 700,
      color: colors.textBody,
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    textMore: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      color: colors.accentColor,
      marginTop: 40,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.main}>
      <Box width='100%'>
        <Box>
          <Typography className={classes.textTitle}>
            Conteúdo relacionado
          </Typography>
          <Typography className={classes.textSubtitle}>
            Confira as últimas notícias do estado.
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: 40,
          }}
        >
          {newsRelation.results.slice(0, 3).map(newItem => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              key={newItem.id}
              className={classes.card}
            >
              <Link
                key={newItem.id}
                to={`/noticias/${newItem.slug}`}
                style={{
                  backgroundColor: colors.colorBackground,
                  boxShadow: colors.boxShadowColor,
                  textDecoration: 'none',
                }}
              >
                <Box
                  onClick={(): void =>
                    history.push(`/noticias/${newItem.slug}`)
                  }
                >
                  <CardMedia
                    component='img'
                    alt='Noticias'
                    height='222'
                    image={newItem.imagem_destaque_url}
                    title='Noticias'
                  />
                  <CardContent style={{ padding: '24px 24px 21px 24px' }}>
                    <Box display='flex' alignItems='center' gridGap={4}>
                      <Typography
                        className={classes.newSubtitleBackground}
                        variant='h4'
                      >
                        {newItem.categoria_titulo}
                      </Typography>
                      <Typography className={classes.newSubtitle}>-</Typography>
                      <Typography className={classes.newSubtitle}>
                        {format(new Date(newItem.created_at), 'dd MMMM yyyy', {
                          locale: ptBR,
                        })}
                      </Typography>
                    </Box>
                    <Typography
                      variant='h3'
                      className={classes.newTitle}
                      title={newItem.titulo}
                    >
                      {newItem.titulo}
                    </Typography>

                    <Button
                      size='small'
                      color='primary'
                      onClick={(): void =>
                        history.push(`/noticias/${newItem.slug}`)
                      }
                      className={classes.textMore}
                    >
                      Continue lendo
                    </Button>
                  </CardContent>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
