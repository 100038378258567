import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  useMediaQuery,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AccountCircle } from '@material-ui/icons';
import getCookie from '../../../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    justifyContent: 'center',
    // margin: '0px 154px',
    minHeight: '800px',
    display: 'flex',
    marginTop: '83px',
  },
  content: {
    width: '1052px',
    display: 'flex',
  },
  boxInfosLeft: {
    width: '300px',
    marginRight: '60px',
  },
  boxInfosLeftMobile: {
    display: 'none',
    marginRight: '0px',
  },
  infoPrimary: {
    cursor: 'pointer',
    width: '280px',
    height: '48px',
    padding: 22,
    borderWidth: 1,
    display: 'flex',
    border: '1px solid #0F6FB7',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: `${theme.palette.primary.main}`,
    marginBottom: 2,
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
  },
  infoSecundary: {
    cursor: 'pointer',
    width: '280px',
    height: '48px',
    padding: 22,
    borderWidth: 1,
    border: '1px solid #0F6FB7',
    backgroundColor: `${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#fff',
    '&:hover': {
      color: `${theme.palette.primary.main}`,
      backgroundColor: '#fff',
    },
  },
  infoTextPrimary: {
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '10px',
  },
  infoTextSecundary: {
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '10px',
  },
  boxInfosRight: {
    width: '100%',
    maxWidth: '712px',
  },
  boxInfosRightMobile: {
    margin: '-60px 0px 10px',
    padding: '20px',
  },
  title: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '20px',
    color: '#373F41',
    marginBottom: 30,
  },
  subTitle: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#373F41',
    marginBottom: 15,
  },
  groupInputs: {
    width: '100%',
    marginTop: '30px',
    maxWidth: '540px',
  },
  btnSave: {
    marginTop: '61px',
    backgroundColor: 'rgba(60, 210, 120, 0.7)',
    borderRadius: 0,
    width: '100px',
    height: '40px',
    '&:hover': {
      backgroundColor: '#3CD278',
    },

    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18px',
    textTransform: 'capitalize',
  },
}));

export default function Informations(): JSX.Element {
  const classes = useStyles();

  const [dataUser, setDataUser] = useState(null);

  const matches = useMediaQuery('(min-width:935px)');

  useEffect(() => {
    if (getCookie('gov_user_data')) {
      setDataUser(JSON.parse(getCookie('gov_user_data')));
    }
  }, []);

  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Box
          className={
            matches ? classes.boxInfosLeft : classes.boxInfosLeftMobile
          }
        >
          <Box className={classes.infoPrimary}>
            <AccountCircle />
            <Typography className={classes.infoTextPrimary}>
              Informações Pessoais
            </Typography>
          </Box>
          {/* <Box className={classes.infoPrimary}>
            <Phone />
            <Typography className={classes.infoTextSecundary}>Informações de Contato</Typography>
          </Box> */}
        </Box>
        <Box
          className={
            matches ? classes.boxInfosRight : classes.boxInfosRightMobile
          }
        >
          <Typography className={classes.title}>
            Informações Pessoais
          </Typography>
          <Typography className={classes.subTitle}>
            Suas informações pessoais.
          </Typography>
          <hr
            style={{
              width: '100%',
              height: 1,
              backgroundColor: 'rgba(55, 63, 65, 0.75)',
            }}
          />

          <Box className={classes.groupInputs}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label='Nome Completo'
                    variant='outlined'
                    disabled
                    value={dataUser?.nome || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label='Nome Social'
                    variant='outlined'
                    disabled
                    value={dataUser?.nome_social || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label='CPF'
                    variant='outlined'
                    disabled
                    value={dataUser?.cpf || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label='RG'
                    variant='outlined'
                    disabled
                    value={dataUser?.rg || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label='UF da Identidade'
                    variant='outlined'
                    disabled
                    value={dataUser?.rg_uf || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label='Órgão Expedidor'
                    variant='outlined'
                    disabled
                    value={dataUser?.orgao_expedidor || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label='Sexo'
                    variant='outlined'
                    disabled
                    value={dataUser?.sexo || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label='Cor ou Raça'
                    variant='outlined'
                    disabled
                    value={dataUser?.raca_cor || 'Não informado'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label='Nome da Mãe'
                    variant='outlined'
                    disabled
                    value={dataUser?.nome_mae || 'Não informado'}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
