/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import colorContext from '../../../../Context/colorContext';
import { IoMdReturnLeft } from 'react-icons/io';

export default function Title(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
    },
    content: {
      display: 'flex',
      maxWidth: '1040px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    title: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      lineHeight: '38.4px',
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(24),
        marginTop: 10,
        marginBottom: 5,
      },
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    icon: {
      width: 15,
      height: 15,
      color: colors.textAccentColor,
      position: 'absolute',
      right: 0,
      left: 25,
      bottom: 5,
    },
    buttonReturn: {
      position: 'relative',
      width: '100%',
      maxWidth: 157,
      height: 40,
      padding: '10px 24px',
      fontSize: 20,
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      borderRadius: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      fontWeight: 400,
      textTransform: 'capitalize'
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Box>
          <Typography className={classes.title} variant='h4'>
            Agendamento
          </Typography>
          <Typography className={classes.subTitle}>
            Agende aqui seu serviço
          </Typography>
        </Box>
        <Button 
          href='/'
          className={classes.buttonReturn} 
          type='button'
          aria-label='voltar'>
          <IoMdReturnLeft className={classes.icon} size={15} />
          Voltar
        </Button>
      </Box>
    </Box>
  );
}
