import { Box, Card, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { memo, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';
import { SearchII } from '../../../SearchService';

interface Props {
  services: SearchII;
}

const Services = ({ services }: Props): JSX.Element => {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      paddingTop: 48,
    },
    content: {
      display: 'flex',
      maxWidth: '1052px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 24px',
      },
    },
    card: {
      width: '100%',
      minHeight: 154,
      height: 'auto',
      marginBottom: 24,
      borderRadius: 4,
      padding: '24px 32px 24px 40px',
      backgroundColor: colors.colorBackground,
      borderLeft: `2px solid ${colors.accentColor}`,
      boxShadow: colors.boxShadowColor,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        borderBottom: `2px solid ${colors.accentColor}`,
        borderLeft: 'none',
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: colors.textBody,
      '&:hover': {
        color: colors.accentColor,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: colors.subTitleColor,
      marginBottom: 16,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'center',
      },
    },
    tag: {
      background: 'rgba(0, 63, 126, 0.04)',
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(13),
      textTransform: 'uppercase',
      fontWeight: 700,
      padding: '5px 12px',
      borderRadius: 25,
      border: `1px solid ${colors.accentColor}`,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(11),
      },
    },
    textAvaliacao: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.subTitleColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginTop: 12,
      },
    },
    starIcon: {
      color: '#FFA000',
      width: 16,
      height: 16,
    },
    link: {
      textDecoration: 'none',
    },
    emptyIcon: {
      color: 'rgba(51, 51, 51, 0.32)',
      width: 16,
      height: 16,
    },
    info: {
      marginTop: '29px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItem: 'center',
      },
    },
    cardLink: {
      textDecoration: 'none',
    },
  }));
  const classes = useStyles();
  const matchesMobile = useMediaQuery('(max-width:660px)');

  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        maxWidth='1320px'
        alignItems='center'
        width='100%'
        justifyContent='space-between'
        flexDirection='column'
        padding={matchesMobile ? '0px 20px' : '0px 10px'}
        style={{
          gap: matchesMobile ? 16 : 24,
        }}
      >
        <InfiniteScroll
          dataLength={services?.results?.length || 0}
          next={() => {}} // To load more data
          hasMore={false}
          loader={<>...</>}
        >
          {services.results.map(search => (
            <Link
              key={search.id}
              to={`/${search.categoria_slug}/${search.slug}`}
              className={classes.link}
            >
              <Card key={search.id} className={classes.card}>
                <Typography className={classes.subTitle}>
                  {search.orgao_nome}
                </Typography>
                <Typography className={classes.title}>
                  {search.titulo}
                </Typography>
                <Box
                  marginTop='32px'
                  display='flex'
                  flexWrap='wrap'
                  alignItems='center'
                  gridGap='8px'
                >
                  {search.online && (
                    <Typography className={classes.tag}>Digital</Typography>
                  )}
                  {search.agendavel && (
                    <Typography className={classes.tag}>Agendável</Typography>
                  )}
                  {search?.publico_especifico?.length &&
                    search.publico_especifico.map(publico => (
                      <Typography key={publico} className={classes.tag}>
                        {publico}
                      </Typography>
                    ))}
                </Box>
                <Box className={classes.info}>
                  {/* {search.total_avaliacao !== undefined && (
                  <Box display="flex" alignItems="center" gridGap="7px">
                    <Typography className={classes.textAvaliacao}>
                      Avaliação:
                    </Typography>
                    {search?.total_avaliacao !== undefined && (
                    <RatingEvaluation
                      value={search?.total_avaliacao}
                      readOnly
                      size="small"
                    />
)}
                    <Typography className={classes.textAvaliacao}>
                      (
                      {search.total_avaliacao}
                      )
                    </Typography>
                  </Box>
                )} */}
                  {search?.updated_at && (
                    <Typography className={classes.textAvaliacao}>
                      Últimas modificações:
                      {search?.updated_at
                        ? format(
                            new Date(search.updated_at),
                            "dd 'de' MMMM 'de' yyyy",
                            { locale: ptBR },
                          )
                        : 'Nunca'}
                    </Typography>
                  )}
                </Box>
              </Card>
            </Link>
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};
export default memo(Services);
