import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { MdHome } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';

interface Props {
  slug: string;
}

export default function Breadcrumb({ slug }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      borderTop: `1px solid ${colors.colorBackgroundFooter}`,
    },
    link: {
      display: 'flex',
    },
    icon: {
      color: colors.accentColor,
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
    formSearch: {},
    inputSearch: {
      height: 50,
      borderRadius: 0,
      fontFamily: 'Open sans',
      width: '100%',
      border: 'none',
      outline: 'none',
      paddingLeft: 16,
      color: colors.textBody,
      background: colors.serviceCardBackgroundColor,
      '&::placeholder': {
        color: '#888888',
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(14),
      },
    },
    buttonSearch: {
      background: colors.serviceCardBackgroundColor,
      '&:hover': {
        background: colors.breadcrumbButtonColorHover,
      },
    },
    breadcrumbs: {
      flexGrow: 1,
      padding: '16px 0px',
      color: colors.accentColor,
      [theme.breakpoints.down('sm')]: {
        margin: '15px 16px',
      },
    },
    form: {
      [theme.breakpoints.down('sm')]: {
        margin: '0px 16px  15px 16px !important',
      },
    },
    breadcrumbLi: {
      display: 'flex',
    },
    itemBreadcrumb: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
    },
    iconSearch: {
      color: colors.accentColor,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(min-width:935px)');

  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        maxWidth='1040px'
        alignItems='center'
        width='100%'
        flexWrap='wrap'
        justifyContent={matches ? 'space-between' : 'flex-end'}
      >
        <Breadcrumbs
          separator='/'
          className={classes.breadcrumbs}
          classes={{ li: classes.breadcrumbLi }}
        >
          <MdHome
            className={classes.icon}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              history.push('/');
            }}
          />
          <Link
            href='/orgaos'
            style={{ textDecoration: 'none' }}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              history.push('/orgaos');
            }}
          >
            <Typography className={classes.itemBreadcrumb}>
              Lista de Órgãos
            </Typography>
          </Link>
          <Typography className={classes.itemBreadcrumb}>{slug}</Typography>
        </Breadcrumbs>
      </Box>
    </Box>
  );
}
