import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import React from 'react';

interface Props {
  getSearchSSO: (page: number) => void;
  getSearchSiseci: (page: number) => void;
  listTermsSiseci: {
    total_pages: number;
    current: number;
  };
}

const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    marginTop: 62,
    marginBottom: 100,
    '& .Mui-selected': {
      color: '#fff',
      borderRadius: 0,
    },
  },
  paginationItem: {
    color: '#9E9E9E',
  },
  paginationSelected: {
    color: '#fff',
  },
}));

export default function PaginationService({
  listTermsSiseci,
  getSearchSSO,
  getSearchSiseci,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Pagination
        renderItem={item => (
          <PaginationItem
            {...item}
            classes={{
              root: classes.paginationItem,
              selected: classes.paginationSelected,
            }}
          />
        )}
        count={listTermsSiseci.total_pages}
        page={listTermsSiseci.current}
        onChange={(e, page) => {
          getSearchSSO(page);
          getSearchSiseci(page);
        }}
        color='primary'
      />
    </Box>
  );
}
