import React, { useContext } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { SearchII } from "../../../SearchService";
import { makeStyles } from "@material-ui/styles";
import colorContext from "../../../../Context/colorContext";

interface Props {
  loading: boolean;
  list: SearchII;
}

export default function LoadingData({ loading, list }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    contentLoading: {
     marginBottom: 60,
     width: '100%',
     display: 'flex',
     justifyContent: 'center',
    },
    contentNotFound: {
      marginBottom: 60,
      minHeight: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    titleNotFound: {
      textAlign: 'center', 
      color: colors.textBody
    }
  }));
  const classes = useStyles();


  if(loading){
    return (
      <Box
        className={classes.contentLoading}
        >
        <CircularProgress />
      </Box>
    )
  }

  if((!list || list?.results?.length === 0) && !loading){
    return (
    <Box
        className={classes.contentNotFound}
      >
      <Typography
        className={classes.titleNotFound}
        variant='h4'
      >
        Nenhum serviço encontrado
      </Typography>
    </Box>
    )
  }

  return (
    <>
    </>
  )
}