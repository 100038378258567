/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';

import { ListBanners } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listBanners: ListBanners[];
}

const Banners = ({ listBanners }: Props): JSX.Element => {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      paddingBottom: 96,
    },
    bannerMain: {
      width: '100%',
      height: 'auto',
      borderRadius: 4,
      maxHeight: 342,
    },
    carouselRoot: {
      '& .carousel .control-dots': {
        margin: '24px 0px 0px 0px',
      },
      '& .carousel .control-dots .dot': {
        width: 8,
        height: 8,
        border: '2px solid #fff',
        backgroundColor: 'transparent',
        borderRadius: 5,
        opacity: 1,
      },
      '& .carousel .control-dots .selected': {
        width: 8,
        height: 8,
        border: 'none',
        backgroundColor: colors.colorBackground,
        borderRadius: 5,
      },
    },
    boxImage: {
      height: '100%',
      maxHeight: 342,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box display='flex' maxWidth='1040px' width='100%' flexDirection='column'>
        <Box className={classes.bannerMain}>
          <Carousel
            autoPlay
            infiniteLoop
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            className={classes.carouselRoot}
          >
            {listBanners.map(banner => (
              <Box
                className={classes.boxImage}
                key={`${banner.titulo}-key`}
                onClick={(): void => (window as any).open(banner.url)}
              >
                <img src={banner.url_imagem} alt={banner.titulo} />
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default Banners;
