/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Slider, { Settings } from 'react-slick';
import { ChannelAttendanteI } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listChannel: ChannelAttendanteI[];
}

export default function ChannelAttendance({ listChannel }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      padding: '64px 0px 120px 0px',
    },
    card: {
      borderRadius: 4,
      borderBottom: `2px solid ${colors.accentColor}`,
      height: 212,
      maxWidth: 160,
      width: '100%',
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.serviceCardBackgroundColor,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 63, 126, 0.1)',
        transition: 'all 0.2s ease',
      },
    },
    cardMobile: {
      marginBottom: 10,
      marginLeft: 5,
      marginRight: 5,
      width: '47%',
      height: 204,
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.textBody,
      boxShadow:
        '0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.05)',
      borderRadius: 4,
      '&:hover': {
        color: colors.textBody,
        background: `linear-gradient(180deg, ${colors.accentColor} 0%, ${colors.accentColor} 100%)`,
        transition: 'all 0.2s ease',
      },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 32,
      height: '100%',
      borderBottom: `2px solid ${colors.accentColor}`,
    },
    boxIcon: {
      width: 80,
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: colors.colorBackground,
    },
    channelTitle: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      marginTop: 16,
      textAlign: 'center',
      fontWeight: 600,
    },
    textTitle: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      color: colors.textBody,
      marginBottom: 16,
    },
    textSubTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      letterSpacing: '0.1px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    iconNavigate: {
      width: 24,
      height: 24,
    },
    buttonNavigate: {
      color: colors.accentColor,
      width: 40,
      height: 40,
      border: `1px solid ${colors.accentColor}`,
      cursor: 'pointer',
      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      '&:hover': {
        backgroundColor: colors.cardAccentColor,
        color: colors.textAccentColor,
      },
    },
    buttonLabel: {
      width: 'auto',
    },
    textIcon: {
      fontSize: 18,
      marginTop: 20,
      lineHeight: 20,
    },
    slider: {
      '& .slick-list': {
        margin: 0,
        height: 212,
        '& .slick-track': {
          height: 212,
        },
      },
      '& .slick-slide': {
        padding: 0,
        marginRight: 16,
        width: '160px !important',
      },
    },
  }));
  const classes = useStyles();
  const showMore = false;
  const matches = useMediaQuery('(min-width:660px)');
  const sliderRef = useRef<Slider | null>(null);

  const NextArrow = (): JSX.Element => (
    <IconButton
      classes={{
        root: classes.buttonNavigate,
        label: classes.buttonLabel,
      }}
      color='primary'
      onClick={() => sliderRef.current.slickNext()}
      aria-label='botão avançar'
    >
      <MdChevronRight className={classes.iconNavigate} />
    </IconButton>
  );

  const PrevArrow = (): JSX.Element => (
    <IconButton
      classes={{
        root: classes.buttonNavigate,
        label: classes.buttonLabel,
      }}
      color='primary'
      onClick={() => sliderRef.current.slickPrev()}
      aria-label='botão retroceder'
    >
      <MdChevronLeft className={classes.iconNavigate} />
    </IconButton>
  );

  const settings: Settings = {
    speed: 1000,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <Box className={classes.main}>
      <Box display='flex' maxWidth='1040px' width='100%' flexDirection='column'>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-end'
          marginBottom='48px'
        >
          <Box>
            <Typography className={classes.textTitle} variant='h5'>
              Canais de atendimento
            </Typography>
            <Typography className={classes.textSubTitle}>
              Solicite informações, registre sua reclamação, denúncia ou elogio,
              participe!
            </Typography>
          </Box>
          <Box>
            <PrevArrow />
            <NextArrow />
          </Box>
        </Box>
        {showMore ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexWrap='wrap'
          >
            {listChannel.map(channel => (
              <Card
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                onClick={(): void => (window as any).open(channel.url)}
                key={channel.titulo}
                className={
                  showMore && !matches ? classes.cardMobile : classes.card
                }
              >
                <CardActionArea
                  style={{
                    height: '100%',
                  }}
                >
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span style={{ fontSize: 60 }} className='material-icons'>
                      {channel.icone}
                    </span>
                    <Typography align='center' variant='h6'>
                      {channel.titulo}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        ) : (
          <Box>
            <Slider ref={sliderRef} {...settings} className={classes.slider}>
              {listChannel.map(channel => (
                <Card
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  onClick={(): void => (window as any).open(channel.url)}
                  key={channel.titulo}
                  className={classes.card}
                >
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.boxIcon}>
                      <span
                        style={{ fontSize: 34, color: colors.accentColor }}
                        className='material-icons'
                      >
                        {channel.icone}
                      </span>
                    </Box>
                    <Typography
                      align='center'
                      variant='h5'
                      className={classes.channelTitle}
                    >
                      {channel.titulo}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Slider>
          </Box>
        )}
      </Box>
    </Box>
  );
}
