import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../Context/colorContext';

interface Props {
  title: string;
  category: string;
}

export default function Title({ title, category }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    mainBox: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.cardAccentColor,
      padding: '48px 16px 24px 16px',
    },
    main: {
      width: '100%',
      display: 'flex',
      maxWidth: '1040px',
      flexDirection: 'column',
    },
    text: {
      color: colors.textAccentColor,
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(32),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    category: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      color: colors.textAccentColor,
      marginBottom: 16,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
        textAlign: 'center',
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.main}>
        <Typography className={classes.category}>{category}</Typography>
        <Typography className={classes.text}>{title}</Typography>
      </Box>
    </Box>
  );
}
