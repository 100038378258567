import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Input, InputAdornment } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import colorContext from "../../Context/colorContext";
import Breadcrumb from "./components/Breadcrumb";
import Title from "./components/Title";
import theme from "../../services/theme-service";
import { searchServiceNew } from "../../services/servico";
import PaginationService from "./components/PaginationService";
import { SearchII } from "../SearchService";
import ServiceList from "./components/ServiceList";
import LoadingData from "./components/LoadingData";
import getCookie from "../../utils/getCookies";

export default function SchedulerService() {
  const token: string | null = getCookie('gov_access_token_sso');
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      alignItems: 'center',
      minHeight: 399,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },

    boxInputSearch: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0px 20px 0px',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 0px 20px 0px',
      },
    },
    formSearch: {
      width: '100%',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E6E6E6',
      [theme.breakpoints.down('md')]: {
        width: 'calc(1040px - 46px)',
        display: '100% !important',
        padding: '6px 4px',
      },
    },
    menuActions: {
      display: 'flex',
      maxWidth: 1040,
      padding: '15px 0px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap-reverse',
        flexDirection:'column-reverse'
      }
    },
    boxButtonScheduler: {
      maxWidth: 250,
      width: '100%',
      paddingLeft: '20px',
    },
    buttonScheduler: {
      width: '100%',
      padding: 15,
      borderRadius: 8,
      outlineStyle: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: '#fff',
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontWeight: 700,
      '&:hover': {
         backgroundColor: theme.palette.primary.main,
         color: '#fff'
      }
    },
    boxInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '10px',
      backgroundColor: colors.colorBackground,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    inputSearch: {
      width: '100%',
      outline: 'none',
      paddingLeft: 20,
      fontFamily: 'Open sans',
      fontSize: 14,
      borderRadius: 4,
      border: 'none',
      margin: '0px 10px',
      backgroundColor: 'transparent',
      color: colors.subTitleColor,
      '&::-webkit-input-placeholder': {
        color: colors.subTitleColor,
        fontSize: theme.typography.pxToRem(16),
      },
    },
    iconButton: {
      borderRadius: '10px',
      minWidth: 190,
      textAlign: 'center',
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(13),
      textTransform: 'capitalize',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: colors.accentColorBackground,
      },
      height: 45,
      [theme.breakpoints.down('sm')]: {
        minWidth: '40%',
        fontSize: theme.typography.pxToRem(11),
      },
    },
    boxFormSearch: {
      display: 'flex',
      flexGrow: 2,
      width: '100%',
      backgroundColor: colors.colorBackground,
      [theme.breakpoints.down('sm')]: {
        padding: '10px 16px 0px 16px',
      },
    },
    inputSearchIcon: {
      color: 'theme.palette.accentColor',
    },
    searchIcon: {
      position: 'absolute',
      left: '8px',
      fontSize: '21px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'blue',
      [theme.breakpoints.down('xs')]: {
        height: 15,
        width: 15,
      },
    },
  }));
  const [textSearch, setTextSearch] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState(false);
  const [listServices, setListServices] = useState<SearchII | null>(null)
  const classes = useStyles();


  const getAllServicesActive = async ({ search, page }: { search?: string, page?: number }) => {
    if(search) setIsSearch(true);
    try {
      setLoading(true)
      const response  = await searchServiceNew({
        agendavel: true,
        search,
        page,
      });

      setListServices(response.data);
    } catch (error) {
      console.error(error);
    }finally{
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    setIsSearch(false);
    setTextSearch('');
    getAllServicesActive({ page: 1 });
  }

  useEffect(() => {
    getAllServicesActive({page: 1});
  },[])

  return (
    <Box className={classes.main}>
       <Breadcrumb slug='Agendamentos' />
       <Title/>
       <Box className={classes.menuActions}>
       <Box className={classes.boxFormSearch}>
        <form 
        onSubmit={e => {
          e.preventDefault();
          if (textSearch) {
            getAllServicesActive({ search: textSearch.toLowerCase(), page: 1
             });
          }
        }}
        className={classes.formSearch}>
          <div className={classes.boxInput}>
            <Input
              value={textSearch}
              onChange={(e): void => setTextSearch(e.target.value)}
              className={`${classes.inputSearch}`}
              placeholder='Ex: CNH, Matrícula escolar...'
              disableUnderline
              startAdornment={
                <InputAdornment position='start'>
                  <img
                    alt='Contraste'
                    src='/assets/images/searchIcon.svg'
                    className={classes.searchIcon}
                  />
                </InputAdornment>
              }
            />
            {isSearch ? (
              <Button type='button' onClick={handleResetFilter} className={classes.iconButton}>
              LIMPAR
            </Button>
            ): (
            <Button type='submit' className={classes.iconButton}>
              BUSCAR SERVIÇO
            </Button>
            )}
          </div>
        </form>
      </Box>
      {token && (
      <Box className={classes.boxButtonScheduler}>
        <button
          onClick={() => window.location.replace('/workspace/meus_agendamentos')} 
          type="button" 
          className={classes.buttonScheduler}>
            Meus Agendamentos
        </button>
      </Box>
      )}
      </Box>

      <LoadingData list={listServices} loading={loading} />

      {listServices?.results.length && !loading ? (
        <>
          <ServiceList list={listServices}/>
          <PaginationService 
            getAllServices={getAllServicesActive} 
            search={textSearch} 
            list={listServices} 
          />
        </>
      ) : null}
    </Box>
  )
}