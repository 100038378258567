import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdFindInPage } from 'react-icons/md';
import colorContext from '../../../../Context/colorContext';

interface Props {
  slug: string;
}

export default function Title({ slug }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
    },
    content: {
      display: 'flex',
      maxWidth: '1040px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
      },
    },
    title: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
        marginTop: 19,
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      marginTop: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    icon: {
      width: 80,
      height: 80,
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        width: 40,
        height: 40,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Box>
          <Typography className={classes.title} variant='h4'>
            Resultados da busca por
          </Typography>
          <Typography className={classes.text}>
            &#34;
            {decodeURIComponent(slug)}
            &#34;
          </Typography>
        </Box>
        <MdFindInPage className={classes.icon} />
      </Box>
    </Box>
  );
}
