import { AxiosResponse } from 'axios';
import { apisiseci, apisso } from './api';
import { postRemoveDevice } from './device';
import { UserData, UserDataMti } from './user';
import getCookie from '../utils/getCookies';

// função auxiliar para setar cookies
const setCookie = (cname: string, cvalue: string, exdays: number): void => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const saveTokens = (tokenSSO: string): void => {
  setCookie('gov_access_token_sso', tokenSSO, 7);
};

export const clearStorage = (): void => {
  const cookiesToDelete = [
    'gov_access_token_sso',
    'gov_access_token_sso',
    'pathname_redirect_gov',
    'gov_user_data',
  ];

  for (const cookie of cookiesToDelete) {
    document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};

export const getPathToRedirectLogin = (): string => {
  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${atob(process.env.REACT_APP_SSO)}/sso/cidadao?client_id=${atob(
      process.env.REACT_APP_CLIENT_ID,
    )}`;
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${atob(process.env.REACT_APP_SSO_SECUNDARY)}/auth?client_id=${atob(
    process.env.REACT_APP_CLIENT_ID,
  )}&response_type=${atob(
    process.env.REACT_APP_RESPONSE_TYPE,
  )}&redirect_uri=${atob(process.env.REACT_APP_REDIRECT_URI)}`;
};

export const loginRedirectPrivider = (pathname: string): void => {
  clearStorage();
  setCookie('pathname_redirect_gov', pathname, 7);
  (window as any).open(getPathToRedirectLogin(), '_self');
};

export const logout = async () => {
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');
  const deviceToken: string | null = getCookie('gov_access_device_token');

  if (tokenSiseci && deviceToken) {
    await postRemoveDevice(deviceToken, tokenSiseci);
  }
  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    clearStorage();
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (window as any).open(
      `${atob(process.env.REACT_APP_SSO)}/sso/sair?client_id=${atob(
        process.env.REACT_APP_CLIENT_ID,
      )}&redirect_uri=${window.location.href}`,
      '_self',
    );
  } else {
    clearStorage();
    (window as any).open(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${atob(process.env.REACT_APP_SSO_SECUNDARY)}/logout?client_id=${atob(
        process.env.REACT_APP_CLIENT_ID,
      )}&response_type=${atob(
        process.env.REACT_APP_RESPONSE_TYPE,
      )}&redirect_uri=${window.location.href}`,
      '_self',
    );
  }
};

export const getDataUserSso = (token: string): Promise<AxiosResponse> =>
  apisso.get('/cidadaos/pro/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getDataUserMti = (
  token: string,
  access_token_mti?: string,
): Promise<AxiosResponse> =>
  apisiseci.get('/profile/externo/', {
    headers: {
      Authorization: `Bearer ${token}`,
      'Authorization-Externo': access_token_mti,
    },
  });

export const validateUserSSO = (code: string): Promise<AxiosResponse> =>
  apisso.post(
    '/validar_cidadao/',
    {
      code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

export const loginSigp = (
  code: string,
  redirect_uri?: string,
): Promise<AxiosResponse> => {
  if (
    atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary' &&
    atob(process.env.REACT_APP_ENV) === 'local'
  ) {
    return apisiseci.post('/login-sigp/', {
      code,
      client_id: atob(process.env.REACT_APP_CLIENT_ID),
      redirect_uri: `${atob(process.env.REACT_APP_URL)}/login/callback/`,
    });
  }

  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    return apisiseci.post('/login-sigp/', {
      code,
    });
  }
  return apisiseci.post(
    '/login/externo/',
    {
      code,
      redirect_uri: redirect_uri || atob(process.env.REACT_APP_REDIRECT_URI),
    },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        Authorization: atob(process.env.REACT_APP_API_SISECI_KEY),
      },
    },
  );
};

const mtiDataUserAdapter = (data: UserDataMti): UserData => {
  const dataF = {
    nome: data.name,
    data_nascimento: data.dataNascimento,
    nome_mae: data.nomeMae,
    cpf: data.preferred_username || data.username || data.cpf,
    contato: {
      email: data.email,
    },
  } as UserData;
  return dataF;
};

export const getUserData = async (token: string, access_token_mti?: string) => {
  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    const { data } = await getDataUserSso(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data[0];
  }

  const { data } = await getDataUserMti(access_token_mti, token);

  return mtiDataUserAdapter(data);
};

export const sendCodeForSISECI = async (code: string, history: any) => {
  const pathname = getCookie('pathname_redirect_gov');
  try {
    const { data } = await loginSigp(
      code,
      `${window.location.origin}${window.location.pathname}`,
    );

    // TODO: verificar o perfil do cidadão no jwt

    if (data.access_token) {
      saveTokens(data.access_token);
      const spathname = pathname === '/' ? '/workspace' : pathname;
      history.push(spathname || '/workspace', {
        message: data.message ? data.message : '',
      });
    }
  } catch (err) {
    console.log(err, ' errlogin');
    clearStorage();
    history.push(pathname || '/', {
      message: 'Erro ao processar o login',
    });
  }
};

export const getImageProfile = async (token: string) =>
  apisso.get('cidadaos/foto/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
