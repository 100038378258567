import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { apisiseci } from '../../services/api';
import { getService } from '../../services/servico';
import { SearchI } from '../SearchService';
import { Params } from '../ServiceInfo';
import {
  Breadcrumb,
  ChannelAttendance,
  ServiceDetails,
  Title,
} from './components';

export interface ChannelAttendanteI {
  icone: string;
  titulo: string;
  url: string;
}

export default function IntegratedService(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [service, setService] = useState<SearchI>();
  const [loading, setLoading] = useState<boolean>(false);
  const [channels, setChannels] = useState<ChannelAttendanteI[]>([]);

  const getChannelAttendances = async () => {
    const { data } = await apisiseci.get('/cms/canais_atendimento/');
    setChannels(data);
  };

  const getServiceEspecific = async () => {
    setService(undefined);
    setLoading(true);

    try {
      const { data } = await getService(params.slug);
      setService(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getServiceEspecific();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    getChannelAttendances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.main}>
      {loading && (
        <Box
          marginBottom='60px'
          flex='1'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Box>
      )}

      {service && (
        <>
          <Breadcrumb service={service} slug={service.titulo} />
          <Title slug={service.titulo} service={service} />
          <ServiceDetails service={service} />
          <ChannelAttendance listChannel={channels} />
        </>
      )}
    </Box>
  );
}
