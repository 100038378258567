/* eslint-disable react/require-default-props */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

import { Cancel, CheckCircle } from '@material-ui/icons';

interface Props {
  typeError: boolean;
  errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxInfoConclueded: {
    marginTop: 50,
    width: 668,
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconBox: {
    margin: '68px 0px 36px 0px',
  },
  textBox: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '28px',
    textAlign: 'center',
  },
  subTextBox: {
    maxWidth: 343,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    margin: '16px 0px 48px 0px',
  },
  buttonRedirect: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: '17px 22px',
    height: 48,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    '&:hover': {
      background: '#0D5A95',
    },
  },
}));

export default function InfoMessage({
  typeError,
  errorMessage,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      className={classes.boxInfoConclueded}
      height={typeError ? 'auto' : 424}
    >
      <Box className={classes.iconBox}>
        {typeError ? (
          <Cancel style={{ color: '#F03D3D', fontSize: 46 }} />
        ) : (
          <CheckCircle style={{ color: '#0BB07B', fontSize: 46 }} />
        )}
      </Box>
      {typeError ? (
        <>
          <Typography className={classes.textBox}>Algo deu errado!</Typography>
          <Typography className={classes.subTextBox}>
            {errorMessage ||
              'Verifique seus dados corretamente e tente novamente.'}{' '}
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.textBox}>
            Reagendamento concluído
          </Typography>
          <Typography className={classes.subTextBox}>
            Você receberá um e-mail e uma mensagem com a confirmação do seu
            agendamento.
          </Typography>
          <Box>
            <Button
              id='confirm-agendamento'
              type='button'
              className={classes.buttonRedirect}
              onClick={() => history.push('/')}
            >
              ir para o portal
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
