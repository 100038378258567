import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    boxFilter: {
      marginBottom: '30px',
      width: '100vw',
      maxWidth: '1052px',
      padding: 20,
    },
    btnFilter: {
      width: '250px',
      padding: '15px',
      borderRadius: '0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    itemMenu: {
      borderRadius: '0',
    },
    textMenu: {
      width: '216px',
      padding: '10px',
      textAlign: 'center',
      color: '#737B7D',
      fontSize: '14px',
      lineHeight: '20px',
    },
  }),
);

interface Props {
  setFilter: any;
}

export default function FilterSchedule({ setFilter }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [selected, setSelected] = React.useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selected) {
      switch (selected) {
        case 'Todos':
          setFilter(null);
          break;
        case 'Aguardando':
          setFilter({
            status: 'Aguardando',
          });
          break;

        case 'Finalizados':
          setFilter({
            status: 'Finalizado',
          });
          break;
        case 'Cancelados':
          setFilter({
            status: 'Cancelado',
          });
          break;
        default:
          setFilter(null);
          break;
      }
    }
  }, [selected, setFilter]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Grid item>
        <Box className={classes.boxFilter}>
          <Button
            color='primary'
            variant='contained'
            aria-describedby={id}
            onClick={handleClick}
            className={classes.btnFilter}
          >
            <Typography>
              {selected === '' ? 'Filtrar agendamentos' : selected}
            </Typography>
            <ExpandMore />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem
              className={classes.itemMenu}
              onClick={() => {
                setSelected('Todos');
                setAnchorEl(null);
              }}
            >
              <Typography
                className={classes.textMenu}
                style={
                  selected === 'Todos' || selected === ''
                    ? { color: '#0F6FB7' }
                    : {}
                }
              >
                Todos
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setSelected('Aguardando');
                setAnchorEl(null);
              }}
            >
              <Typography
                className={classes.textMenu}
                style={selected === 'Aguardando' ? { color: '#0F6FB7' } : {}}
              >
                Aguardando
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setSelected('Finalizados');
                setAnchorEl(null);
              }}
            >
              <Typography
                className={classes.textMenu}
                style={selected === 'Finalizados' ? { color: '#0F6FB7' } : {}}
              >
                Finalizados
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setSelected('Cancelados');
                setAnchorEl(null);
              }}
            >
              <Typography
                className={classes.textMenu}
                style={selected === 'Cancelados' ? { color: '#0F6FB7' } : {}}
              >
                Cancelados
              </Typography>
            </MenuItem>
            <Divider />
          </Popover>
        </Box>
      </Grid>
    </Grid>
  );
}
