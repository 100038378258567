import { AxiosResponse } from 'axios';
import { apisiseci } from './api';

export const loadCurrentTermSISECI = (token: string): Promise<AxiosResponse> =>
  apisiseci.get('cms/termos/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const loadSignedTermSISECI = (
  token: string,
  page = 1,
): Promise<AxiosResponse> =>
  apisiseci.get('cms/termos_assinados/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page,
    },
  });

export const loadPendingTermSISECI = (token: string): Promise<AxiosResponse> =>
  apisiseci.get('cms/termos_pendentes/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const evaluationConfiguration = (): Promise<AxiosResponse> =>
  apisiseci.get('cms/configuracao_avaliacao');

export const signTermSISECI = (
  token: string,
  term_id: string,
): Promise<AxiosResponse> =>
  apisiseci.post(
    'cms/assinar_termo/',
    { termo: term_id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const evaluationConfigurationSend = async (
  token: string,
  dataToSend: any,
): Promise<AxiosResponse> => {
  const response = await apisiseci.post('cms/resposta_avaliacao/', dataToSend, {
    headers: {
      Authorization: atob(process.env.REACT_APP_API_SISECI_KEY),
    },
  });
  return response;
};
