import { AxiosResponse } from 'axios';
import { apisiseci } from './api';

export const allOrgan = (page: number): Promise<AxiosResponse> =>
  apisiseci.get(`cms/orgaos/?page=${page}`);

export const getOrgan = (slug: string): Promise<AxiosResponse> =>
  apisiseci.get(`cms/orgaos/?slug=${slug}`);

export const filterOrgan = (params: {
  search?: string;
  page?: number;
  slug?: string;
}): Promise<AxiosResponse> =>
  apisiseci.get('cms/orgaos/', {
    params,
  });

export const listAllOrgan = (): Promise<AxiosResponse> =>
  apisiseci.get('cms/orgaos_all/');
