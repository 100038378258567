import { Box, Button, Card, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { MdPlayCircle } from 'react-icons/md';
import { VideoDialog } from '..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  videos: [
    {
      titulo: string;
      resumo: string;
      video_id: string;
    },
  ];
}

export default function VideoList({ videos }: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [videoSelected, setVideoSelected] = useState<string>('');
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      marginTop: 61,
    },
    subBox: {
      width: '100%',
      maxWidth: '1040px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        margin: '0px 16px',
      },
    },
    card: {
      width: '100%',
      marginBottom: 16,
      borderRadius: 4,
      padding: '24px 32px 24px 24px',
      backgroundColor: colors.colorBackground,
      borderLeft: `2px solid ${colors.accentColor}`,
      boxShadow: colors.boxShadowColor,
      color: colors.textBody,
      display: 'flex',
      alignItems: 'center',
      gap: 24,
      cursor: 'pointer',
      '&:hover': {
        color: colors.accentColor,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        borderLeft: 'none',
        borderBottom: `2px solid ${colors.accentColor}`,
      },
    },
    textTitle: {
      maxWidth: 667,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(24),
      color: 'inherit',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    boxInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    img: {
      borderRadius: 4,
    },
    buttonRoot: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      lineHeight: '24px',
      color: colors.accentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    iconPlay: {
      width: 40,
      height: 40,
    },
  }));
  const classes = useStyles();

  const handleOpenVideoModal = (video_id: string) => {
    setVideoSelected(video_id);
    setOpen(true);
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.subBox}>
        {videos.map(video => (
          <Card
            onClick={() => handleOpenVideoModal(video.video_id)}
            key={video.video_id}
            className={classes.card}
          >
            <img
              src={`https://img.youtube.com/vi/${video.video_id}/0.jpg`}
              width='105px'
              height='105px'
              alt='thumbnail'
              className={classes.img}
            />
            <Box className={classes.boxInfo}>
              <Typography className={classes.textTitle}>
                {video.titulo}
              </Typography>
              <Button
                classes={{ root: classes.buttonRoot }}
                startIcon={<MdPlayCircle className={classes.iconPlay} />}
              >
                Assistir
              </Button>
            </Box>
          </Card>
        ))}
      </Box>
      {videos?.length > 0 && (
        <VideoDialog video_id={videoSelected} setOpen={setOpen} open={open} />
      )}
    </Box>
  );
}
