/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import colorContext from '../../../../Context/colorContext';

interface Props {
  organ: string;
  category: string;
  tags: string;
}

export default function OriginNew({
  organ,
  category,
  tags,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const [tagsItem, setTagsItems] = useState<Array<string>>([]);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: '1040px',
      marginBottom: 67,
      backgroundColor: colors.colorBackground,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 24px',
      },
    },
    text: {
      color: colors.textBody,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
    },
    time: {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(18),
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    timeCategoria: {
      display: 'flex',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      alignItems: 'center',
      color: colors.textBody,
      margin: '8px 0px',
    },
    tagBox: {
      fontWeight: 400,
      color: colors.accentColor,
      padding: '5px 16px 5px 4px',
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 660px)': {
        padding: '2px 5px',
      },
    },
    textTag: {
      textTransform: 'uppercase',
      fontWeight: 400,
    },
  }));

  function handleTagFormatting(unformatted_tags: string) {
    const tagsFormatted = unformatted_tags.split(',');
    setTagsItems(tagsFormatted);
  }

  useEffect(() => {
    if (tags) handleTagFormatting(tags);
  }, [tags]);

  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        {organ && (
          <Typography className={classes.time}>
            <span style={{ fontWeight: 'bold' }}>Órgão: </span>
            {organ}
          </Typography>
        )}
        <Box className={classes.timeCategoria}>
          <Typography>Categorias:</Typography>
          <Box className={classes.tagBox}>
            <Typography className={classes.textTag} style={{ marginLeft: 4 }}>
              {category}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
