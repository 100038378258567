import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { MdSearchOff } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import adapterNews from '../../services/adapters/adapterNews';
import { apisiseci } from '../../services/api';
import { searchNew } from '../../services/noticias';
import { serviceForCategory } from '../../services/servico';
import { ES } from '../Home';
import { News } from '../News';
import { SearchI } from '../SearchService';
import { Breadcrumb, ServiceList, Title } from './components';

interface Params {
  slug: string;
}

export interface SearchII extends ES {
  results: SearchI[];
}

export interface CategoryII {
  id: number;
  slug: string;
  titulo: string;
  icone: string;
}

export default function CategoyService(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
    content: {
      width: '100%',
      maxWidth: '1040px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 16px',
      },
    },
    boxActions: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.textBody,
      fontWeight: 600,
      backgroundColor: colors.serviceCardBackgroundColor,
      textTransform: 'uppercase',
      padding: '8px 0px',
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(15),
      width: '100%',

      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    textTitleSelected: {
      color: colors.colorBackground,
      backgroundColor: colors.accentColor,
    },
    iconNotFound: {
      width: 80,
      height: 80,
      color: colors.subTitleColor,
    },
    buttonMoreNews: {
      color: colors.accentColor,
      padding: '8px 39px',
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      textTransform: 'uppercase',
      lineHeight: '24px',
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,
      textDecoration: 'none',
      marginBottom: 20,

      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
        textDecoration: 'none',
      },
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [listSearch, setListSearch] = useState<SearchII>();
  const [category, setCategory] = useState<CategoryII>();
  const [loading, setLoading] = useState<boolean>(false);
  const haveNews = atob(process.env.REACT_APP_HAVE_NOTICIAS);
  const [tabSelected, setTabelected] = useState<'servicos' | 'noticias'>(
    'servicos',
  );

  const [getNew, setGetNew] = useState<News>();

  const getSearch = async (page: number) => {
    setListSearch(undefined);
    setLoading(true);
    try {
      const { data } = await serviceForCategory(params.slug, page);
      if (data && data.results) {
        data.results.sort(
          (a: SearchII['results'][0], b: SearchII['results'][0]) =>
            a.titulo.localeCompare(b.titulo),
        );
      }
      setListSearch(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getCategories = async () => {
    const { data } = await apisiseci.get(`/cms/temas/?slug=${params.slug}`);
    if (data?.results?.length > 0) {
      setCategory(data.results[0]);

      await getNews(data.results[0].titulo, 1);
    }
  };

  const getNews = async (search: string, page: number) => {
    setGetNew(undefined);
    setLoading(true);
    try {
      setGetNew(undefined);
      const { data } = await searchNew(search, page);
      const newsData = await adapterNews(data, 'wp_v2');
      setGetNew(newsData);
      // console.log(data);
      setLoading(false);
    } catch (err) {
      setGetNew(undefined);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
    getSearch(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);
  return (
    <Box className={classes.main}>
      {category && (
        <>
          <Breadcrumb slug={category?.titulo} />
          <Title slug={category?.titulo} />
        </>
      )}
      {category ? (
        <>
          <Box
            marginTop='48px'
            width='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Box className={classes.content}>
              <Box className={classes.boxActions}>
                <Typography
                  onClick={(): void => setTabelected('servicos')}
                  className={
                    tabSelected === 'servicos'
                      ? `${classes.textTitle} ${classes.textTitleSelected}`
                      : classes.textTitle
                  }
                >
                  Serviços ({listSearch ? listSearch.results.length : 0})
                </Typography>
                {/*  {process.env.REACT_APP_HAVE_NOTICIAS === 'true' && (
                  <Typography
                    onClick={(): void => setTabelected('noticias')}
                    className={
                      tabSelected === 'noticias'
                        ? `${classes.textTitle} ${classes.textTitleSelected}`
                        : classes.textTitle
                    }
                  >
                    aa Notícias (
                    {
                      getNew ? getNew.results.length : 0
                    }
                    )
                  </Typography>
                )} */}
              </Box>
            </Box>
          </Box>
          {listSearch &&
            listSearch?.results.length > 0 &&
            tabSelected === 'servicos' && (
              <>
                <Box margin='0px 16px'>
                  <ServiceList listSearch={listSearch} />
                </Box>
                {/* <PaginationService
                getSearch={getSearch}
                listSearch={listSearch}
              /> */}
              </>
            )}
          {/* {getNew && haveNews === 'true' && getNew?.results && getNew?.results.length > 0 && tabSelected === 'noticias' && (
            <>
              <ListNews listNews={getNew} />
              <Box
                display="flex"
                justifyContent="center"
                marginTop="40px"
              >

                <Link
                  className={classes.buttonMoreNews}
                  href={
                `${process.env.REACT_APP_NOTICIAS_EXTERNAL_URL}/?s=${category.titulo}`
}
                  target="_blank"
                >
                  Ver mais notícias
                </Link>
              </Box>

            </>
          )} */}
        </>
      ) : null}
      {loading && (
        <Box
          marginBottom='60px'
          flex='1'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Box>
      )}
      {listSearch?.results.length === 0 &&
        !loading &&
        tabSelected === 'servicos' && (
          <Box
            minHeight='500px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
          >
            <MdSearchOff className={classes.iconNotFound} />
            <Typography
              style={{
                textAlign: 'center',
                marginTop: 24,
                color: colors.textBody,
              }}
              variant='h4'
            >
              Nenhum serviço encontrado
            </Typography>
          </Box>
        )}
      {getNew &&
        haveNews === 'true' &&
        getNew?.results &&
        getNew?.results?.length === 0 &&
        !loading &&
        tabSelected === 'noticias' && (
          <Box
            minHeight='500px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
          >
            <MdSearchOff className={classes.iconNotFound} />
            <Typography
              style={{
                textAlign: 'center',
                marginTop: 24,
                color: colors.textBody,
              }}
              variant='h4'
            >
              Nenhuma notícia encontrada
            </Typography>
          </Box>
        )}
    </Box>
  );
}
