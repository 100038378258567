import React, { ReactElement } from 'react';
import MetaTags from 'react-meta-tags';

export default function Tags(): ReactElement {
  return (
    <div className='wrapper'>
      <MetaTags>
        <title>Portal Único ms.gov.br</title>
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={atob(process.env.REACT_APP_TAG_FAVICON) ?? '/favicon.ico'}
        />
      </MetaTags>
    </div>
  );
}
