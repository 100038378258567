import React from 'react';
import NavBarLink from '../QuickAccess/components/NavBarLink';
import { Breadcrumb, Informations } from './components';

export default function Terms(): JSX.Element {
  return (
    <>
      <Breadcrumb slug='Meus dados' />
      <NavBarLink />
      <Informations />
    </>
  );
}
