import React from 'react';
import { Box, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.primary.main}`,
    alignItems: 'center',
    height: '280px',
  },
  BoxInfo: {
    width: '90%',
    maxWidth: '1037px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  Title: {
    height: '40px',
    top: '323px',
    fontFamily: 'Mulish',
    fontSize: '32px',
    lineHeight: '24px',
    marginBottom: '16px',
  },
  SubTitle: {
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'justify',
  },
  boxNavBar: {
    marginTop: '1px',
    marginBottom: '1px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.primary.main}`,
  },
  navBar: {
    minHeight: 66,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  navLinks: {
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    width: '230px',
    padding: '10px 10px',
    textAlign: 'center',
    textDecorationStyle: 'inherit',
    '&:hover': {
      color: `${theme.palette.secondary.main}`,
    },
  },
}));

export default function NavBarLink(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.boxNavBar}>
      <Box className={classes.navBar}>
        <Link
          style={{ textDecoration: 'none' }}
          className={classes.navLinks}
          href='/acesso-rapido'
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            history.push('/acesso-rapido');
          }}
        >
          INÍCIO
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          className={classes.navLinks}
          href='/meus_dados'
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            history.push('/meus_dados');
          }}
        >
          MINHAS INFORMAÇÕES
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          className={classes.navLinks}
          href='/meus_atendimentos'
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            history.push('/meus_atendimentos');
          }}
        >
          MEUS ATENDIMENTOS ONLINE
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          className={classes.navLinks}
          href='/meus_atendimentos'
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            history.push('/meus_agendamentos');
          }}
        >
          MEUS AGENDAMENTOS
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          className={classes.navLinks}
          href='/termos'
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            history.push('/termos');
          }}
        >
          TERMOS
        </Link>
      </Box>
    </Box>
  );
}
