import React, { useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MdClose } from 'react-icons/md';
import colorContext from '../../Context/colorContext';
import { reportBug } from '../../services/servico';
import getCookie from '../../utils/getCookies';

interface PropsModal {
  show: boolean;
  setShow: () => void;
  service_id: string;
  alert: React.Dispatch<
    React.SetStateAction<{
      message: string;
      type: 'error' | 'success' | 'info' | 'warning' | undefined;
    }>
  >;
}
export default function ModalReportBug({
  show,
  setShow,
  service_id,
  alert,
}: PropsModal): JSX.Element {
  const [reportText, setReportText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
    },
    main: {
      backgroundColor: colors.colorBackground,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 1200,
      width: '100%',
      height: 640,
      padding: '30px 50px',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        padding: '30px 24px',
      },
    },
    header: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    boxTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    title: {
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '28px',
      color: colors.textBody,
    },
    iconBug: {
      marginRight: 16,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: 16,
      },
    },
    form: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    button: {
      backgroundColor: colors.colorBackgroundSecundary,
      width: 200,
      height: 60,
      borderColor: colors.colorBackground,
      cursor: 'pointer',
    },
    textButton: {
      fontSize: 18,
      textTransform: 'uppercase',
      color: '#FFF',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    error: {
      fontSize: 14,
      color: colors.buttonErrorColor,
    },
    iconClose: {
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: 10,
        top: 10,
      },
    },
  }));
  const classes = useStyles();

  const handlePostError = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (reportText.length < 10) {
      setError(true);
      // return;
    }
    setReportText('');
    setError(false);

    if (reportText) {
      try {
        setLoading(true);
        const token: string | null = getCookie('gov_access_token_sso');
        await reportBug({
          token,
          conteudo: reportText,
          servico_id: service_id,
        });
        setShow();
        alert({
          message: 'Sua informação foi enviada com sucesso!',
          type: 'success',
        });
      } catch (err) {
        // eslint-disable-next-line no-console

        alert({
          message: 'Erro ao enviar a informação',
          type: 'error',
        });
        // alert(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal open={show} onClose={setShow} className={classes.modal}>
      <Box className={classes.main}>
        <Box className={classes.header}>
          <div className={classes.boxTitle}>
            <Typography className={classes.title}>Reportar erro</Typography>
          </div>
          <IconButton onClick={() => setShow()} className={classes.iconClose}>
            <MdClose style={{ color: colors.textBody }} />
          </IconButton>
        </Box>
        <form className={classes.form} onSubmit={handlePostError}>
          <Box width='100%'>
            <textarea
              name='bug'
              id='bug'
              cols={30}
              rows={20}
              value={reportText}
              onChange={event => setReportText(event.target.value)}
              style={{ width: '100%', resize: 'none' }}
            />
            {error ? (
              <Typography className={classes.error}>
                Digite mais informações
              </Typography>
            ) : undefined}
          </Box>
          {loading ? (
            <CircularProgress
              style={{ width: 28, height: 28 }}
              color='primary'
            />
          ) : (
            <button type='submit' className={classes.button}>
              <Typography className={classes.textButton}>Reportar</Typography>
            </button>
          )}
        </form>
      </Box>
    </Modal>
  );
}
