/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef, useState } from 'react';
import RefContext from '../../Context/refContext';
import adapterNews from '../../services/adapters/adapterNews';
import { apinoticias, apisiseci } from '../../services/api';
import { getAllNews } from '../../services/noticias';
import { getServiceTypeUser } from '../../services/servico';
import { totalUsers } from '../../services/temosSSO';
import { News } from '../News';
import {
  Apps,
  Banners,
  Categories,
  Indicators,
  NewsComponent,
  Search,
  ServiceProfile,
} from './components';
import Videos from './components/Videos';

export interface ES {
  count: number;
  next: number;
  previous: number;
  current: number;
  total_pages: number;
}
export interface Results {
  id: number;
  slug: string;
  titulo: string;
  icone: string;
}
export interface Category extends ES {
  results: Results[];
}

export interface ChannelAttendanteI {
  icone: string;
  titulo: string;
  url: string;
}

export interface AppsI extends ES {
  results: [
    {
      id: number;
      nome: string;
      descricao: string;
      url_apple: string;
      url_banner: string;
      url_google: string;
      url_logo: string;
    },
  ];
}

export interface Service {
  id: number;
  publico_especifico: string;
  slug: string;
  titulo: string;
  categoria_nome: string;
  categoria_icone: string;
  orgao_nome: string;
  categoria_slug?: string;
}

export interface ServiceProfileI {
  cidadao?: Service[];
  servidor?: Service[];
  empresa?: Service[];
  gestao_publica?: Service[];
}

export interface EstatisticI {
  agendamentos: number;
  agendamentos_realizados: number;
  total_usuarios: number;
  atendimentos: number;
  atendimentos_realizados: number;
  servicos_agendamento: number;
  servicos_online: number;
  servicos_total: number;
  total_solicitacoes: number;
}
export interface VideoProps extends ES {
  results: [
    {
      titulo: string;
      resumo: string;
      video_id: string;
    },
  ];
}
export interface ListBanners {
  titulo: string;
  url_imagem: string;
  url: string;
}

export default function Home(): JSX.Element {
  const [categories, setCategories] = useState<Category>();
  const [apps, setApps] = useState<AppsI>();
  const [serviceProfile, setServiceProfile] = useState<ServiceProfileI>();
  const [estatistic, setEstatistic] = useState<EstatisticI>();
  const [totalUsersData, setTotalUserData] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channels, setChannels] = useState<ChannelAttendanteI[]>([]);
  const [banners, setBanners] = useState<ListBanners[]>();
  const [videos, setVideos] = useState<VideoProps>();

  const [newsList, setNewsList] = useState<News>();
  const { refCategory, refService, refEstatistic, refNoticias } =
    useContext(RefContext);

  const refApps = useRef(null);

  const externalNoticiasBanner =
    atob(process.env.REACT_APP_NOTICIAS_EXTERNAL_BANNER) ?? '';
  const haveNews = atob(process.env.REACT_APP_HAVE_NOTICIAS);

  const getVideos = async () => {
    const { data } = await apinoticias.get('/cms/videos');
    setVideos(data);
  };

  const getCategories = async () => {
    const { data } = await apisiseci.get('/cms/temas/');
    setCategories(data);
  };

  const getBanners = async () => {
    const { data } = await apinoticias.get('/cms/banners');
    setBanners(data);
  };

  const getChannelAttendances = async () => {
    const { data } = await apisiseci.get('/cms/canais_atendimento/');
    setChannels(data);
  };

  const getApps = async () => {
    const { data } = await apisiseci.get('/cms/aplicativos/');
    setApps(data);
  };

  const getServiceProfile = async (
    userType: 'Cidadão' | 'Servidor' | 'Empresa' | 'Gestão Pública',
  ) => {
    const { data } = await getServiceTypeUser(userType);
    if (userType === 'Cidadão') {
      setServiceProfile(oldState => ({
        ...oldState,
        cidadao: [...data],
      }));
    }
    if (userType === 'Servidor') {
      setServiceProfile(oldState => ({
        ...oldState,
        servidor: [...data],
      }));
    }
    if (userType === 'Empresa') {
      setServiceProfile(oldState => ({
        ...oldState,
        empresa: [...data],
      }));
    }
    if (userType === 'Gestão Pública') {
      setServiceProfile(oldState => ({
        ...oldState,
        gestao_publica: [...data],
      }));
    }
  };

  const getEstatistic = async () => {
    const { data } = await apisiseci.get('estatisticas/');
    const { data: dataUsers } = await totalUsers();
    setTotalUserData(dataUsers?.total_usuarios);
    setEstatistic(data);
  };

  const getNews = async () => {
    try {
      const { data } = await getAllNews(1);

      const newsData = await adapterNews(data, 'wp_v2');

      setNewsList(newsData);
    } catch (error) {
      console.log('errro notícia', { error });
    }
  };

  useEffect(() => {
    getCategories();
    getApps();
    getServiceProfile('Cidadão');
    getServiceProfile('Servidor');
    getServiceProfile('Empresa');
    getServiceProfile('Gestão Pública');
    getEstatistic();
    getChannelAttendances();
    getNews();
    getBanners();
    getVideos();
  }, []);

  return (
    <>
      <Search />
      <section ref={refService} style={{ order: 1 }}>
        {(serviceProfile?.cidadao ||
          serviceProfile?.empresa ||
          serviceProfile?.servidor ||
          serviceProfile?.gestao_publica) && (
          <ServiceProfile listServiceProfile={serviceProfile} />
        )}
      </section>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <section ref={refCategory} style={{ order: 2 }}>
          {categories?.results && categories.results.length > 0 && (
            <Categories listCategories={categories} />
          )}
        </section>

        <section style={{ order: 3 }}>
          {banners?.length > 0 && <Banners listBanners={banners} />}
        </section>

        <section ref={refNoticias} style={{ order: 4 }}>
          {haveNews === 'true' &&
            newsList?.results?.length &&
            externalNoticiasBanner !== 'true' && (
              <NewsComponent newsList={newsList} />
            )}
        </section>

        <section style={{ order: 5 }}>
          {videos?.results?.length > 0 && <Videos videoList={videos.results} />}
        </section>

        <section ref={refEstatistic} style={{ order: 6 }}>
          {estatistic && (
            <Indicators indicators={estatistic} totalUsers={totalUsersData} />
          )}
        </section>

        <section ref={refApps} style={{ order: 7 }} id='apps_gov'>
          {apps?.results?.length ? <Apps listApps={apps} /> : null}
        </section>
      </div>
    </>
  );
}
