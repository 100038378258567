import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  serviceName: string;
  dateSolicitation: string;
  scheduleSolicitation: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textOrgan: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
  },
  smallText: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default function DetailChat({
  serviceName,
  dateSolicitation,
  scheduleSolicitation,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box display='flex' maxWidth='1052px' width='100%' flexDirection='column'>
        <Typography className={classes.textOrgan}>{serviceName}</Typography>
        <Typography className={classes.smallText}>
          Data da solicitação: {dateSolicitation}
        </Typography>
        <Typography className={classes.smallText}>
          Horário da solicitação: {scheduleSolicitation}
        </Typography>
      </Box>
    </Box>
  );
}
