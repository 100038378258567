/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, makeStyles, SvgIcon, Theme, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  boxEmoji: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 300,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  emojiCicle: {
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: '#E1E6EF',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20,
    },
  },
  emojiAngryCicle: {
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: '#E43C53',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20,
    },
  },
  emojiSadCicle: {
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: '#ECBE3A',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20,
    },
  },
  emojiHappyCicle: {
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: '#97CA5A',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20,
    },
  },
  boxText: {
    width: '100%',
    textAlign: 'center',
    marginTop: '16px',
    textTransform: 'uppercase',
  },
  textAngry: {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: 500,
    color: '#E43C53',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    [theme.breakpoints.down('xs')]: {},
  },
  textSad: {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: 500,
    color: '#ECBE3A',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    [theme.breakpoints.down('xs')]: {},
  },
  textHappy: {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: 500,
    color: '#97CA5A',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    [theme.breakpoints.down('xs')]: {},
  },
  boxTextLabel: {
    width: 80,
    textAlign: 'center',
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      width: 60,
    },
    [theme.breakpoints.down('xs')]: {
      width: 60,
    },
  },
  centeredBoxes: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  textLabel: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 500,
    color: '#666666',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
      fontWeight: 600,
    },
    [theme.breakpoints.down('xs')]: {
      fontWeight: 600,
    },
  },
  boxSizeLg: {},
}));

const EmoticonAngryIcon = props => (
  <SvgIcon viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM5 7.5V6L8 7.5C8 8.3 7.3 9 6.5 9C5.7 9 5 8.3 5 7.5ZM12.77 15.23C12.32 14.5 11.25 14 10 14C8.75 14 7.68 14.5 7.23 15.23L5.81 13.81C6.71 12.72 8.25 12 10 12C11.75 12 13.29 12.72 14.19 13.81L12.77 15.23ZM15 7.5C15 8.3 14.3 9 13.5 9C12.7 9 12 8.3 12 7.5L15 6V7.5Z'
      fill='white'
    />
  </SvgIcon>
);
const EmoticonSadIcon = props => (
  <SvgIcon viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM5 7.5C5 6.7 5.7 6 6.5 6C7.3 6 8 6.7 8 7.5C8 8.3 7.3 9 6.5 9C5.7 9 5 8.3 5 7.5ZM12.77 15.23C12.32 14.5 11.25 14 10 14C8.75 14 7.68 14.5 7.23 15.23L5.81 13.81C6.71 12.72 8.25 12 10 12C11.75 12 13.29 12.72 14.19 13.81L12.77 15.23ZM13.5 9C12.7 9 12 8.3 12 7.5C12 6.7 12.7 6 13.5 6C14.3 6 15 6.7 15 7.5C15 8.3 14.3 9 13.5 9Z'
      fill='white'
    />
  </SvgIcon>
);
const EmoticonNeutralIcon = props => (
  <SvgIcon viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M5 7.5C5 7.10218 5.15804 6.72064 5.43934 6.43934C5.72064 6.15804 6.10218 6 6.5 6C6.89782 6 7.27936 6.15804 7.56066 6.43934C7.84196 6.72064 8 7.10218 8 7.5C8 7.89782 7.84196 8.27936 7.56066 8.56066C7.27936 8.84196 6.89782 9 6.5 9C6.10218 9 5.72064 8.84196 5.43934 8.56066C5.15804 8.27936 5 7.89782 5 7.5ZM14 14H6V12H14V14ZM13.5 9C13.1022 9 12.7206 8.84196 12.4393 8.56066C12.158 8.27936 12 7.89782 12 7.5C12 7.10218 12.158 6.72064 12.4393 6.43934C12.7206 6.15804 13.1022 6 13.5 6C13.8978 6 14.2794 6.15804 14.5607 6.43934C14.842 6.72064 15 7.10218 15 7.5C15 7.89782 14.842 8.27936 14.5607 8.56066C14.2794 8.84196 13.8978 9 13.5 9V9Z'
      fill='white'
    />
  </SvgIcon>
);

const EmoticonHappyIcon = props => (
  <SvgIcon viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM5 7.5C5 6.7 5.7 6 6.5 6C7.3 6 8 6.7 8 7.5C8 8.3 7.3 9 6.5 9C5.7 9 5 8.3 5 7.5ZM10 15.23C8.25 15.23 6.71 14.5 5.81 13.42L7.23 12C7.68 12.72 8.75 13.23 10 13.23C11.25 13.23 12.32 12.72 12.77 12L14.19 13.42C13.29 14.5 11.75 15.23 10 15.23ZM13.5 9C12.7 9 12 8.3 12 7.5C12 6.7 12.7 6 13.5 6C14.3 6 15 6.7 15 7.5C15 8.3 14.3 9 13.5 9Z'
      fill='white'
    />
  </SvgIcon>
);

const EmoticonExcidedIcon = props => (
  <SvgIcon viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0ZM6.88 5.82L9 7.94L7.94 9L6.88 7.94L5.82 9L4.76 7.94L6.88 5.82ZM10 15.5C7.67 15.5 5.69 14.04 4.89 12H15.11C14.31 14.04 12.33 15.5 10 15.5ZM14.18 9L13.12 7.94L12.06 9L11 7.94L13.12 5.82L15.24 7.94L14.18 9Z'
      fill='white'
    />
  </SvgIcon>
);

type Props = {
  value?: number;
  onChange?: (event: React.ChangeEvent<object>, value: number | null) => void;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  readOnly?: boolean;
};

export default function RatingEvaluation({
  value = 0,
  onChange,
  size = 'medium',
  disabled = false,
  readOnly = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const [valueRating, setValueRating] = React.useState<number>(value);
  const [hover, setHover] = React.useState<number>(-1);

  const handleChange = (
    event: React.ChangeEvent<object>,
    newValue: number | null,
  ) => {
    if (disabled || readOnly) {
      return;
    }
    setValueRating(newValue);
    onChange && onChange(event, newValue);
  };

  useEffect(() => {
    setValueRating(value);
  }, [value]);

  return (
    <Box>
      <Box
        className={classes.boxEmoji}
        style={
          size === 'small'
            ? { width: 150 }
            : size === 'large'
            ? { width: '100%' }
            : {}
        }
      >
        <Box className={classes.centeredBoxes}>
          <Box
            className={
              valueRating === 1 || hover === 1
                ? classes.emojiAngryCicle
                : classes.emojiCicle
            }
            style={
              size === 'small'
                ? { width: 30, height: 30 }
                : size === 'large'
                ? { width: 60, height: 60, margin: '0px 10px' }
                : {}
            }
            onClick={e => handleChange(e, 1)}
            onMouseOver={e => setHover(1)}
            onMouseLeave={e => setHover(-1)}
          >
            <EmoticonAngryIcon
              style={size === 'large' ? { fontSize: 30 } : {}}
            />
          </Box>
          {!readOnly && (
            <Box className={classes.boxTextLabel}>
              <Typography
                className={
                  valueRating === 1 || hover === 1
                    ? classes.textAngry
                    : classes.textLabel
                }
              >
                Muito insatisfeito
              </Typography>
            </Box>
          )}
        </Box>

        <Box className={classes.centeredBoxes}>
          <Box
            className={
              valueRating === 2 || hover === 2
                ? classes.emojiAngryCicle
                : classes.emojiCicle
            }
            style={
              size === 'small'
                ? { width: 30, height: 30 }
                : size === 'large'
                ? { width: 60, height: 60, margin: '0px 5px' }
                : {}
            }
            onClick={e => handleChange(e, 2)}
            onMouseOver={e => setHover(2)}
            onMouseLeave={e => setHover(-1)}
          >
            <EmoticonSadIcon style={size === 'large' ? { fontSize: 30 } : {}} />
          </Box>
          {!readOnly && (
            <Box className={classes.boxTextLabel}>
              <Typography
                className={
                  valueRating === 2 || hover === 2
                    ? classes.textAngry
                    : classes.textLabel
                }
              >
                Insatisfeito
              </Typography>
            </Box>
          )}
        </Box>

        <Box className={classes.centeredBoxes}>
          <Box
            className={
              valueRating === 3 || hover === 3
                ? classes.emojiSadCicle
                : classes.emojiCicle
            }
            style={
              size === 'small'
                ? { width: 30, height: 30 }
                : size === 'large'
                ? { width: 60, height: 60, margin: '0px 5px' }
                : {}
            }
            onClick={e => handleChange(e, 3)}
            onMouseOver={e => setHover(3)}
            onMouseLeave={e => setHover(-1)}
          >
            <EmoticonNeutralIcon
              style={size === 'large' ? { fontSize: 30 } : {}}
            />
          </Box>
          {!readOnly && (
            <Box className={classes.boxTextLabel}>
              <Typography
                className={
                  valueRating === 3 || hover === 3
                    ? classes.textSad
                    : classes.textLabel
                }
              >
                Regular
              </Typography>
            </Box>
          )}
        </Box>

        <Box className={classes.centeredBoxes}>
          <Box
            className={
              valueRating === 4 || hover === 4
                ? classes.emojiHappyCicle
                : classes.emojiCicle
            }
            style={
              size === 'small'
                ? { width: 30, height: 30 }
                : size === 'large'
                ? { width: 60, height: 60, margin: '0px 5px' }
                : {}
            }
            onClick={e => handleChange(e, 4)}
            onMouseOver={e => setHover(4)}
            onMouseLeave={e => setHover(-1)}
          >
            <EmoticonHappyIcon
              style={size === 'large' ? { fontSize: 30 } : {}}
            />
          </Box>
          {!readOnly && (
            <Box className={classes.boxTextLabel}>
              <Typography
                className={
                  valueRating === 4 || hover === 4
                    ? classes.textHappy
                    : classes.textLabel
                }
              >
                Satisfeito
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.centeredBoxes}>
          <Box
            className={
              valueRating === 5 || hover === 5
                ? classes.emojiHappyCicle
                : classes.emojiCicle
            }
            style={
              size === 'small'
                ? { width: 30, height: 30 }
                : size === 'large'
                ? { width: 60, height: 60, margin: '0px 5px' }
                : {}
            }
            onClick={e => handleChange(e, 5)}
            onMouseOver={e => setHover(5)}
            onMouseLeave={e => setHover(-1)}
          >
            <EmoticonExcidedIcon
              style={size === 'large' ? { fontSize: 30 } : {}}
            />
          </Box>
          {!readOnly && (
            <Box className={classes.boxTextLabel}>
              <Typography
                className={
                  valueRating === 5 || hover === 5
                    ? classes.textHappy
                    : classes.textLabel
                }
              >
                Muito satisfeito
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
