import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, { useContext } from 'react';
import { MdInfo } from 'react-icons/md';
import { Breadcrumb, Title } from './components';

import colorContext from '../../Context/colorContext';
import theme from '../../services/theme-service';

export default function Accessibility(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 399,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
      color: colors.textBody,
      lineHeight: '24px',
    },
    box: {
      width: '100%',
      display: 'flex',
      maxWidth: '1040px',
      marginBottom: '40px',
    },
    title: {
      fontSize: 24,
      marginBottom: 20,
    },
    subtitle: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    content: {
      marginBottom: 10,
    },
    emphasis: {
      color: colors.accentColor,
      fontWeight: 'bold',
    },
    alert: {
      fontSize: theme.typography.pxToRem(16),
      backgroundColor: colors.favoriteBoxColor,
      color: colors.textBody,
      borderLeft: `4px solid ${colors.accentColor}`,
      padding: '22px 48px 22px 26px',
      marginBottom: '30px',
    },
    icon: {
      color: colors.accentColor,
    },
    info: {
      width: '75%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    gif: {
      width: '186px',
      height: '162px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Breadcrumb slug='Acessibilidade' />
      <Title />
      <Box padding='49px 10px'>
        <Box className={classes.box}>
          <Alert
            icon={<MdInfo className={classes.icon} />}
            className={classes.alert}
          >
            Este site foi construído conforme os padrões de acessibilidade do{' '}
            <strong>W3C (World Wide Web Consortium)</strong>, para possibilitar
            que todos os usuários tenham acesso ao seu conteúdo.
          </Alert>
        </Box>
        <Box className={classes.box}>
          <Box display='flex' justifyContent='space-between'>
            <Box className={classes.info}>
              <Typography className={classes.title}>1 - VLibras</Typography>
              <Box>
                <p>
                  O VLibras é um conjunto de ferramentas computacionais de
                  código aberto, que traduz conteúdos digitais (texto, áudio e
                  vídeo) para Língua Brasileira de Sinais - LIBRAS, tornando
                  computadores, celulares e plataformas Web acessíveis para
                  pessoas surdas.
                </p>
              </Box>
            </Box>
            <img
              src='/assets/images/vlibras.gif'
              alt='V-Libras'
              className={classes.gif}
            />
          </Box>
          <Box />
        </Box>
        <Box className={classes.box}>
          <Box>
            <Box className={classes.content}>
              <Typography className={classes.title}>
                2 - Atalhos pelo teclado
              </Typography>
              <Box>
                <Typography className={classes.subtitle}>
                  - Tamanho do texto
                </Typography>
                <ul>
                  <li>
                    Teclando-se <span className={classes.emphasis}>Ctrl +</span>{' '}
                    aumenta a fonte.
                  </li>
                  <li>
                    Teclando-se <span className={classes.emphasis}>Ctrl -</span>{' '}
                    diminui a fonte.
                  </li>
                  <li>
                    Teclando-se <span className={classes.emphasis}>Ctrl 0</span>{' '}
                    deixa a fonte da página padrão.
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.box}>
          <Box>
            <Typography className={classes.title}>
              3 - Compatibilidade com browsers
            </Typography>
            <p>Este portal é compatível com todos os navegadores modernos.</p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
