/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable no-restricted-syntax */
/* eslint-disable linebreak-style */
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ThumbDownOutlined, ThumbUpOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import RatingEvaluation from '../../Components/RatingEvaluation';
import colorContext from '../../Context/colorContext';
import {
  evaluationConfiguration,
  evaluationConfigurationSend,
} from '../../services/temosSISECI';
import getCookie from '../../utils/getCookies';
import Breadcrumb from '../DocumentValidation/components/Breadcrumb';

interface ApiResponse {
  count: number;
  next: null | string;
  previous: null | string;
  current: number;
  query_param: string;
  page_size_query: string;
  page_size: number;
  max_page_size: number;
  total_pages: number;
  results: ApiResult[];
}
interface LinkAvaliacao {
  id: string;
  ordem: number;
  titulo: string;
  acao: string;
  url: string;
}

interface ApiResult {
  id: string;
  versao: number;
  titulo: string;
  descricao: string;
  perguntas_avaliacao: ApiQuestion[];
  links_avaliacao?: LinkAvaliacao[];
}

interface ApiQuestion {
  id: string;
  ordem: number;
  titulo: string;
  descricao: string;
  tipo: 'Comentário' | 'Ambos' | 'Classificação' | 'Sim ou Não' | 'link';
}

export default function RatePortal(): JSX.Element {
  const { colors } = useContext(colorContext);

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      backgroundColor: colors.colorBackground,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      maxWidth: '800px',
      width: '100%',
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '20px',
      margin: '20px auto',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
    question: {
      margin: '20px 0',
      '& h3': {
        margin: '0px  0px 20px 0px',
        textAlign: 'center',
        fontSize: '24px',
        color: '#333',
        fontWeight: 'bold',
      },
    },
    link: {
      color: '',
    },
    textarea: {
      width: '100%',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      marginBottom: '10px',
      fontSize: '16px',
    },
    ratingContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '20px  10px',
      justifyContent: 'center',
    },
    buttonIcons: {
      width: 70,
      height: 70,
    },
    iconOk: {
      fontSize: 60,
      '&:hover': {
        color: colors.accentColor,
      },
    },
    iconOkSelected: {
      fontSize: 60,
      color: colors.accentColor,
    },
    iconNotOk: {
      fontSize: 60,
      '&:hover': {
        color: '#FF0000',
      },
    },
    iconNotOkSelected: {
      fontSize: 60,
      color: '#FF0000',
    },
    titleQuestion: {
      margin: '10px 0px',
      color: '#333',
      fontWeight: 'bold',
    },
    ratingText: {
      fontSize: '18px',
      margin: '20px 10px',
    },
    button: {
      marginTop: '20px',
      backgroundColor: theme.palette.primary.main,
      float: 'right',
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));
  const classes = useStyles();

  const [ratings, setRatings] = useState<{ [key: string]: number | null }>({});
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [evaluationConfig, setEvaluationConfig] = useState<ApiResponse | null>(
    null,
  );
  const [comments, setComments] = useState<{ [key: string]: string | null }>(
    {},
  );
  const [foundInformation, setFoundInformation] = useState<{
    [key: string]: boolean;
  }>({});
  const tokenSso: string | null = getCookie('gov_access_token_sso');

  const getEvaluationConfiguration = async () => {
    try {
      const { data } = await evaluationConfiguration();
      if (data.results && data.results.length > 0) {
        setEvaluationConfig(data);
      } else {
        console.error('Não há resultados de avaliação disponíveis.');
      }
    } catch (error) {
      console.error('Erro ao buscar configuração de avaliação:', error);
    }
  };

  useEffect(() => {
    getEvaluationConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openErrorSnackbar = message => {
    setErrorMessage(message);
    setErrorSnackbarOpen(true);
  };

  const openSuccessSnackbar = message => {
    setSuccessMessage(message);
    setSuccessSnackbarOpen(true);
  };

  const handleEnviarRespostas = async () => {
    const respostas = [];

    for (const result of evaluationConfig?.results || []) {
      for (const pergunta of result.perguntas_avaliacao || []) {
        const classificacao = ratings[pergunta.id];
        const comentario =
          pergunta.tipo === 'Comentário' ||
          (pergunta.tipo === 'Ambos' && classificacao && classificacao <= 3)
            ? comments[pergunta.id] || null
            : null;

        if (classificacao && classificacao <= 3 && comentario === null) {
          openErrorSnackbar(`Por favor, ${pergunta.titulo}`);
          return;
        }

        if (pergunta.tipo === 'Ambos' && classificacao === undefined) {
          openErrorSnackbar(`Por favor, ${pergunta.titulo}`);
          return;
        }

        if (
          pergunta.tipo === 'Sim ou Não' &&
          foundInformation[pergunta.id] === undefined
        ) {
          openErrorSnackbar(`Por favor, ${pergunta.titulo}`);
          return;
        }

        const respostaObj = {
          id: pergunta.id,
          titulo: pergunta.titulo,
          classificacao:
            pergunta.tipo === 'Sim ou Não'
              ? foundInformation[pergunta.id]
                ? 'Sim'
                : 'Não'
              : classificacao || null,
          comentario: comments[pergunta.id],
        };
        if (pergunta.tipo !== 'link') {
          respostas.push(respostaObj);
        }
      }
    }

    if (respostas.length === 0) {
      openErrorSnackbar('Nenhuma resposta foi fornecida.');
      return;
    }

    const dataToSend = {
      avaliacao: evaluationConfig?.results[0]?.id,
      resposta: respostas,
    };

    try {
      const response = await evaluationConfigurationSend(tokenSso, dataToSend);
      if (response.status === 201) {
        openSuccessSnackbar('Avaliação enviada com sucesso!');
      } else {
        openErrorSnackbar(
          'Erro ao enviar a avaliação. Por favor, tente novamente mais tarde.',
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        openErrorSnackbar(
          'Sua sessão expirou ou não está logado. Por favor, faça <a href="/">login</a> novamente.',
        );
      } else if (error.response && error.response.status === 403) {
        openErrorSnackbar('Usuário já avaliou!');
      }
    }
  };

  const handleCommentChange = (perguntaId: string, comment: string) => {
    setComments(prevComments => ({
      ...prevComments,
      [perguntaId]: comment,
    }));
  };

  const handleRating = (perguntaId: string, newValue: number | null) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [perguntaId]: newValue,
    }));
  };

  const handlefoundInformationChange = (id, value) => {
    setFoundInformation(prevFoundInformation => ({
      ...prevFoundInformation,
      [id]: value,
    }));
  };

  const avaliarLink =
    evaluationConfig && evaluationConfig?.results[0].links_avaliacao;

  useEffect(() => {
    if (errorSnackbarOpen || successSnackbarOpen) {
      const timer = setTimeout(() => {
        setErrorSnackbarOpen(false);
        setSuccessSnackbarOpen(false);
        window.location.reload();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorSnackbarOpen, successSnackbarOpen]);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug='Avaliar Portal' />
      <Box className={classes.container}>
        {evaluationConfig?.results.length === 0 && (
          <div className={classes.question}>
            <h3>Não há resultados de avaliação disponíveis.</h3>
          </div>
        )}
        {evaluationConfig?.results.map(result => (
          <div key={result.id} className={classes.question}>
            <h3>{result.titulo}</h3>
            {result.perguntas_avaliacao.map(pergunta => (
              <div key={pergunta.id}>
                <Typography
                  className={classes.titleQuestion}
                  dangerouslySetInnerHTML={{ __html: pergunta.titulo }}
                />
                {pergunta.tipo === 'Classificação' ? (
                  <div className={classes.ratingContainer}>
                    <Typography className={classes.ratingText}>
                      Avaliação:
                    </Typography>
                    <RatingEvaluation
                      onChange={(e, newValue) =>
                        handleRating(pergunta.id, newValue)
                      }
                      value={ratings[pergunta.id] || 0}
                      size='large'
                    />
                  </div>
                ) : pergunta.tipo === 'Ambos' ? (
                  <div>
                    <div className={classes.ratingContainer}>
                      <RatingEvaluation
                        onChange={(e, newValue) =>
                          handleRating(pergunta.id, newValue)
                        }
                        value={ratings[pergunta.id] || 0}
                        size='large'
                      />
                    </div>
                    <textarea
                      placeholder={
                        ratings[pergunta.id] <= 3
                          ? 'Agradecemos o seu feedback! Por favor, forneça detalhes adicionais sobre sua experiência.'
                          : 'Agradecemos o seu feedback! compartilhe seus comentários adicionais aqui (opcional)'
                      }
                      rows={4}
                      className={classes.textarea}
                      required={
                        !ratings[pergunta.id] || ratings[pergunta.id] <= 3
                      }
                      value={comments[pergunta.id] || ''}
                      onChange={e =>
                        handleCommentChange(pergunta.id, e.target.value)
                      }
                    />
                  </div>
                ) : pergunta.tipo === 'Sim ou Não' ? (
                  <Box
                    display='flex'
                    justifyContent='center'
                    width='100%'
                    alignContent='center'
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignContent='center'
                      flexDirection='column'
                    >
                      <IconButton
                        onClick={() =>
                          handlefoundInformationChange(pergunta.id, true)
                        }
                        className={classes.iconOk}
                      >
                        <ThumbUpOutlined
                          className={
                            foundInformation[pergunta.id] ||
                            foundInformation[pergunta.id] === undefined
                              ? classes.iconOkSelected
                              : classes.iconOk
                          }
                        />
                      </IconButton>
                      <Typography align='center'>SIM</Typography>
                    </Box>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignContent='center'
                      flexDirection='column'
                    >
                      <IconButton
                        onClick={() =>
                          handlefoundInformationChange(pergunta.id, false)
                        }
                        className={classes.iconNotOk}
                      >
                        <ThumbDownOutlined
                          className={
                            !(foundInformation[pergunta.id] === false)
                              ? classes.iconNotOk
                              : classes.iconNotOkSelected
                          }
                        />
                      </IconButton>
                      <Typography align='center'>NÃO</Typography>
                    </Box>
                  </Box>
                ) : pergunta.tipo === 'Comentário' ? (
                  <textarea
                    placeholder={pergunta.descricao}
                    rows={4}
                    className={classes.textarea}
                    required={pergunta.tipo === 'Comentário'}
                    value={comments[pergunta.id] || ''}
                    onChange={e =>
                      handleCommentChange(pergunta.id, e.target.value)
                    }
                  />
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        ))}

        {avaliarLink &&
          avaliarLink.map((link, index) => (
            <Typography key={index} className={classes.titleQuestion}>
              <a
                href={link.url}
                target={link.acao === 'outra_guia' ? '_blank' : '_self'}
                rel='noopener noreferrer'
                style={{ cursor: 'pointer', color: '#00519C' }}
              >
                {link.titulo}
              </a>
            </Typography>
          ))}

        <Button
          variant='contained'
          color='primary'
          onClick={handleEnviarRespostas}
          className={classes.button}
        >
          Avaliar Portal
        </Button>
        <Snackbar
          open={errorSnackbarOpen || successSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => {
            setErrorSnackbarOpen(false);
            setSuccessSnackbarOpen(false);
          }}
        >
          <Alert
            severity={successMessage ? 'success' : 'error'}
            onClose={() => setErrorSnackbarOpen(false)}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: successMessage || errorMessage,
              }}
            />
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
