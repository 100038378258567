// eslint-disable-next-line import/prefer-default-export
export const hideMaskCPF = (cpf: string) => {
  if (!cpf) {
    return '';
  }
  return cpf.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    (_, g1, g2, g3, g4) => `${g1}.***.***-${g4}`,
  );
};
