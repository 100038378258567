import React from 'react';
import { Box, Divider, Typography, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  organName: string;
  status: string;
  buttonHideInfoChat: () => void;
  hideInfo: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textOrgan: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
  },
  boxInfostatusAttendanceOnline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusAttendanceOnline: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 12,
    color: '#737B7D',
  },
  iconStatus: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    margin: '0 4px 0 27px',
  },
}));

export default function OrganSolicitation({
  organName,
  status,
  buttonHideInfoChat,
  hideInfo,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        maxWidth='1052px'
        width='100%'
        flexDirection='column'
        marginTop='40px'
      >
        <Box className={classes.boxInfostatusAttendanceOnline}>
          <Box display='flex' flexWrap='wrap'>
            <Typography color='primary' className={classes.textOrgan}>
              {organName}
            </Typography>
            <Typography className={classes.statusAttendanceOnline}>
              <Typography
                className={classes.iconStatus}
                style={{ backgroundColor: '#3CD278' }}
              />
              ONLINE
            </Typography>
          </Box>
          <IconButton onClick={() => buttonHideInfoChat()}>
            {hideInfo ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>

        <Typography>{status}</Typography>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      </Box>
    </Box>
  );
}
