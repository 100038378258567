/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { AccountBalanceRounded } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { MdAccountBalance, MdLink } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import colorContext from '../../../../Context/colorContext';
import { OrgansI } from '../../../OrgansList';

interface Props {
  organ: OrgansI;
  listOrgan: {
    id: number;
    nome: string;
    sigla: string;
    slug: string;
  }[];
}

interface Params {
  slug: string;
  id?: string;
}

export default function Title({ organ, listOrgan }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 300,
      height: 'auto',
      backgroundColor: colors.cardAccentColor,
      boxShadow: colors.boxShadowColor,
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 0px',
      backgroundColor: colors.colorBackground,
    },
    organIcon: {
      width: 56,
      height: 56,
      color: colors.textAccentColor,
      marginBottom: 26,
      [theme.breakpoints.down('sm')]: {
        width: 31.67,
        height: 34.57,
      },
    },
    organAcronym: {
      fontSize: theme.typography.pxToRem(18),
      textTransform: 'uppercase',
      fontWeight: 400,
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    organTitle: {
      fontSize: theme.typography.pxToRem(32),
      color: colors.textAccentColor,
      marginBottom: 16,
      fontWeight: 800,
      lineHeight: '51.2px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    boxMenu: {
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
    },
    buttonMenu: {
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
      fontWeight: 600,
      lineHeight: '19.07px',
      fontFamily: 'Open Sans',
    },
    boxSocialMedia: {
      maxWidth: 1040,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        width: 'initial',
      },
    },
    titleButton: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    btnSocial: {
      color: theme.palette.text.primary,
      margin: '0 -4px',
    },
    menuOption: {
      color: colors.accentColor,
      textTransform: 'capitalize',
      whiteSpace: 'normal',
      backgroundColor: colors.colorBackground,
      '&:hover': {
        color: colors.accentColorBackground,
      },
    },
    time: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.subTitleColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    icon: {
      width: 24,
      height: 24,
      color: colors.accentColor,
    },
    buttonSocial: {
      padding: 0,
      '&:hover': {
        backgroundColor: colors.colorBackground,
      },
    },
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const params: Params = useParams();
  const url: string = (window as any).location;
  const [copy, setCopy] = useState<boolean>(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrganFilter = (event: any) => {
    history.push(event.target.getAttribute('value'));
  };

  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        width='100%'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        padding='64px 0px'
      >
        <AccountBalanceRounded className={classes.organIcon} />
        <Typography className={classes.organTitle}>{organ.nome}</Typography>
        <Typography className={classes.organAcronym}>{organ.sigla}</Typography>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxSocialMedia}>
          <Box className={classes.boxMenu}>
            <Button
              aria-controls='simple-menu'
              className={classes.buttonMenu}
              onClick={e => setAnchorEl(e.currentTarget)}
              startIcon={<MdAccountBalance className={classes.icon} />}
            >
              <Typography className={classes.titleButton}>
                {params.id ? 'Serviços por Órgãos' : 'Órgãos'}
              </Typography>
            </Button>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {listOrgan.map(list => (
                <MenuItem
                  key={list.id}
                  value={list.slug}
                  className={classes.menuOption}
                  onClick={handleOrganFilter}
                >
                  {list.nome.toLowerCase()}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box display='flex' alignItems='center' gridGap='16px'>
            <Typography className={classes.time}>Compartilhe:</Typography>
            <FacebookShareButton url={url} quote={url}>
              <IconButton className={classes.buttonSocial}>
                <FaFacebookF className={classes.icon} />
              </IconButton>
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <IconButton color='primary' className={classes.buttonSocial}>
                <FaTwitter className={classes.icon} />
              </IconButton>
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
              <IconButton color='primary' className={classes.buttonSocial}>
                <FaWhatsapp className={classes.icon} />
              </IconButton>
            </WhatsappShareButton>
            <IconButton
              className={classes.buttonSocial}
              onClick={() => {
                navigator.clipboard.writeText(url);
                setCopy(true);
              }}
              color='primary'
            >
              <MdLink className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={copy}
        autoHideDuration={2000}
        onClose={(): void => setCopy(false)}
        message='Copiado para área de transferência'
      />
    </Box>
  );
}
