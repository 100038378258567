import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { getSecoes } from '../../services/noticias';
import { Breadcrumb, Title } from './componets';

interface Params {
  slug: string;
}

const SiteMap = (): JSX.Element => {
  const { colors } = useContext(colorContext);
  const [pageData, setPageData] = useState<any[]>();
  const [loading, setLoading] = useState(true);
  const useStyles = makeStyles(theme => ({
    main: {
      width: '100%',
      minHeight: '40vh',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      padding: '0px 5px',
    },
    textTitleInitial: {
      position: 'relative',
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      padding: '10px 0px 0px 3px',
      fontWeight: 'bold',
      color: colors.textSecondary,
      left: 8,
      fontSize: theme.typography.pxToRem(36),
      '&::after': {
        content: '""',
        display: 'block',
        width: 4,
        height: 30,
        backgroundColor: colors.accentColor,
        position: 'absolute',
        bottom: 11,
        marginLeft: -14,
        left: 5,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(28),
        '&::after': {
          bottom: 4,
        },
      },
    },
    box: {
      display: 'flex',
      maxWidth: '1040px',
      width: '100%',
      flexDirection: 'column',
      marginTop: '34px',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      margin: 5,
    },
    content: {
      width: '100%',
      margin: '20px 20px',
      wordWrap: 'break-word',
    },
    links: {
      fontFamily: 'Open Sans',
      textDecoration: 'none',
      color: colors.textSecondary,
      fontSize: '16px',
      fontWeight: 400,
    },
    listItem: {
      marginBottom: '10px',
      paddingTop: '10px',
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();

  useEffect(() => {
    async function handleSections() {
      try {
        const { data } = await getSecoes();
        setPageData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    handleSections();
  }, [params.slug]);

  return (
    <>
      <Breadcrumb slug='Mapa do Site' />
      <Title title='Mapa do Site' />

      <Box className={classes.main}>
        <Box className={classes.box}>
          {loading && !pageData && (
            <Box
              marginBottom='60px'
              flex='1'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <CircularProgress />
            </Box>
          )}
          <Box className={classes.content}>
            <Typography>Mapa do Site</Typography>

            <ul>
              {pageData?.map(item => (
                <li className={classes.listItem} key={item.url}>
                  <Typography
                    component='a'
                    href={item.url}
                    className={classes.links}
                  >
                    {item.titulo}
                  </Typography>
                  {item?.links?.length > 0 && (
                    <ul>
                      {item.links.map(link => (
                        <li className={classes.listItem} key={link.url}>
                          <Typography
                            component='a'
                            href={link.url}
                            className={classes.links}
                          >
                            {link.titulo}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SiteMap;
