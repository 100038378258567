import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import React, { useContext } from 'react';
import { OrgansII } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  getOrgans: ({ page }) => void;
  listOrgans: OrgansII;
}

export default function PaginationService({
  getOrgans,
  listOrgans,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 62,
      marginBottom: 100,
      borderRadius: '50%',
      paddingTop: 20,
      paddingBottom: 20,
      color: '#fff',
      '& .Mui-selected': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackgroundSecundary,
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: colors.accentColor,
          color: colors.colorBackgroundSecundary,
        },
      },
    },
    paginationItem: {
      color: colors.textBody,
      '&:hover': {
        color: colors.colorBackground,
        backgroundColor: `${colors.accentColor} !important`,
      },
    },
    paginationSelected: {
      color: colors.colorBackground,
      backgroundColor: `${colors.accentColor} !important`,
      '&:hover': {
        color: colors.colorBackground,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Pagination
        renderItem={item => (
          <PaginationItem
            {...item}
            classes={{
              root: classes.paginationItem,
              selected: classes.paginationSelected,
            }}
          />
        )}
        count={listOrgans.total_pages}
        page={listOrgans.current}
        onChange={(e, page) => getOrgans({ page })}
      />
    </Box>
  );
}
