import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';

import colorContext from '../../Context/colorContext';
import { apisiseci } from '../../services/api';
import theme from '../../services/theme-service';
import { AppsI } from '../Home';
import { AppsList, Breadcrumb, Title } from './components';

export interface Gestor {
  arquivo: string;
  ativo: boolean;
  biografia: string;
  nome: string;
}
export interface OrgansI {
  contatoOrgao: {
    email: string;
    facebook: string;
    instagram: string;
    telefone: string;
    twitter: string;
    whatsapp: string;
    youtube: string;
  };
  created_at: string;
  descricao: string;
  endereco_orgao: {
    bairro: string;
    cep: string;
    cidade: {
      slug: string;
      cidade: string;
      estado: string;
    };
    complemento: string;
    dias_semana: string;
    endereco: string;
    hora_fim: string;
    hora_inicio: string;
    source: string;
  };
  gestor: Gestor[];
  id: number;
  nome: string;
  sigla: string;
  slug: string;
  updated_at: string;
  link: string;
  missao: string;
  valores: string;
  visao: string;
}

export default function AppsGov(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 399,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
    boxInput: {
      width: '99%',
      display: 'flex',
    },
    boxInputSearch: {
      display: 'flex',
      justifyContent: 'center',
      padding: '64px 0px 48px 0px',
    },
    formSearch: {
      maxWidth: 512,
      borderRadius: 4,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 10px',
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    inputSearch: {
      width: '100%',
      height: 64,
      outline: 'none',
      paddingLeft: 16,
      fontFamily: 'Open sans',
      fontSize: 14,
      borderRadius: 4,
      border: 'none',
      margin: '0px 10px',
      backgroundColor: 'transparent',
      color: colors.subTitleColor,
      '&::-webkit-input-placeholder': {
        color: colors.subTitleColor,
        fontSize: theme.typography.pxToRem(16),
      },
    },
    iconButton: {
      borderRadius: 1,
      color: colors.accentColor,
      padding: 19,
      minWidth: 49,
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [apps, setApps] = useState<AppsI>();

  const getApps = async () => {
    try {
      setLoading(true);
      const { data } = await apisiseci.get('/cms/aplicativos/');
      setApps(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getApps();
  }, []);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug='Lista de Aplicativos' />
      <Title />

      {loading && (
        <Box
          marginBottom='60px'
          width='100%'
          display='flex'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      )}

      {apps?.results?.length > 0 ? (
        <AppsList listApps={apps} />
      ) : (
        <>
          {!loading && (
            <Box
              marginBottom='60px'
              minHeight='100px'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Typography style={{ textAlign: 'center' }} variant='h4'>
                Nenhum APP encontrado
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
