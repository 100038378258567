/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { MdBookmark, MdBookmarkBorder, MdLink, MdPrint } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { TwitterShareButton, WhatsappShareButton } from 'react-share';
import { ModalNotAuth } from '../../../../Components';
import colorContext from '../../../../Context/colorContext';
import {
  favoriteService,
  getFavoriteService,
  removeFavoriteService,
} from '../../../../services/servico';
import formatDateTime from '../../../../utils/formatDateTime';
import getCookie from '../../../../utils/getCookies';
import { SearchI } from '../../../SearchService';

interface Props {
  slug: string;
  service: SearchI;
}

export default function Title({ slug, service }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 300,
      height: 'auto',
      backgroundColor: colors.cardAccentColor,
    },
    tag: {
      border: `1px solid ${colors.textAccentColor}`,
      borderRadius: 25,
      fontSize: theme.typography.pxToRem(13),
      color: colors.textAccentColor,
      lineHeight: '20px',
      textTransform: 'uppercase',
      padding: '5px 12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(11),
      },
    },
    favoriteBox: {
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    boxInfoService: {
      maxWidth: 1040,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '48px 0px',
      [theme.breakpoints.down('md')]: {
        padding: '48px 16px',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        padding: '48px 16px',
      },
    },
    serviceCategory: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textAccentColor,
      fontWeight: 400,
      lineHeight: '16.8px',
      marginBottom: 24,
      textTransform: 'uppercase',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
        textAlign: 'center',
        marginTop: 10,
      },
    },
    titleService: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      color: colors.textAccentColor,
      letterSpacing: '0.1 px',
      lineHeight: '51.2px',
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    subTitleService: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    subTitleServiceUpdate: {
      fontSize: theme.typography.pxToRem(12),
      color: colors.textAccentColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      marginBottom: 30,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    categoryIcon: {
      fontSize: 80,
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: 80,
        marginBottom: 10,
      },
    },
    listTag: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 0px',
      backgroundColor: colors.colorBackground,
      boxShadow: colors.boxShadowColor,
    },
    boxSocialsIcons: {
      maxWidth: 1040,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    btnSocial: {
      color: colors.textAccentColor,
      margin: '0 -1px',
    },
    subBoxInfoService: {
      width: '100%',
      maxWidth: 600,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '20px',
    },
    time: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    text: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    iconSocial: {
      color: colors.accentColor,
      width: 24,
      height: 24,
    },
    buttonSocial: {
      padding: 0,
      '&:hover': {
        backgroundColor: colors.colorBackground,
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: 3,
    },
    btnBack: {
      display: 'flex',
      alignItems: 'center',
      color: colors.textAccentColor,
      textDecoration: 'none',
      border: `1px solid ${colors.textAccentColor}`,
      borderRadius: 25,
      fontFamily: 'Open sans',
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      width: 93,
      height: 32,
      justifyContent: 'center',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        marginBottom: 59,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginBottom: 20,
      },
    },
    boxIconAndLinkBack: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      minHeight: 303,
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        minHeight: 100,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const matchesMobile = useMediaQuery('(max-width:954px)');
  const [serviceFavorite, setServiceFavorite] = useState<boolean>(false);
  const [myServiceFavorite, setMyServiceFavorite] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [copy, setCopy] = useState<boolean>(false);
  const history = useHistory();

  const url: string = (window as any).location;
  const token: string | null = getCookie('gov_access_token_sso');

  const handleSearchFavoritedService = useCallback(
    async data => {
      try {
        return data.results.find(
          services_favorited =>
            services_favorited.servico.slug === service.slug,
        ) as Promise<any>;
      } catch (error) {
        return {};
      }
    },
    [service.slug],
  );

  const handleFavoriteService = async () => {
    if (token) {
      setIsAuth(true);
      setLoading(true);
      if (!serviceFavorite) {
        const { status, data } = await favoriteService(service.id, token);
        if (status === 201) {
          setServiceFavorite(true);
          setMyServiceFavorite({ id: data.id });
        }
      } else {
        const response = await removeFavoriteService(
          myServiceFavorite.id,
          token,
        );
        if (response.status === 204) {
          setServiceFavorite(!serviceFavorite);
        }
      }
      setLoading(false);
    } else {
      setIsAuth(false);
    }
  };

  useEffect(() => {
    if (token) {
      const handleGetFavoriteService = async () => {
        setLoading(true);
        const { data } = await getFavoriteService(token);
        const searchServiceFavorited = await handleSearchFavoritedService(data);
        setLoading(false);
        if (searchServiceFavorited) {
          setMyServiceFavorite(searchServiceFavorited);
          setServiceFavorite(true);
        } else {
          setServiceFavorite(false);
        }
      };
      handleGetFavoriteService();
    }
  }, [handleSearchFavoritedService]);

  return (
    <Box className={classes.main}>
      <ModalNotAuth open={!isAuth} onClose={(): void => setIsAuth(true)} />
      <Box
        display='flex'
        width='100%'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
      >
        <Box className={classes.boxInfoService}>
          <Box maxWidth='789px'>
            <Typography
              component='a'
              href={`/categoria/${service.categoria_slug}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography className={classes.serviceCategory} variant='h2'>
                {service.categoria_nome}
              </Typography>
            </Typography>
            <Typography className={classes.titleService} variant='h1'>
              {slug}
            </Typography>
            <Typography className={classes.subTitleService} variant='h3'>
              {service.orgao_nome}
            </Typography>
            {matchesMobile && (
              <Box width='100%' display='flex' justifyContent='center'>
                <Box
                  onClick={() => {
                    history.goBack();
                  }}
                  className={classes.btnBack}
                  aria-label='Botão Voltar'
                >
                  <ArrowBack className={classes.icon} />
                  Voltar
                </Box>
              </Box>
            )}
            <Typography className={classes.subTitleServiceUpdate}>
              Última revisão:
              {service.ultima_revisao
                ? formatDateTime(service.ultima_revisao)
                : formatDateTime(service.updated_at)}
            </Typography>

            <Box className={classes.listTag}>
              {service.online && (
                <Typography className={classes.tag}>Digital</Typography>
              )}
              {service.agendavel && (
                <Typography className={classes.tag}>Agendável</Typography>
              )}
              {service.publico_especifico.length &&
                service.publico_especifico.map(publico => (
                  <Typography key={publico} className={classes.tag}>
                    {publico}
                  </Typography>
                ))}
            </Box>
          </Box>
          <Box className={classes.boxIconAndLinkBack}>
            <span className={`material-icons ${classes.categoryIcon}`}>
              {service.categoria_icone}
            </span>
            {!matchesMobile && (
              <Box
                onClick={() => {
                  history.goBack();
                }}
                className={classes.btnBack}
                aria-label='Botão Voltar'
              >
                <ArrowBack className={classes.icon} />
                Voltar
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxSocialsIcons}>
          <Button
            className={classes.favoriteBox}
            disabled={loading}
            onClick={() => handleFavoriteService()}
            startIcon={
              serviceFavorite ? (
                <MdBookmark style={{ width: 24, height: 24 }} />
              ) : (
                <MdBookmarkBorder style={{ width: 24, height: 24 }} />
              )
            }
          >
            {loading ? (
              <CircularProgress
                size={20}
                style={{ marginLeft: 5, color: colors.textAccentColor }}
              />
            ) : (
              <Typography className={classes.text}>
                {serviceFavorite
                  ? 'Remover dos Favoritos'
                  : 'Adicionar aos Favoritos'}
              </Typography>
            )}
          </Button>
          <Box display='flex' alignItems='center' gridGap='16px'>
            <Typography className={classes.time}>Compartilhe:</Typography>
            <IconButton className={classes.buttonSocial}>
              <a
                href={`/${service.categoria_slug}/${service.slug}/imprimir`}
                style={{ display: 'inherit' }}
                className={classes.btnSocial}
                target='_blank'
                rel='noreferrer'
              >
                <MdPrint className={classes.iconSocial} />
              </a>
            </IconButton>
            <TwitterShareButton url={url}>
              <IconButton className={classes.buttonSocial}>
                <FaTwitter className={classes.iconSocial} />
              </IconButton>
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
              <IconButton className={classes.buttonSocial}>
                <FaWhatsapp className={classes.iconSocial} />
              </IconButton>
            </WhatsappShareButton>
            <IconButton
              className={classes.buttonSocial}
              onClick={() => {
                navigator.clipboard.writeText(url);
                setCopy(true);
              }}
            >
              <MdLink className={classes.iconSocial} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={copy}
        autoHideDuration={2000}
        onClose={(): void => setCopy(false)}
        message='Copiado para área de transferência'
      />
    </Box>
  );
}
