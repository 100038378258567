/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';

interface Props {
  slug: string;
}

export default function Title({ slug }: Props): JSX.Element {
  const { colors } = useContext(colorContext);

  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
    },
    content: {
      display: 'flex',
      maxWidth: '1040px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    title: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      marginTop: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: 3,
    },
    btnBack: {
      display: 'flex',
      alignItems: 'center',
      color: colors.textAccentColor,
      textDecoration: 'none',
      border: `1px solid ${colors.textAccentColor}`,
      borderRadius: 25,
      fontFamily: 'Open sans',
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      width: 93,
      height: 32,
      justifyContent: 'center',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Box>
          <Typography className={classes.title} variant='h4'>
            Busca por assunto:
          </Typography>
          <Typography className={classes.text}>
            &#34;
            {slug}
            &#34;
          </Typography>
        </Box>
        <Box>
          <Link
            to='/'
            onClick={() => history.goBack()}
            className={classes.btnBack}
          >
            <ArrowBack className={classes.icon} />
            Voltar
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
