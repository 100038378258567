/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, CircularProgress, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import colorContext from '../../Context/colorContext';
import refContext from '../../Context/refContext';
import adapterNews from '../../services/adapters/adapterNews';
import {
  getAllNews,
  getNewForCategory,
  listNewsCategory,
} from '../../services/noticias';
import { ES } from '../Home';
import { Breadcrumb, ListNews, Pagination, Title } from './components';

export type ListCategory = {
  slug: string;
  icone: string;
  titulo: string;
}[];
export interface NewsI {
  id: number;
  slug: string;
  titulo: string;
  resumo: string;
  conteudo: string;
  publicado: boolean;
  imagem_destaque_url: string;
  autor_imagem: string;
  autor_nome: string;
  descricao_imagem: string;
  youtube_videoId: string;
  descricao_video: string;
  categoria_slug: string;
  categoria_icone: string;
  categoria_titulo: string;
  subtopico_slug: string;
  subtopico_icone: string;
  subtopico_titulo: string;
  orgao_slug: string;
  orgao_nome: string;
  orgao_sigla: string;
  created_at: string;
  updated_at: string;
  tags_noticia: string;
  categoria_id?: number;
}

export interface News extends ES {
  results: NewsI[];
}

export default function ServiceInfo(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(theme => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonMoreNews: {
      color: colors.accentColor,
      padding: '8px 39px',
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      textTransform: 'uppercase',
      lineHeight: '24px',
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,
      textDecoration: 'none',
      marginBottom: 20,

      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
        textDecoration: 'none',
      },
    },
  }));
  const classes = useStyles();
  const [news, setNews] = useState<News>();
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<ListCategory>();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<string>('');
  const [loadingCategories, setLoadingCategories] = useState(false);

  const { refInicio } = useContext(refContext);

  const getNews = async () => {
    setNews(undefined);
    setLoading(true);

    try {
      const { data } = await getAllNews(page);
      const newsData = await adapterNews(data, 'wp_v2');
      setNews(newsData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getCategories = async () => {
    setLoadingCategories(true);
    const { data } = await listNewsCategory();
    setCategories(data);
    setLoadingCategories(false);
  };

  useEffect(() => {
    const getNewsFiltered = async (params?: string) => {
      if (params === 'Todos') {
        getNews();
        setFilter('');
        return;
      }
      setNews(undefined);
      setLoading(true);
      try {
        const { data } = await getNewForCategory(params, 1);
        const newsData = await adapterNews(data, 'wp_v2');
        setNews(newsData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    if (filter) {
      getNewsFiltered(filter);
    }
  }, [filter, page]);

  useEffect(() => {
    getNews();
    refInicio?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [page]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug='Últimas notícias' />
      <Title />
      {/* {categories?.length > 0
        && process.env.REACT_APP_NOTICIAS_EXTERNAL === 'false'
        && !loadingCategories && (
        <Filter setFilter={setFilter} categories={categories} />
      )} */}
      {news && news.results.length > 0 && !loading && !loadingCategories && (
        <>
          <ListNews listNews={news} />
          {atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true' ? (
            <Box display='flex' justifyContent='center' marginTop='40px'>
              <Link
                className={classes.buttonMoreNews}
                href={atob(process.env.REACT_APP_NOTICIAS_EXTERNAL_URL)}
                target='_blank'
              >
                Ver mais notícias
              </Link>
            </Box>
          ) : (
            <Pagination setPage={setPage} listItem={news} />
          )}
        </>
      )}
      {(loading || loadingCategories) && (
        <Box
          marginBottom='60px'
          flex='1'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Box>
      )}
      {news?.results?.length === 0 && !loading && !loadingCategories && (
        <Box
          marginBottom='60px'
          minHeight='100px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <img
            style={{
              maxWidth: '280px',
              height: 'auto',
            }}
            src='/assets/images/not_found.svg'
            alt='Não encontrado'
          />
          <Typography
            style={{
              textAlign: 'center',
              marginTop: 50,
              color: colors.textBody,
            }}
            variant='h4'
          >
            Nenhuma notícia encontrada.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
