import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UserData } from '../../../../services/user';
import getCookie from '../../../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.primary.main}`,
    alignItems: 'center',
    height: '280px',
  },
  BoxInfo: {
    width: '90%',
    maxWidth: '1037px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  Title: {
    height: '40px',
    top: '323px',
    fontFamily: 'Mulish',
    fontSize: '32px',
    lineHeight: '24px',
    marginBottom: '16px',
  },
  SubTitle: {
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'justify',
  },
  boxNavBar: {
    marginTop: '1px',
    marginBottom: '1px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.primary.main}`,
  },
  navBar: {
    width: '1052px',
    minHeight: 66,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  navLinks: {
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    width: '230px',
    padding: '10px 10px',
    textDecorationStyle: 'inherit',
    '&:hover': {
      color: `${theme.palette.secondary.main}`,
    },
  },
}));

export default function Welcome(): JSX.Element {
  const classes = useStyles();
  const dataStorage: string | null = getCookie('gov_user_data');
  const [useDataUser, setUseDataUser] = useState<UserData>();

  useEffect(() => {
    if (dataStorage) {
      setUseDataUser(JSON.parse(dataStorage));
    }
  }, [dataStorage]);

  return (
    <>
      <Box className={classes.main}>
        <Box className={classes.BoxInfo}>
          <Typography className={classes.Title}>
            {useDataUser ? `Olá ${useDataUser?.nome} :)` : 'Olá'}
          </Typography>
          <Typography className={classes.SubTitle}>
            Bem-vindo(a) ao painel do cidadão{' '}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
