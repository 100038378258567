/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import colorContext from '../../../../Context/colorContext';
import {
  favoriteService,
  getFavoriteService,
  removeFavoriteService,
} from '../../../../services/servico';
import getCookie from '../../../../utils/getCookies';
import { SearchI } from '../../../SearchService';

interface Props {
  slug: string;
  service: SearchI;
}

export default function Title({ slug, service }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 300,
      height: 'auto',
      backgroundColor: colors.cardAccentColor,
    },
    tag: {
      border: `1px solid ${colors.textAccentColor}`,
      borderRadius: 25,
      fontSize: theme.typography.pxToRem(13),
      color: colors.textAccentColor,
      lineHeight: '20px',
      textTransform: 'uppercase',
      padding: '5px 12px',
    },
    favoriteBox: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    boxInfoService: {
      maxWidth: 1040,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '48px 0px',
    },
    serviceCategory: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textAccentColor,
      fontWeight: 400,
      lineHeight: '16.8px',
      marginBottom: 24,
      textTransform: 'uppercase',
    },
    titleService: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 800,
      color: colors.textAccentColor,
      letterSpacing: '0.1 px',
      lineHeight: '51.2px',
      marginBottom: 16,
    },
    subTitleService: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      marginBottom: 45,
    },
    categoryIcon: {
      fontSize: 80,
      color: colors.textAccentColor,
    },
    listTag: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    container: {
      width: '100%',
      borderTop: '1px solid #007C3C',
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 0px',
    },
    boxSocialsIcons: {
      maxWidth: 1040,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    btnSocial: {
      color: colors.textAccentColor,
      margin: '0 -1px',
    },
    subBoxInfoService: {
      width: '100%',
      maxWidth: 600,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '20px',
    },
    time: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      color: colors.textAccentColor,
    },
    iconSocial: {
      color: colors.textAccentColor,
      width: 24,
      height: 24,
    },
  }));
  const classes = useStyles();
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const matchesMobile = useMediaQuery('(max-width:575px)');
  const [serviceFavorite, setServiceFavorite] = useState<boolean>(false);
  const [myServiceFavorite, setMyServiceFavorite] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [copy, setCopy] = useState<boolean>(false);

  const url: string = (window as any).location;

  const handleSearchFavoritedService = useCallback(
    async data => {
      try {
        return data.results.find(
          services_favorited =>
            services_favorited.servico.slug === service.slug,
        ) as Promise<any>;
      } catch (error) {
        return {};
      }
    },
    [service.slug],
  );

  const handleFavoriteService = async () => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token) {
      setIsAuth(true);
      setLoading(true);
      if (!serviceFavorite) {
        const { status, data } = await favoriteService(service.id, token);
        if (status === 201) {
          setServiceFavorite(true);
          setMyServiceFavorite({ id: data.id });
        }
      } else {
        const response = await removeFavoriteService(
          myServiceFavorite.id,
          token,
        );
        if (response.status === 204) {
          setServiceFavorite(!serviceFavorite);
        }
      }
      setLoading(false);
    } else {
      setIsAuth(false);
    }
  };

  useEffect(() => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token) {
      const handleGetFavoriteService = async () => {
        const { data } = await getFavoriteService(token);
        const searchServiceFavorited = await handleSearchFavoritedService(data);
        if (searchServiceFavorited) {
          setMyServiceFavorite(searchServiceFavorited);
          setServiceFavorite(true);
        } else {
          setServiceFavorite(false);
        }
      };
      handleGetFavoriteService();
    }
  }, [handleSearchFavoritedService]);

  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        width='100%'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
      >
        <Box className={classes.boxInfoService}>
          <Box maxWidth='789px'>
            <Typography className={classes.serviceCategory}>
              {service.categoria_nome}
            </Typography>
            <Typography className={classes.titleService}>{slug}</Typography>
            <Typography className={classes.subTitleService}>
              {service.orgao_nome}
            </Typography>
            <Box className={classes.listTag}>
              {service.online && (
                <Typography className={classes.tag}>Digital</Typography>
              )}
              {service.agendavel && (
                <Typography className={classes.tag}>Agendável</Typography>
              )}
              {service.publico_especifico.length &&
                service.publico_especifico.map(publico => (
                  <Typography key={publico} className={classes.tag}>
                    {publico}
                  </Typography>
                ))}
            </Box>
          </Box>
          <span className={`material-icons ${classes.categoryIcon}`}>
            {service.categoria_icone}
          </span>
        </Box>
      </Box>
      {/* <Box className={classes.container}>
        <Box className={classes.boxSocialsIcons}>
          <Button
            className={classes.favoriteBox}
            disabled={loading}
            onClick={() => handleFavoriteService()}
            startIcon={
              serviceFavorite ? (
                <MdBookmark style={{ width: 24, height: 24 }} />
              ) : (
                <MdBookmarkBorder style={{ width: 24, height: 24 }} />
              )
            }
          >
            {loading ? (
              <CircularProgress
                size={20}
                style={{ marginLeft: 5, color: colors.textAccentColor }}
              />
            ) : (
              <Typography>
                {serviceFavorite
                  ? 'Remover dos Favoritos'
                  : 'Adicionar aos Favoritos'}
              </Typography>
            )}
          </Button>
          <Box display="flex" alignItems="center">
            <Typography className={classes.time}>Compartilhe:</Typography>
            <IconButton>
              <a
                href={`/servico/${service.slug}/imprimir`}
                style={{ display: 'inherit' }}
                className={classes.btnSocial}
                target="_blank"
                rel="noreferrer"
              >
                <MdPrint className={classes.iconSocial} />
              </a>
            </IconButton>
            <TwitterShareButton url={url}>
              <IconButton>
                <FaTwitter className={classes.iconSocial} />
              </IconButton>
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
              <IconButton>
                <FaWhatsapp className={classes.iconSocial} />
              </IconButton>
            </WhatsappShareButton>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(url);
                setCopy(true);
              }}
            >
              <FaLink className={classes.iconSocial} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={copy}
        autoHideDuration={2000}
        onClose={(): void => setCopy(false)}
        message="Copiado para área de transferência"
      /> */}
    </Box>
  );
}
