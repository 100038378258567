import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { loginRedirectPrivider } from '../../services/auth';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function ModalNotAuth({ open, onClose }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles({
    root: {
      backgroundColor: colors.serviceCardBackgroundColor,
      color: colors.textBody,
    },
    title: {
      color: colors.textBody,
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    content: {
      color: colors.textBody,
    },
    actions: {
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    buttonCloser: {
      color: colors.accentColor,
      textTransform: 'none',
    },
    buttonLogin: {
      textTransform: 'none',
      color: colors.accentColor,
      border: `1px solid ${colors.accentColor}`,
    },
  });
  const classes = useStyles();
  const location = useLocation();

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogTitle className={classes.title}>
        Você não tem permissão para continuar
      </DialogTitle>
      <DialogContent classes={{ root: classes.root }}>
        <DialogContentText className={classes.content}>
          Para continuar você precisa estar logado no portal único de serviços.
          Clique em acessar para ser redirecionado para o login único.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button className={classes.buttonCloser} onClick={onClose}>
          Fechar
        </Button>
        <Button className={classes.buttonLogin} onClick={loginRedirect}>
          Fazer login
        </Button>
      </DialogActions>
    </Dialog>
  );
}
