import React from 'react';
import { NavBarLink, Breadcrumb, FlashAccess } from './components';
import Welcome from './components/Welcome';

export default function QuickAccess(): JSX.Element {
  return (
    <>
      <Breadcrumb slug='Painel do cidadão' />
      <NavBarLink />
      <Welcome />
      <FlashAccess />
    </>
  );
}
