import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { MdClose, MdVideoLibrary } from 'react-icons/md';
import colorContext from '../../../../Context/colorContext';

interface Props {
  video_id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function TermoDialog({
  video_id,
  setOpen,
  open,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    textHeaderModal: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      width: '90%',
      color: colors.colorBackground,
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 13,
    },
    header: {
      background: colors.accentColor,
    },
    headerBox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    buttonClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: colors.colorBackground,
    },
    content: {
      backgroundColor: '#000',
      padding: 0,
    },
  }));
  const classes = useStyles();
  const [showIframe, setShowIframe] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setShowIframe(true);
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <MdClose />
      </IconButton>
      <DialogTitle className={classes.header}>
        <div className={classes.headerBox}>
          <MdVideoLibrary
            style={{ fontSize: 24, color: colors.colorBackground }}
          />
          <Typography className={classes.textHeaderModal}>Vídeo</Typography>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {showIframe && (
          <iframe
            width='100%'
            height='500'
            src={`https://www.youtube.com/embed/${video_id}`}
            title='YouTube video player'
            frameBorder='0'
            allow='fullscreen;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
