import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import colorContext from '../../Context/colorContext';
import { getAllMenus } from '../../services/noticias';

interface SubMenu {
  id: string;
  slug: string;
  titulo: string;
  link: string | null;
  icone: string | null;
  link_externo: boolean | null;
  ordem: number;
  pagina_slug: string | null;
  pagina_publicado: boolean | null;
}

interface Result {
  id: string;
  slug: string;
  titulo: string;
  link: string;
  icone: string | null;
  local_exibicao: string;
  ordem: number;
  submenu_menu: SubMenu[];
}

export default function Licence(): JSX.Element {
  const { colors } = useContext(colorContext);
  const [footerMenus, setFooterMenus] = useState<Result[]>();
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 44,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#004F9F',
      color: colors.textBody,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column', // Ajusta a direção do flex
        padding: '16px',
      },
    },
    linkFooter: {
      color: '#fff',
      textDecoration: 'none',
      padding: '0px 5px',
      cursor: 'pointer',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        padding: '0px ',
        borderRight: 'none',
        marginBottom: '8px',
        display: 'block',
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: '22px',
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        marginBottom: '8px',
      },
    },
    nameCompany: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
    boxContainer: {
      display: 'flex',
      maxWidth: '1040px',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '8px',
      },
    },
  }));
  const classes = useStyles();

  const getSevices = async () => {
    try {
      const { data: dataMenus } = await getAllMenus();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const filteredMenus = dataMenus.filter(menu =>
        menu.titulo?.toLowerCase().includes('setdig'),
      );

      setFooterMenus(filteredMenus);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSevices();
  }, []);

  const matchesMobile = useMediaQuery('(min-width:660px)');
  return (
    <Box className={classes.main}>
      <Box className={classes.boxContainer}>
        <Typography className={classes.title}>
          {footerMenus && footerMenus[0]?.titulo}
        </Typography>
        <Box>
          {footerMenus &&
            footerMenus[0]?.submenu_menu
              ?.sort((a, b) => a.ordem - b.ordem)
              ?.map((submenu, submenuIndex) => (
                <a
                  key={submenu.id}
                  rel={submenu.link_externo ? 'noopener noreferrer' : ''}
                  target={submenu.link_externo ? '_blank' : '_self'}
                  href={
                    submenu.pagina_slug !== null
                      ? `/pagina/${submenu.pagina_slug}`
                      : submenu.link
                  }
                  className={classes.linkFooter}
                  style={{
                    borderRight: matchesMobile
                      ? submenuIndex < footerMenus[0].submenu_menu.length - 1
                        ? '2px solid #fff'
                        : 'none'
                      : '',
                    paddingRight: matchesMobile
                      ? submenuIndex < footerMenus[0].submenu_menu.length - 1
                        ? '5px'
                        : '0'
                      : '',
                  }}
                >
                  {submenu.titulo}
                </a>
              ))}
        </Box>
      </Box>
    </Box>
  );
}
