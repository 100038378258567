/* eslint-disable @typescript-eslint/ban-types */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import TimerIcon from '@material-ui/icons/Timer';
import React, { useContext, useEffect, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import colorContext from '../../../../Context/colorContext';
import { unitSchedule } from '../../../../services/servico';
import { SearchI, ServicoUnidadeI } from '../../../SearchService';

interface Props {
  service: SearchI;
}

type BusinessHoursData = {
  dia_semana: string[];
  intervalo_ativo: boolean;
  hora_inicio_manha: string;
  hora_inicio_tarde: string;
  hora_fim_manha: string;
  hora_fim_tarde: string;
};

export default function Localization({ service }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '64px 0px',
      borderBottom: `1px solid ${colors.dividerColor}`,
      [theme.breakpoints.down('md')]: {
        padding: '64px 16px',
      },
    },
    content: {
      display: 'flex',
      maxWidth: '1040px',
      width: '100%',
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px',
        justifyContent: 'center',
      },
    },
    boxUnitysList: {
      marginRight: 24,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24,
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    tag: {
      backgroundColor: colors.colorBackground,
      padding: '5px 12px',
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 25,
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 700,
      textTransform: 'uppercase',
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(11),
      },
    },
    labelText: {
      color: colors.textBody,
      fontWeight: 'normal',
      '&:hover': {
        color: colors.accentColor,
      },
    },
    textTitleSm: {
      fontWeight: 700,
      fontFamily: 'Open Sans',
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBlackGray,
      marginBottom: 24,
      alignSelf: 'flex-start',
      width: '100%',
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    textTitle: {
      fontWeight: 700,
      fontFamily: 'Open Sans',
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBlackGray,
      marginBottom: 32,
      alignSelf: 'flex-start',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      fontWeight: 400,
      lineHeight: '28.8px',
    },
    boxLocalizationSide: {
      flex: 1,
    },
    mainLocation: {
      margin: 24,
      width: '100%',
    },
    cardLocalization: {
      width: '100%',
      maxWidth: 688,
      borderRadius: 4,
      backgroundColor: colors.colorBackground,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: colors.boxShadowColor,
      border: `1px solid ${colors.borderShadowsColor}`,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        marginTop: 10,
      },
    },
    boxLocalization: {
      height: 240,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.serviceCardBackgroundColor,
    },
    localizationInfo: {
      padding: '24px 0px 39px 24px',
    },
    localeTitle: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(24),
      marginBottom: 16,
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    localizationSubTitle: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      marginBottom: 32,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    day: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      padding: theme.spacing(0.5, 0),
    },
    details: {
      flexDirection: 'column',
      backgroundColor: '#fff',
      zIndex: 1000,
      [theme.breakpoints.down('xs')]: {
        paddingRight: '8px',
        paddingLeft: '8px',
      },
    },
    iconSize: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    timeText: {
      margin: '0 8px',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    accordion: {
      width: 300,
      padding: '0 !important',
      boxShadow: colors.boxShadowColor,
      backgroundColor: '#F9F9F9',
      gap: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: 5,
      },
    },
    accordionDetails: {
      gap: 0,
      padding: 0,
      height: '100%',
      // maxHeight: 380,
      // overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      // gap: 32,
      // padding: '24px 16px 24px 16px',
      // '&::-webkit-scrollbar': {
      //   width: 8,
      // },
      // '&::-webkit-scrollbar-thumb': {
      //   backgroundColor: colors.dividerColor,
      //   borderRadius: 24,
      // },
    },
    boxTitleUnits: {
      gap: 0,
      padding: '0 12px',
      backgroundColor: colors.colorBackground,
      borderTop: `2px solid ${colors.accentColor}`,
      borderBottom: `1px solid ${colors.dividerColor}`,
    },
    boxTitleExpanded: {},
    titleUnits: {
      color: colors.accentColor,
      fontWeight: 700,
      fontFamily: 'Open Sans',
      fontSize: theme.typography.pxToRem(16),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    unityName: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      fontWeight: 400,
      cursor: 'pointer',
      borderBottom: `1px solid ${colors.dividerColor}`,
      padding: 12,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    selectedUnityName: {
      backgroundColor: colors.colorBackgroundSecundary,
      color: colors.textAccentColor,
      fontWeight: 600,
    },
    colorIcon: {
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(16),
    },
    radio: {
      color: colors.accentColor,
      '&.Mui-checked': {
        color: colors.accentColor,
      },
    },
    localizationContent: {
      flexDirection: 'row',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [BusinessHours, setBusinessHours] = useState<BusinessHoursData>();
  const [isOpen, setIsOpen] = useState(false);
  const [unidadeSelected, setUnidadeSelected] = useState<ServicoUnidadeI>(
    service.servicosUnidade[0],
  );
  const handleChangeUnity = (unityId: string) => {
    const unidadeClick = service.servicosUnidade.filter(
      // eslint-disable-next-line radix
      unidade => unidade.unidade.slug_unidade === unityId,
    );
    setUnidadeSelected(unidadeClick[0]);
  };

  function isBusinessOpen(BusinessHours) {
    const currentDay = new Date().getDay();
    const currentTime = new Date().toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    });

    // Se for sábado (6) ou domingo (0), a empresa está fechada
    if (currentDay === 6 || currentDay === 0) {
      return false;
    }

    // Função auxiliar para verificar se o horário atual está dentro do intervalo
    function isTimeWithinInterval(time, start, end) {
      if (start === 'None' || end === 'None') {
        return false;
      }
      return time >= start && time <= end;
    }

    // Verificar se o momento atual está dentro dos intervalos de tempo
    if (
      isTimeWithinInterval(
        currentTime,
        BusinessHours.hora_inicio_manha,
        BusinessHours.hora_fim_manha,
      ) ||
      isTimeWithinInterval(
        currentTime,
        BusinessHours.hora_inicio_tarde,
        BusinessHours.hora_fim_tarde,
      ) ||
      (BusinessHours.hora_inicio_manha !== 'None' &&
        BusinessHours.hora_fim_tarde !== 'None' &&
        currentTime >= BusinessHours.hora_inicio_manha &&
        currentTime <= BusinessHours.hora_fim_tarde)
    ) {
      return true;
    }

    return false;
  }

  const handleAvaliar = async (slug_unidade: string) => {
    try {
      if (slug_unidade) {
        const { data } = await unitSchedule({ slug_unidade: slug_unidade });
        if (data) {
          setBusinessHours(data[0] ? data[0].horario_unidade : []);
        } else {
          setBusinessHours(data[0]?.horario_unidade || []);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (unidadeSelected) {
      handleAvaliar(unidadeSelected.unidade.slug_unidade);
    }
  }, [service, unidadeSelected]);

  function formatTime(timeStr) {
    if (timeStr === 'None') return null;
    const [hour, minute] = timeStr.split(':');
    return `${hour}:${minute}`;
  }

  const handleChangeAccordion =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Typography className={classes.textTitleSm}>Onde solicitar</Typography>
        <Box className={classes.boxUnitysList}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChangeAccordion('panel1')}
            classes={{
              root: classes.accordion,
            }}
          >
            <AccordionSummary
              expandIcon={<MdExpandMore className={classes.colorIcon} />}
              classes={{
                root: classes.boxTitleUnits,
                content: classes.boxTitleExpanded,
              }}
            >
              <Typography className={classes.titleUnits}>
                Unidades disponíveis
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              {service.servicosUnidade?.map(unidade => (
                <Typography
                  key={unidade.id}
                  className={
                    unidade.id === unidadeSelected.id
                      ? `${classes.unityName} ${classes.selectedUnityName}`
                      : classes.unityName
                  }
                  onClick={() =>
                    handleChangeUnity(unidade.unidade.slug_unidade)
                  }
                >
                  {unidade.unidade.nome}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className={classes.boxLocalizationSide}>
          <Typography className={classes.textTitle}>Onde solicitar</Typography>
          <Box className={classes.localizationContent}>
            <Box className={classes.cardLocalization}>
              <Box className={classes.boxLocalization}>
                {unidadeSelected.unidade.source.indexOf(
                  'https://www.google.com/maps/embed?',
                ) !== -1 ? (
                  <iframe
                    width='100%'
                    height='226px'
                    src={unidadeSelected.unidade.source}
                    title='endereço'
                  />
                ) : (
                  <Typography
                    style={{
                      color: colors.textBody,
                      marginLeft: 20,
                    }}
                  >
                    Não foi possível carregar o mapa.
                  </Typography>
                )}
              </Box>
              <Box className={classes.localizationInfo}>
                <Typography className={classes.localeTitle}>
                  {unidadeSelected.unidade.nome}
                </Typography>
                <Typography className={classes.localizationSubTitle}>
                  {unidadeSelected.unidade.endereco} -{' '}
                  {unidadeSelected.unidade.bairro} -{' '}
                  {unidadeSelected.unidade.complemento && (
                    <>{unidadeSelected.unidade.complemento}</>
                  )}
                </Typography>
                <Box style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                  {unidadeSelected.agendamento && (
                    <Typography className={classes.tag}>Agendável</Typography>
                  )}
                  {unidadeSelected.atendimento && (
                    <Typography className={classes.tag}>Digital</Typography>
                  )}
                </Box>
                <Box style={{ marginRight: '24px', marginTop: '5px' }}>
                  <Box style={{ backgroundColor: '#fff' }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      onClick={() => setIsOpen(!isOpen)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Typography>Horário de funcionamento</Typography>
                      {BusinessHours &&
                        (isBusinessOpen(BusinessHours) ? (
                          <Typography color='primary'>Aberto</Typography>
                        ) : (
                          <Typography color='error'>Fechado</Typography>
                        ))}
                      <IconButton>
                        <ExpandMoreIcon
                          className={classes.iconSize}
                          style={{
                            transform: isOpen
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                          }}
                        />
                      </IconButton>
                    </Box>
                    <Divider />
                    {isOpen && (
                      <Box className={classes.details}>
                        {BusinessHours ? (
                          BusinessHours.dia_semana?.map(day => (
                            <Box
                              key={day}
                              style={{ backgroundColor: '#fff' }}
                              display='flex'
                              justifyContent='space-between'
                              alignItems='center'
                              my={1}
                            >
                              <Typography variant='body2'>
                                {day}
                                -feira
                              </Typography>
                              <Box
                                display='flex'
                                alignItems='center'
                                flexDirection={['column', 'row']}
                              >
                                <TimerIcon className={classes.iconSize} />
                                {BusinessHours.hora_inicio_manha !== 'None' &&
                                BusinessHours.hora_fim_tarde !== 'None' ? (
                                  <>
                                    {BusinessHours.hora_fim_manha !== 'None' &&
                                    BusinessHours.hora_inicio_tarde !==
                                      'None' ? (
                                      <>
                                        <Typography
                                          variant='body2'
                                          className={classes.timeText}
                                        >
                                          {formatTime(
                                            BusinessHours.hora_inicio_manha,
                                          )}{' '}
                                          -
                                          {formatTime(
                                            BusinessHours.hora_fim_manha,
                                          )}
                                        </Typography>
                                        <PauseCircleFilledIcon
                                          className={classes.iconSize}
                                        />
                                        <Typography
                                          variant='body2'
                                          className={classes.timeText}
                                        >
                                          {formatTime(
                                            BusinessHours.hora_inicio_tarde,
                                          )}{' '}
                                          -
                                          {formatTime(
                                            BusinessHours.hora_fim_tarde,
                                          )}
                                        </Typography>
                                      </>
                                    ) : (
                                      <Typography
                                        variant='body2'
                                        className={classes.timeText}
                                      >
                                        {formatTime(
                                          BusinessHours.hora_inicio_manha,
                                        )}{' '}
                                        Às{' '}
                                        {formatTime(
                                          BusinessHours.hora_fim_tarde,
                                        )}
                                      </Typography>
                                    )}
                                  </>
                                ) : (
                                  <Typography
                                    variant='body2'
                                    className={classes.timeText}
                                  >
                                    Horário não disponível
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Typography
                            variant='body2'
                            style={{ padding: '10px 0px' }}
                          >
                            Nenhum horário disponível no momento!
                          </Typography>
                        )}
                        <Divider />
                        <Typography
                          variant='caption'
                          color='textSecondary'
                          style={{ paddingTop: '8px' }}
                        >
                          Horários podem variar
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
