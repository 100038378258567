/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect } from 'react';
import theme from './services/theme-service';

type Props = {
  name: string;
  host: string;
  history: any;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
};

function MicroFrontend({
  name,
  host,
  history,
  setLoading,
}: Props): JSX.Element {
  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;

    const renderMicroFrontend = () => {
      if (window['render'.concat(name)]) {
        window[`render${name}`](`${name}-container`, history, theme);
      }
    };

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
      return;
    }

    fetch(`${host}/asset-manifest.json`)
      .then(res => {
        console.log('host: ', host);
        return res.json();
      })
      .then(manifest => {
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = `${host}${manifest.files['main.js']}`;
        script.onload = () => {
          setLoading(false);
          renderMicroFrontend();
        };
        document.head.appendChild(script);
      });

    return (): void =>
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <main id={`${name}-container`} />;
}

export default MicroFrontend;
