import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AssignmentOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '270px',
    backgroundColor: '#F3F6FA',
  },
  icon: {
    marginTop: '80px',
    width: 45,
    height: 55,
  },
  textTitle: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '24px',
    marginTop: '17px',
    color: `${theme.palette.primary.main}`,
    marginBottom: '94px',
  },
}));

export default function NavBarInfo(): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.main}>
        <AssignmentOutlined color='primary' className={classes.icon} />
        <Typography className={classes.textTitle}>Termos de aceite</Typography>
      </Box>
    </>
  );
}
