/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AssignmentInd } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    height: 270,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F3F6FA',
    marginBottom: 80,
  },
  container: {
    color: theme.palette.primary.main,
  },
}));

export default function Title(): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        maxWidth='1052px'
        alignItems='center'
        width='100%'
        justifyContent='center'
        flexDirection='column'
        className={classes.container}
      >
        <AssignmentInd style={{ width: 60, height: 60 }} />
        <Typography
          style={{
            textAlign: 'center',
          }}
          className={classes.container}
          variant='h4'
        >
          Atendimentos solicitados
        </Typography>
      </Box>
    </Box>
  );
}
