import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getService } from '../../services/servico';
import { SearchI } from '../SearchService';
import { ServiceDetais } from './components';

const useStyles = makeStyles(() => ({
  main: {
    padding: '0 20px',
    backgroundColor: '#fff',
    minHeight: '100vh',
  },
}));

interface Params {
  slug: string;
  modal?: string;
}
export interface ChannelAttendanteI {
  icone: string;
  titulo: string;
  url: string;
}

export default function ServiceInfoPrint(): JSX.Element {
  const classes = useStyles();
  const params: Params = useParams();
  const [service, setService] = useState<SearchI>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLogo, setLoadingLogo] = useState<boolean>(true);
  const [loadingBrasao, setLoadingBrasao] = useState<boolean>(true);

  const getServiceEspecific = async () => {
    setService(undefined);
    setLoading(true);

    try {
      const { data } = await getService(params.slug);
      setService(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const captcha = document.getElementsByClassName('grecaptcha-badge');

    if (captcha && captcha.length) {
      captcha[0].remove();
    }
  }, [service]);

  useEffect(() => {
    const enable = document.getElementsByClassName('enabled');
    if (enable && enable.length) {
      enable[0].remove();
    }
    getServiceEspecific();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    if (!loading && !loadingLogo && !loadingBrasao) {
      window.print();
    }
  }, [loading, loadingLogo, loadingBrasao]);

  return (
    <Box className={classes.main}>
      {service && (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '20px',
            }}
          >
            <Box style={{ flex: 0.9 }}>
              <img
                style={{
                  marginBottom: 10,
                  width: 135,
                  maxWidth: 135,
                  height: 'auto',
                  maxHeight: 135,
                }}
                alt='Entidade'
                src={atob(process.env.REACT_APP_LOGO_PRIMARY)}
                onLoad={() => setLoadingLogo(false)}
              />
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  marginBottom: 10,
                }}
              >
                <span role='img' aria-labelledby='Publicado'>
                  📝{' '}
                </span>
                Publicado em {atob(process.env.REACT_APP_LICENCE) || ''} (
                <span
                  style={{ textDecoration: 'underline' }}
                >{`${window.location.protocol}//${window.location.host}`}</span>
                )
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  marginBottom: 3,
                }}
              >
                {' '}
                <strong>Source URL: </strong>
                {`${window.location.protocol}//${window.location.host}/servico/${service.slug} `}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                flex: 0.1,
                alignContent: 'flex-end',
                width: 135,
                maxWidth: 135,
                height: 'auto',
                maxHeight: 135,
              }}
            >
              <img
                style={{
                  width: 135,
                  maxWidth: 135,
                  height: 'auto',
                  maxHeight: 135,
                }}
                alt='Brasão'
                src={atob(process.env.REACT_APP_LOGO_PRIMARY)}
                onLoad={() => setLoadingBrasao(false)}
              />
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography
              style={{
                fontFamily: 'Arial',
                color: '#000000',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: 18,
                marginTop: 20,
                marginBottom: 20,
                textTransform: 'uppercase',
              }}
            >
              {service.titulo}
            </Typography>
          </Box>
          <Box display='flex'>
            <Typography
              style={{
                fontFamily: 'Arial',
                fontSize: 12,
                fontWeight: 'normal',
                marginRight: 40,
                marginBottom: 20,
                textTransform: 'uppercase',
              }}
            >
              <span
                style={{
                  textDecoration: 'underline',
                }}
              >
                ÓRGÃO:
              </span>{' '}
              {service.orgao_nome}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Arial',
                fontSize: 12,
                fontWeight: 'normal',
                textTransform: 'uppercase',
              }}
            >
              <span
                style={{
                  textDecoration: 'underline',
                }}
              >
                CATEGORIA:
              </span>{' '}
              {service.categoria_nome}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              textTransform: 'uppercase',
              marginBottom: 20,
              fontFamily: 'Arial',
              fontStyle: 'normal',
              fontWeight: 'bold',
            }}
          >
            {service.agendavel && (
              <Typography
                style={{
                  fontSize: 13,
                  textTransform: 'uppercase',
                  marginRight: 10,
                }}
              >
                <span role='img' aria-labelledby='icon check'>
                  ✔
                </span>
                Agendável
              </Typography>
            )}
            {service.publico_especifico.length &&
              service.publico_especifico.map(publico => (
                <Typography
                  key={publico}
                  style={{
                    fontSize: 13,
                    textTransform: 'uppercase',
                    marginRight: 10,
                  }}
                >
                  <span role='img' aria-labelledby='icon check'>
                    ✔
                  </span>
                  {publico}
                </Typography>
              ))}
            {service.online && (
              <Typography
                style={{
                  fontSize: 13,
                  textTransform: 'uppercase',
                  marginRight: 10,
                }}
              >
                <span role='img' aria-labelledby='icon check'>
                  ✔
                </span>
                Digital
              </Typography>
            )}
          </Box>
          <Divider />
          <ServiceDetais service={service} />
          <Divider />
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontSize: 12,
              fontStyle: 'normal',
              fontWeight: 400,
              marginBottom: 3,
            }}
          >
            {' '}
            <strong>Source URL: </strong>
            {`${window.location.protocol}//${window.location.host}/servico/${service.slug} `}
          </Typography>
        </>
      )}
    </Box>
  );
}
