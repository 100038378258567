/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { EstatisticI } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  indicators: EstatisticI;
  totalUsers?: number;
}

export default function Indicators({
  indicators,
  totalUsers,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      padding: '56px 0px 80px 0px',
      display: 'flex',
      justifyContent: 'center',
      background: colors.accentColor,
      borderBottom: `1px solid ${colors.accentColor}`,
      alignItems: 'center',
    },
    textTitle: {
      fontSize: theme.typography.pxToRem(32),
      color: colors.colorBackground,
      fontWeight: 800,
      lineHeight: '38.4px',
      textAlign: 'center',
      marginBottom: 16,
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      lineHeight: '28.8px',
      color: colors.colorBackground,
      marginBottom: 40,
      [theme.breakpoints.down('xs')]: {
        padding: '0px 16px',
      },
    },
    boxIndicator: {
      width: 248,
      height: 145,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      backgroundColor: colors.colorBackground,
      borderRadius: 4,
      margin: '0px 8px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '0px 24px 7px 24px',
      },
    },
    indicatorNumber: {
      fontSize: theme.typography.pxToRem(48),
      fontWeight: 800,
      lineHeight: '57.6px',
      color: colors.accentColor,
    },
    indicatorText: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      lineHeight: '25.6px',
      color: colors.accentColor,
    },
  }));
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:935px)');
  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        maxWidth='1040px'
        width='100%'
        flexDirection='column'
        alignItems='center'
      >
        <Typography className={classes.textTitle} variant='h1'>
          Indicadores
        </Typography>
        <Typography className={classes.subTitle}>
          Acompanhe o desempenho dos serviços.
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          justifyContent={matches ? 'center' : 'center'}
          flexDirection={matches ? 'row' : 'column'}
          alignSelf='normal'
          margin={!matches ? '0px 16px' : ''}
        >
          <Box className={classes.boxIndicator}>
            <Typography className={classes.indicatorNumber} variant='h1'>
              {indicators.servicos_total}
            </Typography>
            <Typography className={classes.indicatorText} variant='h2'>
              Serviços no portal
            </Typography>
          </Box>
          {totalUsers && (
            <Box className={classes.boxIndicator}>
              <Typography className={classes.indicatorNumber}>
                {totalUsers}
              </Typography>
              <Typography className={classes.indicatorText} variant='h2'>
                Usuários cadastrados
              </Typography>
            </Box>
          )}
          {/* <Box className={classes.boxIndicator}>
            <Typography className={classes.indicatorNumber}>
              {indicators.servicos_agendamento}
            </Typography>
            <Typography className={classes.indicatorText} variant="h2">
              Serviços agendáveis
            </Typography>
          </Box>
          <Box className={classes.boxIndicator}>
            <Typography className={classes.indicatorNumber}>
              {indicators.agendamentos_realizados}
            </Typography>
            <Typography className={classes.indicatorText} variant="h2">
              Agendamentos concluídos
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
