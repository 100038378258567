import React, { useState } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import { MdTouchApp } from 'react-icons/md';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ModalShedulingPresential from '../ModalShedulingPresential';
import ModalAttendenceOnline from '../ModalAttendenceOnline';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignItems: 'center',
    height: 430,
  },
  mainMobile: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignItems: 'center',
    flexDirection: 'column',
    height: '518px',
    marginTop: '70px',
  },
  cards: {
    margin: '0 21px',
    width: '337px',
    height: '162px',
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    color: `${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    boxShadow:
      '0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.05)',
    borderRadius: 0,
    '&:hover': {
      color: '#fff',
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
      transition: 'all 0.2s ease',
    },
  },
  cardMobile: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    width: '47%',
    height: 204,
    color: '#000',
    backgroundColor: '#fff',
    boxShadow:
      '0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.05)',
    borderRadius: 0,
    '&:hover': {
      color: '#fff',
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
      transition: 'all 0.2s ease',
    },
  },
  title: {
    marginTop: '26px',
    fontSize: '32px',
    fontFamily: 'Mulish',
    fontWeight: 700,
    display: 'flex',
    marginBottom: '-56px',
    justifyContent: 'center',
    color: '#000',
  },
  titleMobile: {
    marginTop: '96px',
    fontSize: '32px',
    fontFamily: 'Mulish',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    color: `${theme.palette.primary.main}`,
  },
  textCard: {
    fontSize: '16px',
    textTransform: 'uppercase',
  },
}));

export default function FlashAccess(): JSX.Element {
  const [openModalShedulingPresential, setOpenShedulingPresential] =
    useState<boolean>(false);
  const [openAttendenceOnline, setOpenAttendenceOnline] =
    useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Typography className={matches ? classes.title : classes.titleMobile}>
        Acesso rápido
      </Typography>
      <Box className={matches ? classes.mainMobile : classes.main}>
        <Card
          className={matches ? classes.cardMobile : classes.cards}
          onClick={(): void => {
            history.push('/workspace');
          }}
        >
          <CardActionArea
            style={{
              height: '100%',
            }}
          >
            <CardContent
              style={{
                display: 'flex',
                margin: '0 21px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontSize: 40 }} className='material-icons'>
                <MdTouchApp />
              </span>
              <Typography align='center' className={classes.textCard}>
                Serviços digitais
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          className={matches ? classes.cardMobile : classes.cards}
          onClick={() => setOpenAttendenceOnline(true)}
        >
          <CardActionArea
            style={{
              height: '100%',
            }}
          >
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontSize: 40 }} className='material-icons'>
                <MdTouchApp />
              </span>
              <Typography align='center' className={classes.textCard}>
                Atendimento online rápido
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          className={matches ? classes.cardMobile : classes.cards}
          onClick={() => setOpenShedulingPresential(true)}
        >
          <CardActionArea
            style={{
              height: '100%',
            }}
          >
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontSize: 40 }} className='material-icons'>
                <MdTouchApp />
              </span>
              <Typography align='center' className={classes.textCard}>
                Agendamento rápido
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <ModalShedulingPresential
        open={openModalShedulingPresential}
        handleClose={(): void => setOpenShedulingPresential(false)}
      />
      <ModalAttendenceOnline
        open={openAttendenceOnline}
        handleClose={(): void => setOpenAttendenceOnline(false)}
      />
    </>
  );
}
