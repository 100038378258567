import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';
import { News } from '../../../News';

interface Props {
  newsList: News;
}

export default function NewsComponent({ newsList }: Props): JSX.Element {
  const { colors } = useContext(colorContext);

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      padding: '64px 0px 120px 0px',
    },
    textMore: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      color: colors.accentColor,
      marginTop: 40,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    cardNews: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 16px',
      },
    },
    newSubtitle: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.subTitleColor,
      fontWeight: 400,
      lineHeight: '18.2px',
      textTransform: 'capitalize',

      '&:first-child': {
        textTransform: 'uppercase',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    newTitle: {
      marginTop: 16,
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 700,
      color: colors.textBody,
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    IconNavite: {
      color: colors.accentColor,
      width: 44,
      height: 44,
      border: `1px solid ${colors.accentColor}`,
      borderRadius: '4px 4px 4px 4px',
      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
      },
    },
    barDivider: {
      width: '100%',
      marginTop: 8,
      height: 4,
      backgroundColor: colors.textBody,
    },
    fullTitle: {
      fontSize: theme.typography.pxToRem(32),
      color: colors.textBody,
      fontWeight: 800,
      lineHeight: '38.4px',
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubtitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      fontWeight: 400,
      lineHeight: '28.8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const redirectToNew = slug => {
    // if (process.env.REACT_APP_NOTICIAS_EXTERNAL === 'true') {
    //   (window as any).open(
    //     `${
    //       process.env.REACT_APP_NOTICIAS_EXTERNAL_URL
    //     }/pt/web/guest/-/${slug}`,
    //   );
    // } else {
    history.push(`/noticias/${slug}`);
    // }
  };

  return (
    <Box className={classes.main}>
      <Box display='flex' maxWidth='1040px' width='100%' flexDirection='column'>
        <Box>
          <Typography className={classes.fullTitle} variant='h5'>
            Notícias
          </Typography>
          <Typography className={classes.textSubtitle} variant='h5'>
            Confira as últimas notícias do órgão.
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: 49,
          }}
        >
          {newsList.results.slice(3, 6).map(newItem => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              key={newItem.id}
              className={classes.cardNews}
            >
              <Card
                key={newItem.id}
                style={{
                  backgroundColor: colors.colorBackground,
                  boxShadow: colors.boxShadowColor,
                }}
              >
                <Box>
                  <CardMedia
                    component='img'
                    alt='Noticias'
                    height='222'
                    image={newItem.imagem_destaque_url}
                    title='Noticias'
                  />
                  <CardContent style={{ padding: '24px 24px 21px 24px' }}>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Typography className={classes.newSubtitle}>
                        {newItem.categoria_titulo}
                      </Typography>
                      <Typography className={classes.newSubtitle}>
                        {format(parseISO(newItem.created_at), 'dd MMM, yyyy', {
                          locale: ptBR,
                        })}
                      </Typography>
                    </Box>
                    <Typography variant='h3' className={classes.newTitle}>
                      {newItem.titulo}
                    </Typography>
                    <Button
                      size='small'
                      color='primary'
                      onClick={(): void => redirectToNew(newItem.slug)}
                      className={classes.textMore}
                    >
                      Continue lendo
                    </Button>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
