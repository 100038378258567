import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  unity: string;
  service: string;
}

const useStyles = makeStyles(() => ({
  information: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '84px auto',
  },
  informationText: {
    maxWidth: 740,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    color: '#333333',
    textAlign: 'justify',
    lineHeight: '28px',
    margin: '0px 20px',
  },
  informationTextBold: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    color: '#333333',
    textDecoration: 'underline',
  },
}));

export default function InformationBox({ unity, service }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.information}>
      <Typography className={classes.informationText}>
        Infelizmente seu agendamento na unidade{' '}
        <span className={classes.informationTextBold}>{unity}</span>, para o
        serviço <span className={classes.informationTextBold}>{service}</span>,
        precisou ser cancelado, porém, você pode escolher uma nova data e
        horário disponíveis abaixo para realizar seu novo agendamento de forma
        prioritária para o serviço na mesma unidade.
      </Typography>
    </Box>
  );
}
