/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import ModalAlertsDialog from '../../Components/ModalAlertsDialog';
import TermoDialog from '../../Components/TermoDialog';
import MicroFrontend from '../../Microfrontend';

function WorkspaceMicro({ history, loading, setLoading }): JSX.Element {
  return (
    <MicroFrontend
      loading={loading}
      setLoading={setLoading}
      history={history}
      host={atob(process.env.REACT_APP_MICRO_FRONT_WORKSPACE) ?? ''}
      name='Workspace'
    />
  );
}

function Workspace({ history }): JSX.Element {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={50} color='primary' />
        </div>
      )}
      <div style={{ display: loading ? 'none' : 'block' }}>
        <WorkspaceMicro
          loading={loading}
          setLoading={setLoading}
          history={history}
        />
      </div>
      <div>
        <TermoDialog />
        <ModalAlertsDialog />
      </div>
    </>
  );
}
export default Workspace;
