/* eslint-disable no-underscore-dangle */
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const apisso = axios.create({
  baseURL: atob(process.env.REACT_APP_API_SSO) ?? '',
});

/* const ssoMti = axios.create({
  baseURL: atob(process.env.REACT_APP_SSO_MTI) ?? '',
}); */

const apisiseci = axios.create({
  baseURL: atob(process.env.REACT_APP_API_SISECI) ?? '',
});

const apinoticias = axios.create({
  baseURL: atob(process.env.REACT_APP_API_NOTICIAS) || '',
});

const apinoticiasExterna = axios.create({
  baseURL: atob(process.env.REACT_APP_NOTICIAS_EXTERNAL_URL) || '',
});

const apiformflow = axios.create({
  baseURL: atob(process.env.REACT_APP_API_FORM_FLOW),
});

const apiagencianoticiaExterna = axios.create({
  baseURL: atob(process.env.REACT_APP_AGENCIA_NOTICIA_EXTERNAL_API) || ' ',
});

apisiseci.defaults.headers.common.Authorization =
  atob(process.env.REACT_APP_API_SISECI_KEY) ?? '';
apisso.defaults.headers.common.Authorization =
  atob(process.env.REACT_APP_API_SSO_KEY) ?? '';
apinoticias.defaults.headers.common.Authorization =
  atob(process.env.REACT_APP_API_NOTICIAS_KEY) ?? '';

export {
  apisso,
  apisiseci,
  /*  ssoMti, */ apinoticias,
  apiformflow,
  apinoticiasExterna,
  apiagencianoticiaExterna,
};
