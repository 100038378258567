import { Box, CardContent, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';
import { Category } from '../../../Home';

interface Props {
  listCategory: Category;
}

export default function CategoryList({ listCategory }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    card: {
      borderRadius: 4,
      borderBottom: `2px solid ${colors.accentColor}`,
      textDecoration: 'none',
      height: 180,
      maxWidth: 160,
      width: '100%',
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.colorBackground,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: '#EDF5FB',
        transition: 'all 0.2s ease',
        '& $boxIcon': {
          backgroundColor: colors.colorBackground,
        },
      },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      borderBottom: '2px solid',
    },
    boxIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: '#EDF5FB',
      padding: 20,
    },
    serviceTitle: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      marginTop: 16,
      textAlign: 'center',
      fontWeight: 700,
      fontFamily: 'Open Sans',
    },
    text: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(20),
      textTransform: 'capitalize',
    },
    tag: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.palette.secondary.main,
      color: '#fff',
      padding: '2px 14px',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    textTag: {
      display: 'block',
      '@media (max-width: 660px)': {
        display: 'none',
      },
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box
        display='flex'
        maxWidth='1040px'
        alignItems='center'
        width='100%'
        justifyContent='inherit'
        flexDirection='row'
        flexWrap='wrap'
        gridGap='16px'
      >
        {listCategory.results.map(category => (
          <Link
            to={`/categoria/${category.slug}`}
            key={category.id}
            className={classes.card}
          >
            <CardContent className={classes.cardContent}>
              <Box className={classes.boxIcon}>
                <span
                  style={{ fontSize: 34, color: colors.accentColor }}
                  className='material-icons'
                >
                  {category.icone}
                </span>
              </Box>
              <Typography
                align='center'
                variant='h2'
                className={classes.serviceTitle}
              >
                {category.titulo}
              </Typography>
            </CardContent>
          </Link>
        ))}
      </Box>
    </Box>
  );
}
