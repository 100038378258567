import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QrCode from 'qrcode.react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HistoryI } from '../..';
import { cancelar } from '../../../../services/agendamento';
import dateFormatToBR from '../../../../utils/dateFormat';
import getCookie from '../../../../utils/getCookies';

export interface SimpleDialogProps {
  open: boolean;
  schedule: HistoryI;
  handleClose: () => void;
}

const useStyles = makeStyles(() => ({
  boxHeader: {
    backgroundColor: '#E53535',
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textCpf: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  textNumberCounter: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textCounter: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTansform: 'uppercase',
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'capitalize',
    color: '#737B7D',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  textService: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#373F41',
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',
  },
  textDateTime: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#373F41',
  },
  textTicket: {
    paddingTop: '20px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',
  },
  btnConfirm: {
    backgroundColor: '#3CD278',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',

    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00a04b',
    },
  },
  btnClose: {
    backgroundColor: '#b0bec5',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',

    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#808e95',
    },
  },
}));

export default function CancelDialog({
  open,
  handleClose,
  schedule,
}: SimpleDialogProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const handleCancel = async (id: number) => {
    const token: string | null = getCookie('gov_access_token_sso');

    setLoading(true);
    if (token) {
      try {
        const { data } = await cancelar(token, id);
        setLoading(false);
        if (data) {
          handleClose();
          history.go(0);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
    >
      <DialogTitle id='alert-dialog-title' className={classes.boxHeader}>
        <Typography className={classes.textName}>
          Deseja cancelar o agendamento?
        </Typography>
      </DialogTitle>
      <DialogContent style={{ borderRadius: '0' }}>
        <DialogContentText>
          <Grid container>
            <Grid item style={{ paddingBottom: '20px' }}>
              <Typography className={classes.textUnidade}>
                {schedule.unidade.nome.toLowerCase()}
              </Typography>
              <Typography className={classes.textService}>
                {schedule.servico.titulo}
              </Typography>

              <Typography className={classes.textOrgan}>
                {`Órgão: ${schedule.orgao_responsavel}`}
              </Typography>

              <Typography className={classes.textDateTime}>
                Data da solicitação:
                <Typography component='strong'>
                  {' '}
                  {` ${dateFormatToBR(schedule.data)}`}
                </Typography>
              </Typography>

              <Typography className={classes.textDateTime}>
                Horário da solicitação:{' '}
                <Typography component='strong'>
                  {schedule.hora.slice(0, 5)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify='space-around' alignItems='flex-start'>
            <Grid item xs={12} sm={12} md={8}>
              {schedule.hora_inicio && (
                <>
                  <Typography className={classes.textDateTime}>
                    Data do atendimento:
                    <Typography component='strong'>
                      {' '}
                      {` ${dateFormatToBR(schedule.data)}`}
                    </Typography>
                  </Typography>
                  <Typography className={classes.textDateTime}>
                    Horário do atendimento:
                    <Typography component='strong'>
                      {' '}
                      {schedule.hora_inicio.slice(0, 5)}
                    </Typography>
                  </Typography>
                </>
              )}

              <Typography className={classes.textDateTime}>
                Status do atendimento:
                <Typography component='strong'> {schedule.status}</Typography>
              </Typography>
              <Typography className={classes.textTicket}>
                {schedule.ticket}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <QrCode value={schedule.ticket} size={180} />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <Box
            marginBottom='60px'
            width='100%'
            display='flex'
            justifyContent='center'
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              variant='contained'
              color='primary'
              className={classes.btnConfirm}
              onClick={() => handleCancel(schedule.id)}
            >
              Sim, eu quero cancelar
            </Button>
            <Button
              onClick={handleClose}
              variant='contained'
              color='primary'
              className={classes.btnClose}
            >
              Não, eu não quero cancelar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
