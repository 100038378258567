import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { apisiseci } from '../../services/api';
import { getService } from '../../services/servico';
import { SearchI } from '../SearchService';
import {
  Breadcrumb,
  ConsumeService,
  Localization,
  ModalAttendanceOnline,
  ModalAttendancePresential,
  Rating,
  ServiceDetais,
  Title,
} from './components';
import GuidanceModal from './components/GuidanceModal';

export interface Params {
  slug: string;
  modal?: string;
}
export interface ChannelAttendanteI {
  icone: string;
  titulo: string;
  url: string;
}

export default function ServiceInfo(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 300,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [service, setService] = useState<SearchI>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSchedulerPresential, setOpenSchedulerPresential] =
    useState<boolean>(false);
  const [openSchedulerOnline, setOpenSchedulerOnline] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channels, setChannels] = useState<ChannelAttendanteI[]>([]);
  const [openGuidanceModal, setOpenGuidanceModal] = useState(false);
  const [isPresentialModal, setIsPresentialModal] = useState(null);

  const descriptionProblem = service && service.configuracao_agendamento.texto;

  const getChannelAttendances = async () => {
    const { data } = await apisiseci.get('/cms/canais_atendimento/');
    setChannels(data);
  };

  const getServiceEspecific = async () => {
    setService(undefined);
    setLoading(true);

    try {
      const { data } = await getService(params.slug);
      setService(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getServiceEspecific();
    if (params.modal === 'true') {
      setOpenSchedulerPresential(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    getChannelAttendances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = isPresential => {
    setIsPresentialModal(isPresential);
    if (!descriptionProblem) {
      if (isPresential) {
        setOpenSchedulerPresential(true);
      } else {
        setOpenSchedulerOnline(true);
      }
    } else {
      setOpenGuidanceModal(true);
    }
  };

  const handleConfirm = () => {
    if (isPresentialModal) {
      setOpenSchedulerPresential(true);
    } else {
      setOpenSchedulerOnline(true);
    }
    setOpenGuidanceModal(false);
  };

  return (
    <Box className={classes.main}>
      {loading && (
        <Box
          marginBottom='60px'
          flex='1'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Box>
      )}
      {service && (
        <>
          <Breadcrumb service={service} slug={service.titulo} />
          <Title slug={service.titulo} service={service} />
          <ServiceDetais service={service} />
          {service.servicosUnidade.length > 0 && (
            <Localization service={service} />
          )}
          <Rating service={service} />

          <ConsumeService
            openModalSchedulerPresential={(): void => handleOpenModal(true)}
            openModalSchedulerOnline={(): void => handleOpenModal(false)}
            service={service}
          />

          <ModalAttendancePresential
            open={openSchedulerPresential}
            handleClose={(): void => setOpenSchedulerPresential(false)}
            service={service}
          />

          <ModalAttendanceOnline
            open={openSchedulerOnline}
            handleClose={(): void => setOpenSchedulerOnline(false)}
            service={service}
          />

          <GuidanceModal
            open={openGuidanceModal}
            handleClose={() => setOpenGuidanceModal(false)}
            handleConfirm={handleConfirm}
            description={descriptionProblem}
          />
        </>
      )}
    </Box>
  );
}
