/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Category } from '../..';
import colorContext from '../../../../Context/colorContext';
import { CategoryListComponent } from '../../../Categorias/components';

interface Props {
  listCategories: Category;
}

export default function Categories({ listCategories }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      borderBottom: `1px solid ${colors.dividerColor}`,
    },
    card: {
      borderRadius: 4,
      borderBottom: `2px solid ${colors.accentColor}`,
      height: 212,
      maxWidth: 160,
      width: '100%',
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.serviceCardBackgroundColor,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 63, 126, 0.1)',
        transition: 'all 0.2s ease',
      },
    },
    cardMobile: {
      borderRadius: 4,
      borderBottom: `2px solid ${colors.accentColor}`,
      width: '47%',
      height: 204,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.serviceCardBackgroundColor,
      '&:hover': {
        color: '#fff',
        background: `linear-gradient(180deg, ${colors.accentColor} 0%, ${colors.accentColor} 100%)`,
        transition: 'all 0.2s ease',
      },
      cursor: 'pointer',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 32,
      height: '100%',
      borderBottom: '2px solid',
    },
    boxIcon: {
      width: 80,
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: colors.colorBackground,
    },
    serviceTitle: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
      marginTop: 16,
      textAlign: 'center',
      fontWeight: 600,
    },
    textTitleInitial: {
      fontSize: theme.typography.pxToRem(36),
      textTransform: 'uppercase',
      textAlign: 'left',
      marginBottom: 10,
      fontWeight: 800,
      color: colors.textBody,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    textSubTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      letterSpacing: '0.1px',
      lineHeight: '24px',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(16),
      },
    },
    buttonNavigate: {
      color: colors.accentColor,
      width: 40,
      height: 40,
      border: `1px solid ${colors.accentColor}`,
      '&:first-child': {
        borderRadius: '4px 0px 0px 4px',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0px',
      },
      '&:hover': {
        backgroundColor: colors.cardAccentColor,
        color: colors.textAccentColor,
      },
    },
    buttonLabel: {
      width: 'auto',
    },
    iconNavigate: {
      width: 24,
      height: 24,
      fontSize: 24,
    },
    slider: {
      '& .slick-list': {
        margin: 0,
        height: 212,
        '& .slick-track': {
          height: 212,
        },
      },
      '& .slick-slide': {
        padding: 0,
        marginRight: 16,
        width: '160px !important',
      },
    },
    buttonCategory: {
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,
      padding: '8px 40px',
      color: colors.accentColor,
      fontfamily: 'Open Sans',
      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
        transition: 'all 0.2s ease',
      },
    },
    boxInfo: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: '48px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    controls: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box id='categoria' className={classes.main}>
      <Box
        display='flex'
        maxWidth='1040px'
        width='100%'
        flexDirection='column'
        padding='64px 0px'
      >
        <Box className={classes.boxInfo}>
          <Box padding='0px 16px'>
            <Typography className={classes.textTitleInitial} variant='h1'>
              CATEGORIAS
            </Typography>
            <Typography className={classes.textSubTitle}>
              Encontre o serviço desejado navegando pelas categorias abaixo.
            </Typography>
          </Box>
        </Box>
        <CategoryListComponent listCategory={listCategories} />
      </Box>
    </Box>
  );
}
