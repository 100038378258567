/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  Snackbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import { BsFillGridFill } from 'react-icons/bs';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdExitToApp, MdExpandMore, MdMenu } from 'react-icons/md';
import { Link as LinkRouter, useHistory, useLocation } from 'react-router-dom';
import {
  clearStorage,
  getImageProfile,
  getUserData as getUserDataRequest,
  loginRedirectPrivider,
  logout,
} from '../../services/auth';

// eslint-disable-next-line import/extensions
import ColorContext from '../../Context/colorContext';
import RefContext from '../../Context/refContext';
import {
  listLinksCabecalho,
  listLogos,
  listSocialMidias,
} from '../../services/noticias';
import { UserData } from '../../services/user';
import { hideMaskCPF } from '../../utils/cpfMask';
import getCookie from '../../utils/getCookies';
import { getInitialsFromName } from '../../utils/nameFormat';
import { setCookie } from '../../utils/setCookie';
import AlertAcceptCookies from '../AlertAcceptCookies';
import ModalAlertsDialog from '../ModalAlertsDialog';
import TermoDialog from '../TermoDialog';
import HoverMenu from './components/HoverMenu';

interface LinkItem {
  ordem: number;
  titulo: string;
  url: string;
  link_externo: boolean;
}

interface SocialMediaLink {
  ordem: number;
  titulo: string;
  url_imagem: string;
  url: string;
}

export interface LogoData {
  titulo: string;
  url_imagem: string;
  url: string;
  tipo: string;
  sistema: string;
}

export default function Header(): JSX.Element {
  const { colors } = useContext(ColorContext);

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 132,
      background: colors.colorBackground,
      color: '#0F4B91',
      [theme.breakpoints.down('xs')]: {
        height: 60,
      },
    },
    text: {
      textTransform: 'capitalize',
      margin: '0px 15px',
      color: colors.textAccentColor,
      cursor: 'pointer',
    },
    textButton: {
      fontWeight: 500,
      fontStyle: 'normal',
      marginLeft: 3,
      fontSize: 14,
      color: colors.accentColor,
    },
    textName: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: colors.accentColor,
    },
    avatar: {
      height: 32,
      width: 32,
      backgroundColor: colors.colorBackground,
      marginRight: 10,
      border: '1px solid #fff',
    },
    logoImg: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: '16px',
        height: '80px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '16px',
        height: '45px',
      },
    },
    menuItem: {
      minHeight: 42,
      color: '#737B7D',
      minWidth: 236,
      backgroundColor: colors.colorBackground,
      '&:hover': {
        background: colors.colorBackground,
      },
    },
    textMenuItem: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: 10,
      fontWeight: 500,
      color: colors.textBody,
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 400,
      textAlign: 'start',
      color: colors.accentColor,
    },
    buttonAccessibility: {
      display: 'inline-block',
      padding: 6,
      minWidth: 0,
      minHeight: 0,
      fontSize: 14,
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.accentColor,
    },
    boxAccessibility: {
      display: 'flex',
      alignItems: 'center',
    },
    boxSubMenu: {
      height: 40,
      width: '100%',
      display: 'flex',
      backgroundColor: '#fff',
      color: '#0F4B91',
    },
    boxSub: {
      width: '1040px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    socialMidia: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    linksBoxSubMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: theme.typography.pxToRem(13),
      textDecoration: 'none',
      fontWeight: 700,
      color: '#0F4B91',
      cursor: 'pointer',
    },
    textLocale: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 15px',
      fontSize: 12,
      fontWeight: 500,
      color: '#3E3E3E',
      borderLeft: '1px solid #DBDFE2',
      borderRight: '1px solid #DBDFE2',
    },
    textFontContrast: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.textBody,
    },
    boxInfo: {
      fontWeight: 400,
      color: colors.accentColor,
    },
    textSocial: {
      fontSize: theme.typography.pxToRem(13),
      color: colors.textAccentColor,
      fontWeight: 400,
    },
    btnSocial: {
      display: 'flex',
      color: colors.textAccentColor,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      width: 25,
      height: 25,
      borderRadius: 4,
    },
    textContrast: {
      fontSize: theme.typography.pxToRem(15),
      color: colors.textBody,
      fontWeight: 600,
      textTransform: 'uppercase',
      lineHeight: '16px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    boxIcon: {
      width: 32,
      height: 32,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: colors.accentColor,
        opacity: 0.8,
      },
    },
    iconSocial: {},
    img: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '80%',
        height: 'auto',
      },
    },
    linkRouterContainer: {
      width: 180,
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('xs')]: {
        height: 'auto',
      },
      [theme.breakpoints.only('sm')]: {
        height: 48,
      },
      [theme.breakpoints.down('xs')]: {
        height: 48,
        width: 'fit-content',
        display: 'flex',
      },
    },
    loginButton: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'normal',
      fontFamily: 'Open Sans',
      color: colors.accentColor,
      backgroundColor: colors.colorBackground,
      marginRight: '16px',
      borderRadius: 4,
      lineHeight: '18px',
      padding: '10px 18px 10px 16px',
      '&:hover': {
        color: colors.textAccentColor,
        backgroundColor: colors.colorBackgroundSecundary,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(10),
        padding: '4px 10px',
      },
    },
    loginBox: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    iconLogin: {
      fontSize: theme.typography.pxToRem(24),
      color: 'inherit',
      marginLeft: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    barActions: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        backgroundColor: colors.colorBackgroundSecundary,
        justifyContent: 'center',
      },
    },
    iconActions: {
      padding: '5px 0px',
    },
    accessibilityIcon: {
      width: 25,
      height: 25,
      color: '#0057AF',
    },
    btnMenu: {
      marginRight: 10,
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    },
    iconMenu: {
      fontSize: theme.typography.pxToRem(40),
      color: colors.colorBackgroundSecundary,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(30),
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery('(min-width:1012px)');
  const matchesMobile = useMediaQuery('(min-width:660px)');
  const mobileVersion = useMediaQuery('(min-width:1280px)');
  const token: string | null = getCookie('gov_access_token_sso');

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [anchorElUser, setAnchorElUser] =
    React.useState<HTMLButtonElement | null>(null);
  const [userData, setUserData] = useState<UserData | null>();
  const [message, setMessage] = useState<string>('');
  const [avatarUser, setAvatarUser] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [linkHeader, setLinksHeader] = useState<LinkItem[]>();
  const [socialMidia, setSocialMidia] = useState<SocialMediaLink[]>();
  const [logo, setLogo] = useState<LogoData[]>();

  const goToPage = (link: string) => {
    (window as any).open(link);
  };

  const { refInicio } = useContext(RefContext);

  useEffect(() => {
    const { state } = location;
    if (state as any) {
      setMessage((state as any).message);
    }
  }, [location]);

  const getUserData = async (tokenSso: string, tokenSiseciReceive?: string) => {
    try {
      setLoading(true);

      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);

      setUserData(data);
      setCookie('gov_user_data', JSON.stringify(data));
      // setLoading(false);
    } catch (err) {
      clearStorage();
      if (err.response?.data) {
        setMessage(err.response.data.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  const getLinksHeader = async () => {
    try {
      const { data: linksData } = await listLinksCabecalho();
      const { data: socialMediaData } = await listSocialMidias();
      const { data: Logos } = await listLogos();
      setLinksHeader(linksData);
      setSocialMidia(socialMediaData);
      setLogo(Logos);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (token) {
      getUserData(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      getUserData(token);
    }
    getLinksHeader();
  }, [token]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'user' | 'access',
  ) => {
    if (type === 'user') {
      setAnchorElUser(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElUser(null);
  };

  const logoutUser = () => {
    logout();
    setUserData(undefined);
    setAnchorElUser(null);
  };

  useEffect(() => {
    const handleGetImageProfile = async () => {
      if (token) {
        const { data } = await getImageProfile(token);

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo;
          setAvatarUser(image);
        }
      }
    };

    handleGetImageProfile();
  }, [token]);

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const renderMenus = (): JSX.Element => (
    <>
      {!matches && (
        <>
          <IconButton onClick={handleClick} className={classes.iconActions}>
            <MdMenu style={{ color: colors.textAccentColor }} />
          </IconButton>
        </>
      )}
      <TermoDialog />
      <ModalAlertsDialog />
      <AlertAcceptCookies />
    </>
  );

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  const nameSplitOne = (name: string): string => {
    const trimmedName = name.trim(); // Remove espaços no início e no fim
    const normalizedName = trimmedName.replace(/\s+/g, ' '); // Substitui múltiplos espaços por um único espaço
    return normalizedName.split(' ')[0]; // Retorna apenas o primeiro nome
  };

  const Scroll = (ref: React.MutableRefObject<any>) => {
    handleClose();
    if (ref && ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  };

  const renderItems = (): JSX.Element => (
    <header>
      {linkHeader &&
        linkHeader?.map(menu => (
          <MenuItem
            key={menu.titulo}
            style={{
              backgroundColor: colors.colorBackgroundSecundary,
            }}
          >
            <a
              key={menu.ordem}
              href={menu.url}
              target={menu.link_externo ? '_blank' : '_self'}
              rel={menu.link_externo ? 'noopener noreferrer' : ''}
              className={classes.linksBoxSubMenu}
              style={{ color: '#fff' }}
            >
              {menu.titulo}
            </a>
          </MenuItem>
        ))}
    </header>
  );

  const redirectToHome = () => {
    history.push('/');
  };

  useEffect(() => {
    Scroll(refInicio);
  }, [location]);

  return (
    <>
      {matches && (
        <div ref={matches ? refInicio : null} className={classes.boxSubMenu}>
          <Box margin='auto' className={classes.boxSub}>
            <Box display='flex' gridGap='32px'>
              {linkHeader &&
                linkHeader?.map(
                  menu =>
                    (menu.titulo === 'OUVIDORIA' ||
                      menu.titulo === 'TRANSPARÊNCIA') && (
                      <>
                        <Typography
                          onClick={(e: React.SyntheticEvent): void => {
                            preventDefault(e);
                            if (menu.link_externo) {
                              (window as any).open(menu.url);
                            } else {
                              history.push(menu.url);
                            }
                            handleClose();
                          }}
                          className={classes.linksBoxSubMenu}
                        >
                          {menu.titulo}
                        </Typography>
                      </>
                    ),
                )}
            </Box>
            <Box display='flex' gridGap='32px'>
              {linkHeader &&
                linkHeader?.map(
                  menu =>
                    menu.titulo !== 'OUVIDORIA' &&
                    menu.titulo !== 'TRANSPARÊNCIA' && (
                      <>
                        <a
                          key={menu.ordem}
                          href={menu.url}
                          target={menu.link_externo ? '_blank' : '_self'}
                          rel={menu.link_externo ? 'noopener noreferrer' : ''}
                          className={classes.linksBoxSubMenu}
                        >
                          {menu.titulo}
                        </a>
                      </>
                    ),
                )}
            </Box>
          </Box>
        </div>
      )}
      <Box className={classes.barActions}>{renderMenus()}</Box>
      <header
        id='back-to-top-anchor'
        style={{ top: 0, backgroundColor: '#004F9F' }}
        className={classes.main}
        ref={!matches ? refInicio : null}
      >
        <Snackbar
          autoHideDuration={3000}
          onClose={(): void => setMessage('')}
          open={!!message}
        >
          <Alert onClose={(): void => setMessage('')} severity='error'>
            {message}
          </Alert>
        </Snackbar>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {renderItems()}
        </Popover>
        <Popover
          open={Boolean(anchorElUser)}
          anchorEl={anchorElUser}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box>
            {userData && (
              <MenuItem
                style={{
                  backgroundColor: colors.colorBackground,
                  // minHeight: 73,
                }}
              >
                <Avatar
                  src={avatarUser || null}
                  alt={getInitialsFromName(userData.nome || '')}
                  className={classes.avatar}
                />
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                >
                  <Typography className={classes.textName}>
                    {nameSplitOne(userData?.nome?.toLowerCase())}
                  </Typography>
                  <Typography className={classes.textCpf}>
                    {hideMaskCPF(userData.cpf)}
                  </Typography>
                </Box>
              </MenuItem>
            )}
            <MenuItem className={classes.menuItem}>
              <LinkRouter
                to='/workspace'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  textDecoration: 'none',
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                <BsFillGridFill style={{ color: colors.accentColor }} />
                <Typography className={classes.textMenuItem}>
                  Meu Painel
                </Typography>
              </LinkRouter>
            </MenuItem>
            {/* <Divider  /> */}
            <MenuItem onClick={logoutUser} className={classes.menuItem}>
              <MdExitToApp style={{ color: colors.buttonErrorColor }} />
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </MenuItem>
            {/* <Divider /> */}
          </Box>
        </Popover>
        <Box
          display='flex'
          maxWidth='1040px'
          alignItems='center'
          margin='auto'
          alignContent={matchesMobile ? 'center' : 'flex-start'}
          width='100%'
          justifyContent='space-between'
          onClick={() => setOpenMenu(false)}
        >
          <Box onClick={redirectToHome} style={{ cursor: 'pointer' }}>
            <img
              alt={logo && logo[0].titulo}
              src={logo && logo[0].url_imagem}
              className={classes.logoImg}
            />
          </Box>
          <Popover
            open={Boolean(anchorElUser)}
            anchorEl={anchorElUser}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box className={classes.loginBox}>
              {userData && (
                <MenuItem
                  style={{
                    backgroundColor: colors.colorBackground,
                    color: '#fff',
                  }}
                >
                  <Avatar
                    src={avatarUser || null}
                    alt={getInitialsFromName(userData.nome || '')}
                    className={classes.avatar}
                  />
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                  >
                    <Typography className={classes.textName}>
                      {nameSplitOne(userData?.nome?.toLowerCase())}
                    </Typography>
                    <Typography className={classes.textCpf}>
                      {hideMaskCPF(userData.cpf)}
                    </Typography>
                  </Box>
                </MenuItem>
              )}
              <MenuItem className={classes.menuItem}>
                <LinkRouter
                  to='/workspace'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    textDecoration: 'none',
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  <BsFillGridFill style={{ color: colors.accentColor }} />
                  <Typography className={classes.textMenuItem}>
                    Meu Painel
                  </Typography>
                </LinkRouter>
              </MenuItem>
              {/* <Divider  /> */}
              <MenuItem onClick={logoutUser} className={classes.menuItem}>
                <MdExitToApp style={{ color: colors.buttonErrorColor }} />
                <Typography className={classes.textMenuItem}>Sair</Typography>
              </MenuItem>
              {/* <Divider /> */}
            </Box>
          </Popover>

          <Box className={classes.loginBox} alignSelf={mobileVersion && 'end'}>
            {loading ? (
              <CircularProgress
                style={{ width: 25, height: 25 }}
                color='primary'
              />
            ) : (
              <>
                {userData ? (
                  <Button
                    color='primary'
                    onClick={(e): void => handleClick(e, 'user')}
                  >
                    <Avatar
                      src={avatarUser || null}
                      alt={getInitialsFromName(userData.nome || '')}
                      className={classes.avatar}
                    />
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='flex-start'
                    >
                      <Typography
                        className={classes.textName}
                        style={{ color: '#fff' }}
                        color='primary'
                      >
                        {nameSplitOne(userData?.nome?.toLowerCase())}
                      </Typography>
                      <Typography
                        color='primary'
                        className={classes.textCpf}
                        style={{ color: '#fff' }}
                      >
                        {hideMaskCPF(userData?.cpf)}
                      </Typography>
                    </Box>
                    <MdExpandMore style={{ color: '#fff' }} />
                  </Button>
                ) : (
                  <Button
                    className={classes.loginButton}
                    onClick={loginRedirect}
                    startIcon={<FaRegUserCircle />}
                    classes={{
                      startIcon: classes.iconLogin,
                    }}
                  >
                    Entrar
                  </Button>
                )}
              </>
            )}
          </Box>
          <Box display='flex' className={classes.socialMidia}>
            <Box
              display='flex'
              style={{ gap: 10, marginTop: '45px' }}
              alignItems='center'
            >
              {socialMidia &&
                socialMidia?.map(data => (
                  <Box
                    key={data.titulo}
                    onClick={() => goToPage(data.url)}
                    title={data.titulo}
                  >
                    <img
                      src={data.url_imagem}
                      alt={data.titulo}
                      style={{ width: 25, height: 25, cursor: 'pointer' }}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </header>
      <HoverMenu open={openMenu} setOpen={setOpenMenu} />
    </>
  );
}
