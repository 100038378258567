import React, { useState } from 'react';
import { Box, Button, Card, Grid, Typography, Badge } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CancelDialog from '../CancelDialog';
import dateFormatToBR from '../../../../utils/dateFormat';
import { IMyAttendences, IAttendenceResponse } from '../..';

interface Props {
  listAttendences: IAttendenceResponse;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    marginBottom: 40,
  },
  card: {
    width: '100%',
    minHeight: 120,
    color: '#373F41',
    padding: 28,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 25,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    paddingBottom: '5px',
  },
  textService: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(24),
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#373F41',
    padding: '10px 0px',
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '24px',
    textDecorationLine: 'underline',
    color: '#373F41',
    paddingBottom: '10px',
  },
  textDateTime: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '24px',
    color: '#373F41',
  },
  textStatus: {
    paddingTop: '10px',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '24px',
    color: '#373F41',
    paddingBottom: '10px',
  },
  textNumberCounter: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(32),
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: theme.palette.primary.main,
    paddingBottom: '10px',
  },
  textCounter: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '24px',
    textTansform: 'uppercase',
    color: '#373F41',
  },
  btnCancel: {
    color: '#E53535',
    borderColor: 'rgba(229,53,53, 0.7)',
    marginRight: '20px',
    '&:hover': {
      backgroundColor: 'rgba(229,53,53, 0.1)',
      borderColor: '#E53535',
    },
  },
}));

export default function ServiceList({ listAttendences }: Props): JSX.Element {
  const classes = useStyles();
  const [attendenceSelected, setAttendenceSelected] =
    useState<IMyAttendences>();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const history = useHistory();

  const handleCancelDialog = (attendence: IMyAttendences) => {
    setOpenCancelDialog(true);
    setAttendenceSelected(attendence);
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setAttendenceSelected(undefined);
  };
  const handleToAttendence = (id: string) => {
    history.push(`/atendimento_online/${id}`);
  };

  interface IRespost {
    lido: boolean;
    user: {
      username: string;
    };
  }
  const countNotReadResp = (respostas: IRespost[], user_name: string) => {
    let count = 0;
    respostas.forEach(({ lido, user }) => {
      if (!lido && user.username !== user_name) {
        count += 1;
      }
    });
    return count;
  };

  return (
    <>
      <Box className={classes.main}>
        <Box
          display='flex'
          maxWidth='1052px'
          alignItems='center'
          width='100%'
          justifyContent='space-between'
          flexDirection='column'
        >
          {listAttendences?.results?.map(attendence => (
            <Card key={attendence.id} className={classes.card}>
              <Grid container justify='space-between' alignItems='flex-start'>
                <Grid item sm={10}>
                  <Typography className={classes.textUnidade}>
                    {attendence.unidade.nome.toLowerCase()}
                  </Typography>
                  <Typography className={classes.textService}>
                    {attendence.servico.titulo.toUpperCase()}
                  </Typography>

                  <Typography className={classes.textOrgan}>
                    {`Órgão: ${
                      attendence.orgao_responsavel || 'Sem descrição'
                    }`}
                  </Typography>

                  <Typography className={classes.textDateTime}>
                    <Typography component='strong'>Data:</Typography>
                    {` ${
                      dateFormatToBR(attendence.created_at, true).split(' ')[0]
                    }`}
                  </Typography>

                  <Typography className={classes.textDateTime}>
                    <Typography component='strong'>Horário:</Typography>
                    {` ${dateFormatToBR(attendence.created_at, true)
                      .split(' ')[1]
                      .slice(0, 5)}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    style={{
                      width: '75px',
                      textAlign: 'center',
                      paddingTop: '25px',
                    }}
                  >
                    <Typography className={classes.textNumberCounter}>
                      {attendence.guiche.ordem}
                    </Typography>
                    <Typography className={classes.textCounter}>
                      GUICHÊ
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container justify='space-between' alignItems='flex-start'>
                <Grid item>
                  <Typography className={classes.textStatus}>
                    <Typography component='strong'>{`${attendence.status}`}</Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  {attendence.status === 'Aguardando' ? (
                    <Button
                      variant='outlined'
                      color='inherit'
                      className={classes.btnCancel}
                      onClick={() => handleCancelDialog(attendence)}
                    >
                      <Typography>Cancelar</Typography>
                    </Button>
                  ) : (
                    <>
                      {countNotReadResp(
                        attendence.respostas,
                        attendence.user.username,
                      ) ? (
                        <Badge
                          badgeContent={countNotReadResp(
                            attendence.respostas,
                            attendence.user.username,
                          )}
                          color='primary'
                        >
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => handleToAttendence(attendence.id)}
                          >
                            Noficações
                          </Button>
                        </Badge>
                      ) : (
                        <>
                          {attendence.status !== 'Cancelado' ? (
                            <Button
                              variant='outlined'
                              color='primary'
                              onClick={() => handleToAttendence(attendence.id)}
                            >
                              Ver conversas
                            </Button>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Card>
          ))}
        </Box>
      </Box>
      {attendenceSelected && (
        <CancelDialog
          open={openCancelDialog}
          attendence={attendenceSelected}
          handleClose={handleCloseCancelDialog}
        />
      )}
    </>
  );
}
