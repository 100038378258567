/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AccessibilityRounded } from '@material-ui/icons/';
import colorContext from '../../../../Context/colorContext';

export default function Title(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
    },
    content: {
      display: 'flex',
      maxWidth: '1040px',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(32),
      color: colors.textAccentColor,
      fontWeight: 800,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    iconAccessibility: {
      width: 80,
      height: 80,
      color: colors.textAccentColor,
      [theme.breakpoints.down('sm')]: {
        width: 40,
        height: 40,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.content}>
        <Box>
          <Typography variant='h4' className={classes.title}>
            Acessibilidade
          </Typography>
          <Typography variant='h4' className={classes.subTitle}>
            Ajudar pessoas com diferentes deficiências a aproveitarem o conteúdo
            do portal.
          </Typography>
        </Box>
        <AccessibilityRounded className={classes.iconAccessibility} />
      </Box>
    </Box>
  );
}
