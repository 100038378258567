import { AxiosResponse } from 'axios';
import { apinoticias, apinoticiasExterna } from './api';

export const getAllNews = (page: number): Promise<AxiosResponse> => {
  if (atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true') {
    return apinoticiasExterna.get(
      `${atob(
        process.env.REACT_APP_NOTICIAS_EXTERNAL_ROUTER,
      )}/posts?page=${page}`,
    );
  }
  return apinoticias.get(`/cms/noticias/?page=${page}`);
};

export const getNew = (slug: string): Promise<AxiosResponse> => {
  if (atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true') {
    return apinoticiasExterna.get(
      `${atob(
        process.env.REACT_APP_NOTICIAS_EXTERNAL_ROUTER,
      )}/posts?slug=${slug}`,
    );
  }

  return apinoticias.get(`/cms/noticias/?slug=${slug}`);
};

export const getNewForCategory = (
  category_slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apinoticias.get(
    `/cms/noticias/?categoria_slug=${category_slug}&page=${page}`,
  );

export const getNewForCategoryById = (
  category_id: number,
  page: number,
): Promise<AxiosResponse> =>
  apinoticiasExterna.get(
    `${atob(
      process.env.REACT_APP_NOTICIAS_EXTERNAL_ROUTER,
    )}/posts?categories=${category_id}&page=${page}`,
  );

export const searchNew = (text: string, page: number) => {
  if (atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true') {
    return apinoticiasExterna.get(
      `${atob(
        process.env.REACT_APP_NOTICIAS_EXTERNAL_ROUTER,
      )}/posts?search=${text.toLocaleUpperCase()}&page=${page}`,
    );
  }

  return apinoticias.get(
    `/cms/noticias/?search=${text.toLocaleUpperCase()}&page=${page}`,
  );
};

export const listNewsCategory = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/categorias');

export const listLinksCabecalho = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/links-cabecalho');

export const listSocialMidias = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/icones-redes-sociais');

export const listAddress = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/endereco');

export const listLogos = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/logos');

export const featuredImage = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/imagem-pesquisa');

export const getPages = (slug: string): Promise<AxiosResponse> =>
  apinoticias.get(`/cms/paginas/?slug=${slug}`);

export const getMenus = (titulo: string): Promise<AxiosResponse> =>
  apinoticias.get(`/cms/menus/?titulo=${titulo}`);

export const getAllMenus = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/menus/');

export const getSecoes = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/secoes');
