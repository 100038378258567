import React, { useContext, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Typography,
} from '@material-ui/core';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { MdLink } from 'react-icons/md';
import colorContext from '../../../../Context/colorContext';

interface Props {
  changeMediaType: (type: 'image' | 'video') => void;
  video: string;
  image: string;
}

export default function ActionsNew({
  changeMediaType,
  video,
  image,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      backgroundColor: colors.colorBackground,
      boxShadow: colors.boxShadowColor,
      padding: '16px 0px',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      maxWidth: 1040,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    buttonRoot: {
      lineHeight: 'normal',
      color: colors.accentColor,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    buttonWithoutText: {
      [theme.breakpoints.down('md')]: {
        display: 'visible',
        margin: '0px',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    iconMedia: {
      fontSize: '24px !important',
    },
    shareText: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.subTitleColor,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    buttonSocial: {
      padding: 0,
      '&:hover': {
        backgroundColor: colors.colorBackground,
      },
    },
    textButton: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    iconSocial: {
      fontSize: '24px !important',
      color: colors.accentColor,
    },
    sectionActions: {
      display: 'flex',
      alignItems: 'center',
      gridGap: '10px',
      [theme.breakpoints.down('sm')]: {
        gridGap: 0,
      },
    },
  }));

  const classes = useStyles();
  const [copy, setCopy] = useState<boolean>(false);
  const url: string = (window as any).location;
  return (
    <Box className={classes.main}>
      <Box className={classes.container}>
        <Box className={classes.sectionActions}>
          <IconButton
            color='primary'
            onClick={() => changeMediaType('image')}
            disabled={!image}
            className={classes.buttonWithoutText}
          >
            <span className={`material-icons ${classes.iconMedia}`}>image</span>
          </IconButton>
          <Button
            color='primary'
            startIcon={
              <span className={`material-icons ${classes.iconMedia}`}>
                image
              </span>
            }
            classes={{
              root: classes.buttonRoot,
            }}
            onClick={() => changeMediaType('image')}
            disabled={!image}
          >
            Ver imagem
          </Button>
          <Button
            color='primary'
            startIcon={
              <span className={`material-icons ${classes.iconMedia}`}>
                play_circle_filled
              </span>
            }
            classes={{
              root: classes.buttonRoot,
            }}
            onClick={() => changeMediaType('video')}
            disabled={!video}
          >
            Ver vídeo
          </Button>
          <IconButton
            color='primary'
            onClick={() => changeMediaType('video')}
            disabled={!video}
            className={classes.buttonWithoutText}
          >
            <span className={`material-icons ${classes.iconMedia}`}>
              play_circle_filled
            </span>
          </IconButton>
        </Box>
        <Box display='flex' alignItems='center' gridGap='16px'>
          <Typography className={classes.shareText}>Compartilhe:</Typography>
          <FacebookShareButton url={url} quote={url}>
            <IconButton className={classes.buttonSocial}>
              <FaFacebookF className={classes.iconSocial} />
            </IconButton>
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <IconButton className={classes.buttonSocial}>
              <FaTwitter className={classes.iconSocial} />
            </IconButton>
          </TwitterShareButton>
          <WhatsappShareButton url={url}>
            <IconButton className={classes.buttonSocial}>
              <FaWhatsapp className={classes.iconSocial} />
            </IconButton>
          </WhatsappShareButton>
          <IconButton
            className={classes.buttonSocial}
            onClick={() => {
              navigator.clipboard.writeText(url);
              setCopy(true);
            }}
          >
            <MdLink className={classes.iconSocial} />
          </IconButton>
        </Box>
      </Box>
      <Snackbar
        open={copy}
        autoHideDuration={2000}
        onClose={(): void => setCopy(false)}
        message='Copiado para área de transferência'
      />
    </Box>
  );
}
