import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ThumbDownOutlined, ThumbUpOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { MdAnnouncement, MdClose } from 'react-icons/md';
import RatingEvaluation from '../../../../Components/RatingEvaluation';
import colorContext from '../../../../Context/colorContext';
import {
  ratingServiceAndComment,
  ratingServiceInfo,
} from '../../../../services/servico';
import getCookie from '../../../../utils/getCookies';
import { SearchI } from '../../../SearchService';
import ReportBug from '../ReportBug';

interface Props {
  service: SearchI;
}

export default function RatingComponent({ service }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '64px',
      borderBottom: `1px solid ${colors.dividerColor}`,
    },
    boxRating: {
      maxWidth: 1040,
      width: '100%',
      padding: '56px 0px 48px 0px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    titleRating: {
      fontFamily: 'Open Sans',
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: colors.textBlackGray,
      marginBottom: 24,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
        padding: '0px 16px',
      },
    },
    buttonRating: {
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,
      padding: '4px 24px',
      margin: '10px 10px 40px 10px',
      color: colors.accentColor,
    },
    boxButtonsRating: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    buttonClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: colors.textAccentColor,
    },
    header: {
      background: colors.accentColorBackground,
    },
    textHeader: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      width: '90%',
      color: colors.textAccentColor,
    },
    numberEvaluations: {
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      lineHeight: '21px',
      marginTop: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    ratingRoot: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    iconStar: {
      color: colors.accentColor,
      width: 40,
      height: 40,
    },
    iconEmpty: {
      color: 'rgba(136, 136, 136, 0.2)',
    },
    content: {
      padding: 32,
    },
    button: {
      color: colors.accentColor,
      '&:last-child': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
      },
    },
    buttonIcons: {
      width: 70,
      height: 70,
    },
    iconOk: {
      fontSize: 60,
      '&:hover': {
        color: colors.accentColor,
      },
    },
    iconOkSelected: {
      fontSize: 60,
      color: colors.accentColor,
    },
    iconNotOk: {
      fontSize: 60,
      '&:hover': {
        color: '#FF0000',
      },
    },
    iconNotOkSelected: {
      fontSize: 60,
      color: '#FF0000',
    },
  }));
  const classes = useStyles();
  const [avaliacaoIfo, setAvaliacaoInfo] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [ratingService, setRatingService] = useState<number>(0);
  const [message, setMessage] = useState<{
    message: string;
    type: 'error' | 'success' | 'info' | 'warning' | undefined;
  }>({
    message: '',
    type: 'success',
  });
  const [comments, setComments] = useState<string>('');
  const [commentsError, setCommentsError] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setRatingService(0);
    setCommentsError('');
  };

  const handleRating = (e: React.ChangeEvent<HTMLInputElement>, newValue) => {
    setOpen(true);
    setRatingService(parseInt(newValue));
  };

  const handleComment = async () => {
    const token: string | null = getCookie('gov_access_token_sso');
    try {
      if (ratingService < 4 && comments.length <= 10) {
        setCommentsError('O comentário deve ter no mínimo 10 caracteres');
        return;
      }
      setCommentsError('');

      const response = await ratingServiceAndComment(
        service.id,
        ratingService,
        comments,
        token,
      );

      if (response.data) {
        setMessage({
          message: 'Obrigado por avaliar está informação',
          type: 'success',
        });
        handleClose();
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        setMessage({
          message: err.response.data.message,
          type: 'error',
        });
        handleClose();
      } else {
        setMessage({
          message: err?.response?.data?.detail || 'Algo inesperado ocorreu',
          type: 'error',
        });
        handleClose();
      }
    }
  };

  const handleAvaliar = async (tipo: string) => {
    try {
      if (avaliacaoIfo === '') {
        await ratingServiceInfo(service.id, tipo);
        setAvaliacaoInfo(tipo);
        setMessage({
          message: 'Obrigado por avaliar está informação',
          type: 'success',
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 403) {
        console.log(error?.response?.data?.detail);

        setMessage({
          message:
            error?.response?.data?.detail ||
            'Algo inesperado ocorreu! a avaliação não pode ser enviada, tente novamente',
          type: 'error',
        });
      }
    }
  };

  return (
    <Box className={classes.main}>
      <Snackbar
        autoHideDuration={3000}
        onClose={(): void => setMessage({ message: '', type: message.type })}
        open={!!message.message}
      >
        <Alert
          onClose={(): void => setMessage({ message: '', type: message.type })}
          severity={message.type}
        >
          {message.message}
        </Alert>
      </Snackbar>
      <Box className={classes.boxRating}>
        <Box>
          <Box>
            <Typography className={classes.titleRating}>
              Esta informação foi útil para você?
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            alignContent='center'
          >
            <Box
              display='flex'
              justifyContent='center'
              alignContent='center'
              flexDirection='column'
            >
              <IconButton
                onClick={() => handleAvaliar('true')}
                className={classes.iconOk}
              >
                {avaliacaoIfo === 'true' ? (
                  <ThumbUpOutlined className={classes.iconOkSelected} />
                ) : (
                  <ThumbUpOutlined className={classes.iconOk} />
                )}
              </IconButton>
              <Typography align='center'>SIM</Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              alignContent='center'
              flexDirection='column'
            >
              <IconButton
                onClick={() => handleAvaliar('false')}
                className={classes.iconNotOk}
              >
                {avaliacaoIfo === 'false' ? (
                  <ThumbDownOutlined className={classes.iconNotOkSelected} />
                ) : (
                  <ThumbDownOutlined className={classes.iconNotOk} />
                )}
              </IconButton>
              <Typography align='center'>NÃO</Typography>
            </Box>
          </Box>
        </Box>
        <ReportBug service_id={service.id.toString()} />
        <Box>
          <Typography className={classes.titleRating}>
            Avalie Este serviço
          </Typography>
        </Box>
        <Dialog
          fullWidth
          open={open}
          onClose={() => {
            handleClose();
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <MdClose />
          </IconButton>
          <div style={{ backgroundColor: colors.colorBackground }}>
            <DialogTitle className={classes.header}>
              <Typography className={classes.textHeader}>
                <MdAnnouncement style={{ marginRight: 15, fontSize: 24 }} />
                Deixe seu comentário{' '}
                {ratingService > 3 ? '(opcional)' : '(Obrigatório)'}
              </Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              {ratingService < 4 && (
                <Box>
                  <Typography align='center'>
                    Nos ajude a melhorar este serviço nos contando sobre o
                    motivo de sua insatisfação.
                  </Typography>
                </Box>
              )}
              <textarea
                aria-label='minimum height'
                style={{
                  width: '100%',
                  height: 200,
                  backgroundColor: colors.colorBackground,
                  color: colors.textBody,
                  marginTop: 16,
                }}
                placeholder='Escreva aqui seu comentário com no mínimo 10 caracteres...'
                maxLength={200}
                onChange={e => setComments(e.target.value)}
              />
              {commentsError.length > 0 && (
                <Typography color='error'>{commentsError}</Typography>
              )}
              <DialogActions
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 16,
                  padding: 0,
                  marginTop: 32,
                }}
              >
                <Button
                  onClick={handleClose}
                  classes={{
                    root: classes.button,
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant='contained'
                  onClick={handleComment}
                  classes={{
                    root: classes.button,
                  }}
                >
                  Enviar
                </Button>
              </DialogActions>
            </DialogContent>
          </div>
        </Dialog>
        <Box>
          <RatingEvaluation
            value={ratingService}
            onChange={handleRating}
            size='large'
          />
        </Box>
        <Typography
          className={classes.numberEvaluations}
        >{`(${service.total_avaliacao} avaliações)`}</Typography>
      </Box>
    </Box>
  );
}
