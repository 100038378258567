import { Box, IconButton, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';
import { listAllCategory } from '../../../../services/categoria';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function HoverMenu({ open, setOpen }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      minHeight: 400,
      position: 'absolute',
      zIndex: 9999,
      backgroundColor: colors.colorBackgroundMainMenu,
    },
    container: {
      width: '100%',
      maxWidth: 1040,
      padding: '24px 16px',
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        flexDirection: 'row',
      },
    },
    boxCategory: {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    boxUnity: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    boxLinksCategory: {
      columns: '2 auto',
      [theme.breakpoints.down('xs')]: {
        columns: '1',
      },
    },
    linkCategory: {
      color: colors.textAccentColor,
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Open Sans',
      display: 'block',
      textDecoration: 'none',
      marginBottom: 10,
    },
    link: {
      color: colors.textAccentColor,
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Open Sans',
      display: 'block',
      textDecoration: 'none',
      marginBottom: 24,
    },
    boxBtnClose: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  }));
  const classes = useStyles();
  const [categories, setCategories] = React.useState([]);
  const getCategories = async () => {
    const { data } = await listAllCategory();
    setCategories(data?.results || []);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  if (!open) {
    return null;
  }
  return (
    <nav className={classes.main}>
      <Box className={classes.container}>
        <Box className={classes.boxCategory}>
          <Typography className={classes.link}>
            Categorias de Serviços
          </Typography>
          <Box className={classes.boxLinksCategory}>
            {categories.map((category: any) => (
              <Link
                key={category.id}
                to={`/categoria/${category.slug}`}
                onClick={handleClose}
                className={classes.linkCategory}
              >
                {category.titulo}
              </Link>
            ))}
          </Box>
        </Box>
        <Box className={classes.boxUnity}>
          <Link to='/orgaos' onClick={handleClose} className={classes.link}>
            Órgãos
          </Link>
          <Link to='/noticias' onClick={handleClose} className={classes.link}>
            Notícias
          </Link>
          <Link to='/videos' onClick={handleClose} className={classes.link}>
            Vídeos
          </Link>
          <Link
            to='/apps_governo'
            onClick={handleClose}
            className={classes.link}
          >
            Apps do Governo
          </Link>
        </Box>
        <Box className={classes.boxBtnClose}>
          <IconButton onClick={handleClose}>
            <Close style={{ color: colors.textAccentColor }} />
          </IconButton>
        </Box>
      </Box>
    </nav>
  );
}
